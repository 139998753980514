import { Iso8601DateSchema } from "@chatbotgang/etude/zod/Iso8601DateSchema";
import { Ymd } from "@zeffiroso/utils/date/Ymd";
import { HourNumberSchema } from "@zeffiroso/utils/zod/HourNumberSchema";
import { safeEnumSchema } from "@zeffiroso/utils/zod/safeEnumSchema";
import { SafeIso4217CurrencySchema } from "@zeffiroso/utils/zod/SafeIso4217CurrencySchema";
import { SafeIso8601DateSchema } from "@zeffiroso/utils/zod/SafeIso8601DateSchema";
import { StringToNumberSchema } from "@zeffiroso/utils/zod/StringToNumberSchema";
import { WeekdayNumberSchema } from "@zeffiroso/utils/zod/WeekdayNumberSchema";
import { isValid, parse } from "date-fns";
import { objectFromEntries } from "tsafe";
import { z } from "zod";

const SalesBindingProviderSchema = z.enum(["91", "caac"]);

const GencSchema = z.enum([
  "kh",
  "cn",
  "hk",
  "in",
  "id",
  "jp",
  "kr",
  "mo",
  "my",
  "mm",
  "ph",
  "sg",
  "tw",
  "th",
  "vn",
  "other",
]);

const FeatureControlNameSchema = z.enum([
  "workduoOrgAvailability",
  "nineOneIntegrationOrgAvailability",
  "commerceProfileAvailability",
  "aiQuickTemplateRecommendationAvailability",
]);

const FeatureControlSchema = z
  .object({
    /**
     * commented out due to it can be derived from enabledAt
     */
    // enabled: z.boolean(),
    enabledAt: Iso8601DateSchema.nullable(),
  })
  .catch(() => ({ enabledAt: null }));

type FeatureControlName = z.infer<typeof FeatureControlNameSchema>;
type FeatureControlSchemaShape = Record<
  FeatureControlName,
  typeof FeatureControlSchema
>;

const FeatureControlsSchema = z.object(
  objectFromEntries(
    FeatureControlNameSchema.options.map((feature) => [
      feature,
      FeatureControlSchema,
    ]),
  ) satisfies FeatureControlSchemaShape as FeatureControlSchemaShape,
);

const CrescendoLabProductSchema = z.enum(["maac", "caac"]);

const EcTypeSchema = z.enum([
  "91app",
  "shopline",
  "cyberbiz",
  "shopify",
  "flaps",
]);

const DashboardIntervalSchema = z.enum(["daily", "weekly", "monthly"]);

const DashboardMetricsSchema = z.object({
  date: Ymd.YmdStringToYmdSchema,
  value: z.number(),
});

const DashboardTotalCountSchema = z.object({
  currentCount: z.number().int(),
  previousCount: z.number().int(),
});

const DashboardAverageSchema = z.object({
  currentAvg: z.number().nullable(),
  previousAvg: z.number().nullable(),
});

const DashboardDayHourCountSchema = z.object({
  count: z.number(),
  day: WeekdayNumberSchema,
  hour: HourNumberSchema,
});

const TagSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  createdAt: Iso8601DateSchema,
});

const CdpTagSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  source: CrescendoLabProductSchema,
});

const KeywordSchema = z.object({
  id: z.number().int(),
  text: z.string(),
});

const AutoAssignmentRuleAssigneeSchema = z.discriminatedUnion("type", [
  /**
   * If the related assignee is deleted, the `type` will be set to `null` and
   * the `status` will be updated to `off`.
   *
   * New change from [91 Sales
   * binding](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#19ca2b5b2bd9421fb716074e59b87e06)
   */
  z.object({
    type: z.null(),
  }),
  z.object({
    type: z.literal("an-agent"),
    userId: z.number().int(),
  }),
  z.object({
    type: z.literal("by-queue"),
  }),
  z.object({
    type: z.literal("a-team"),
    teamId: z.number().int(),
  }),
]);

const AutoAssignmentRuleConditionSchema = z.union([
  z.object({
    category: z.literal("all-messages"),
    operator: z.literal("none"),
  }),
  z.object({
    category: z.literal("keywords"),
    operator: z.enum(["contain-one-of", "contain-all-of"]),
    keywords: z.array(KeywordSchema),
  }),
  z.object({
    category: z.literal("tags"),
    operator: z.enum(["contain-one-of", "contain-all-of"]),
    tags: z.array(TagSchema),
  }),
]);

const AutoAssignmentRuleSchema = z.object({
  id: z.number().int(),
  settingId: z.number().int(),
  name: z.string(),
  status: z.enum(["on", "off"]),
  assignee: AutoAssignmentRuleAssigneeSchema,
});

const AutoAssignmentRuleDetailSchema = AutoAssignmentRuleSchema.extend({
  conditions: z.array(AutoAssignmentRuleConditionSchema),
  assignee: AutoAssignmentRuleAssigneeSchema,
});

const AssignmentGeneralSettingSchema = z.object({
  assignmentType: z.enum(["permanent", "case-based"]),
  enableDefaultAssignment: z.boolean(),
});

const UserStatusSchema = z.enum(["invited", "active", "suspend", "deleted"]);

const UserOnDutyStatusSchema = z.enum(["online", "offline", "away"]);

const UserLanguageCodeSchema = z.enum(["en", "ja", "th", "zh-hant"]);

const ChannelTypeSchema = z.enum(["line", "fb", "ig", "wccs", "whatsapp"]);

const MemberTypeSchema = z.enum([
  "line",
  "fb",
  "ig",
  "line_group",
  "wccs",
  "whatsapp",
]);

const FbAttachmentTypeSchema = z.enum(["image", "video", "audio", "file"]);

const IgAttachmentTypeSchema = z.enum(["image", "video", "audio"]);

const WccsAttachmentTypeSchema = z.enum(["image", "video", "audio", "file"]);

const MessageMetadataSchema = z.union([
  z.object({
    // TODO: Use `Iso8601DateSchema` for response and `z.date()` for request.
    downloadExpirationDate: z.coerce.date(),
    expirationDatePrefix: z.string(),
    filename: z.string(),
    filesizeBytes: z.number().int(),
    filesizePrefix: z.string(),
  }),
  // The GCS URL if the `originUrl` is a CDN URL from the channel.
  z
    .object({
      backupMediaUrl: z.string().nullable().optional(),
    })
    .optional(),
]);

const MessageSenderTypeSchema = z.enum([
  "user",
  "member",
  "assignment",
  "processing_state",
  "group_action",

  // [[BE] Auto-remind for assignment
  // result](https://app.asana.com/0/1199607007611227/1208328059149477/f)
  "assignment_error",
  "bot",
]);

const MessageSchema = z.object({
  id: z.number().int(),
  uuid: z.string(),
  channelId: z.number().int(),
  memberId: z.number().int(),
  userId: z.number().int().nullable(),
  senderType: MessageSenderTypeSchema,
  type: z.enum([
    "text",
    "image",
    "video",
    "audio",
    "file",
    "line_flex",
    "ig_story_mention",
  ]),
  text: z.string(),
  previewUrl: z.string().nullable(),
  originUrl: z.string().nullable(),
  lineFlexContent: z.string().nullable(),
  read: z.boolean(),
  createdAt: Iso8601DateSchema,
  updatedAt: Iso8601DateSchema,
  /**
   * TODO: remove `.optional()` after the server-side implementation is ready.
   *
   * - Asana: [IG un-sent post-release](https://app.asana.com/0/0/1207106744242473/f)
   */
  deletedAt: Iso8601DateSchema.nullable().optional(),
  metadata: MessageMetadataSchema.nullable(),
  speakerId: z.number().int().nullable(),
  replyTo: z.number().int().nullable(),
  quotable: z.boolean(),
});

const MessageDetailSchema = MessageSchema.extend({
  memberAvatar: z.string(),
  memberDisplayName: z.string(),
  pinned: z.boolean(),
  userAvatar: z.string().nullable(),
  userId: z.number().int().nullable(),
  userName: z.string().nullable(),
  userStatus: UserStatusSchema.nullable(),
});

const MessageWithStatusSchema = MessageSchema.and(
  z.union([
    z.object({
      status: z.literal("delivered"),
    }),
    z.object({
      status: z.literal("failed"),
      error: z.enum([
        "FB_EXCEED_MESSAGE_WINDOW",
        "FB_TOKEN_EXPIRE",
        "MESSAGE_NOT_SEND",
        "REMOTE_LINE_CLIENT_ERROR",
        "REMOTE_LINE_REACH_BUDGET_LIMIT",
        "REMOTE_PROCESS_ERROR",
        /**
         * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1729833119468679?thread_ts=1729830255.902529&cid=C02R6ETJMEY)
         */
        "REMOTE_LINE_NG_SENDER_NAME",
      ]),
    }),
  ]),
);

const MessagePinnedSchema = z.object({
  id: MessageDetailSchema.shape.id,
  senderType: MessageDetailSchema.shape.senderType,
  memberId: MessageDetailSchema.shape.memberId,
  memberDisplayName: MessageDetailSchema.shape.memberDisplayName,
  memberAvatar: MessageDetailSchema.shape.memberAvatar,
  userId: MessageDetailSchema.shape.userId,
  userName: MessageDetailSchema.shape.userName,
  userStatus: MessageDetailSchema.shape.userStatus,
  userAvatar: MessageDetailSchema.shape.userAvatar,
  type: MessageDetailSchema.shape.type.extract([
    "text",
    "image",
    "audio",
    "video",
    "file",
  ]),
  text: MessageDetailSchema.shape.text,
  previewUrl: MessageDetailSchema.shape.previewUrl,
  originUrl: MessageDetailSchema.shape.originUrl,
  lineFlexContent: MessageDetailSchema.shape.lineFlexContent,
  metadata: MessageDetailSchema.shape.metadata,
  createdAt: MessageDetailSchema.shape.createdAt,
  updatedAt: MessageDetailSchema.shape.updatedAt,
  deletedAt: MessageDetailSchema.shape.deletedAt,
  quotable: MessageDetailSchema.shape.quotable,
  replyTo: MessageDetailSchema.shape.replyTo,
  speakerId: MessageDetailSchema.shape.speakerId,
  cursor: z.object({
    after: z.string().nullable(),
    before: z.string().nullable(),
  }),
});

const MemberStatusSchema = z.enum(["active", "inactive"]);

const MemberProcessingStateSchema = z.enum([
  "new",
  "follow-up",
  "resolved",
  "blocked",
  "none",
]);

const MemberAssignTypeSchema = z.enum([
  "permanent",
  "unassigned",
  "case-based",
]);

const MemberGenderSchema = z.enum(["male", "female", "other", "unknown", ""]);

const MemberEngagementLevel = z
  .enum(["Lv1", "Lv2", "Lv3", "Lv4", "Lv5"])
  .nullable();

const UnificationKeysSchema = z.enum([
  "display_email",
  "custom_id",
  "connect_id",
  "display_mobile",
  "external_member_id",
  "line_id",
]);

const UnifyScopeSettingSchema = z.object({
  unifyScopeId: z.number().int(),
  isCdpConnected: z.boolean(),
  orgs: z.array(
    z.object({
      id: z.number().int(),
      name: z.string(),
      source: CrescendoLabProductSchema,
    }),
  ),
  unifyKeys: z.array(UnificationKeysSchema),
});

const UtmSettingSchema = z.object({
  source: z.string(),
  medium: z.string(),
});

const MemberSchema = z.object({
  id: z.number().int(),
  channelId: z.number().int(),
  type: MemberTypeSchema,
  status: MemberStatusSchema,
  processingState: MemberProcessingStateSchema,
  originalName: z.string(),
  displayName: z.string(),
  avatar: z.string(),
  starMark: z.boolean(),
  unreadMessageCount: z.number().int(),
  assignType: MemberAssignTypeSchema,
  assignmentRelationship: z.object({
    user: z
      .object({
        id: z.number().int(),
        name: z.string(),
      })
      .nullable(),
    team: z
      .object({
        id: z.number().int(),
        name: z.string(),
      })
      .nullable(),
  }),
  createdAt: Iso8601DateSchema,
  updatedAt: Iso8601DateSchema,
  pinned: z.boolean(),
  lastMessageAt: Iso8601DateSchema,
  lastMessage: z
    .object({
      message: z.object({
        id: z.number().int(),
        uuid: z.string(),
        channelId: z.number().int(),
        memberId: z.number().int(),
        memberDisplayName: z.string(),
        memberAvatar: z.string(),
        userId: z.number().int().nullable(),
        userName: z.string().nullable(),
        userAvatar: z.string().nullable(),
        senderType: MessageSenderTypeSchema,
        speakerId: MessageSchema.shape.speakerId,
        type: MessageSchema.shape.type,
        text: z.string(),
        previewUrl: z.string().nullable(),
        originUrl: z.string().nullable(),
        lineFlexContent: z.string().nullable(),
        metadata: MessageMetadataSchema.nullable(),
        read: z.boolean(),
        pinned: z.boolean(),
        createdAt: MessageSchema.shape.createdAt,
        updatedAt: MessageSchema.shape.updatedAt,
        deletedAt: MessageSchema.shape.deletedAt,
      }),
    })
    .nullable(),
  matchedMessage: z
    .object({
      lastMatchedMessageAt: Iso8601DateSchema,
      matchedMessageCount: z.number().int(),
    })
    .nullable(),
  lastMemberMessageSend: Iso8601DateSchema.nullable(),
  isOnline: z.boolean().nullable(),
});

/**
 * starMark not included in this schema.
 */
const DirectChatSearchMemberSchema = MemberSchema.pick({
  id: true,
  channelId: true,
  type: true,
  status: true,
  processingState: true,
  originalName: true,
  displayName: true,
  avatar: true,
  unreadMessageCount: true,
  assignType: true,
  assignmentRelationship: true,
  createdAt: true,
  updatedAt: true,
  pinned: true,
  lastMessageAt: true,
}).extend({
  lastMessage: MemberSchema.shape.lastMessage.unwrap().shape.message.nullable(),
});

const MemberDetailSchema = MemberSchema.pick({
  id: true,
  channelId: true,
  type: true,
  status: true,
  processingState: true,
  originalName: true,
  displayName: true,
  avatar: true,
  starMark: true,
  unreadMessageCount: true,
  assignType: true,
  assignmentRelationship: true,
  createdAt: true,
  updatedAt: true,
  pinned: true,
  lastMessageAt: true,
  lastMemberMessageSend: true,
  isOnline: true,
}).extend({
  lastMessage: MemberSchema.shape.lastMessage.unwrap().shape.message.nullable(),
  externalMemberId: z.string(),
  name: z.string().nullable(),
  originalEmail: z.string(),
  displayEmail: z.string(),
  originalMobile: z.string(),
  displayMobile: z.string(),
  gender: MemberGenderSchema,
  birthday: z.string().transform((dateString) => {
    if (dateString === "9999-12-31") return null;
    const date = parse(dateString, "yyyy-MM-dd", new Date());

    return !isValid(date) ? null : date;
  }),
  age: z.number().int().nullable(),
  location: z.string(),
  country: z.string().nullable(),
  city: z.string().nullable(),
  company: z.string().nullable(),
  accountManager: z.string().nullable(),
  engagementLevel: MemberEngagementLevel,
  memberLevel: z.string().nullable(),
  memberStatus: z.boolean(),
  customId: z.string(),
  connectId: z.string(),
});

const MemberNoteSchema = z.object({
  id: z.number().int(),
  orgId: z.number().int(),
  userId: z.number().int().nullable(),
  userSource: CrescendoLabProductSchema.nullable(),
  channelId: z.number().int(),
  channelName: z.string(),
  channelType: ChannelTypeSchema,
  memberSource: CrescendoLabProductSchema,
  memberId: z.number().int(),
  content: z.string(),
  createdAt: Iso8601DateSchema,
  updatedAt: Iso8601DateSchema,
});

const GroupMemberSchema = MemberSchema.pick({
  id: true,
  status: true,
  processingState: true,
  originalName: true,
  displayName: true,
  avatar: true,
});

/**
 * The total amount, spending, points, and rewards are all string types as forwarded from CDH.
 */
const MemberProfileSchema = z.object({
  ecCurrency: SafeIso4217CurrencySchema.nullable(),
  ecTotalAmount: StringToNumberSchema.nullable(),
  ecMemberTierGapEntitySpending: StringToNumberSchema.nullable(),
  ecPoints: StringToNumberSchema.nullable(),
  ecReward: StringToNumberSchema.nullable(),
  memberLevel: z.string().nullable(),
  ecMemberStartTime: SafeIso8601DateSchema.nullable(),
  ecMemberEndTime: SafeIso8601DateSchema.nullable(),
  accountManager: z.string().nullable(),
});

const OrderItemsSchema = z.object({
  productName: z.string(),
  count: z.number().int(),
  price: z.number(),
});

const NineOneOrderStatusSchema = z.enum([
  "WaitingToShipping",
  "ConfirmedToShipping",
  "Finish",
  "Cancel",
  "WaitingToCreditCheck",
  "WaitingToPay",
  "WaitingToModifyStore",
]);

const ShopifyOrderStatusSchema = z.enum([
  "FULFILLED",
  "IN_PROGRESS",
  "ON_HOLD",
  "OPEN",
  "PARTIALLY_FULFILLED",
  "PENDING_FULFILLMENT",
  "RESTOCKED",
  "SCHEDULED",
  "UNFULFILLED",
]);

const ShoplineOrderStatusSchema = z.enum([
  "pending",
  "confirmed",
  "completed",
  "cancelled",
]);

const CyberbizOrderStatusSchema = z.enum(["open", "closed", "cancelled"]);

const EcOrderStatusSchema = safeEnumSchema(
  z.union([
    NineOneOrderStatusSchema,
    ShopifyOrderStatusSchema,
    ShoplineOrderStatusSchema,
    CyberbizOrderStatusSchema,
  ]),
);

const ShopifyPaymentStatusSchema = z.enum([
  "AUTHORIZED",
  "EXPIRED",
  "PAID",
  "PARTIALLY_PAID",
  "PARTIALLY_REFUNDED",
  "PENDING",
  "REFUNDED",
  "VOIDED",
]);

const ShoplinePaymentStatusSchema = z.enum([
  "temp",
  "pending",
  "failed",
  "expired",
  "completed",
  "refunding",
  "refunded",
  "partially_refunded",
]);

const CyberbizPaymentStatusSchema = z.enum([
  "paid",
  "pending",
  "cod",
  "failed",
  "abandoned",
  "refunded",
  "no_refunded",
  "pending_refund",
  "processing",
  "remitted",
  "pending_partial_refund",
  "partial_refunded",
  "refunding",
  "refund_failed",
]);

const FinancialStatusSchema = safeEnumSchema(
  z.union([
    ShopifyPaymentStatusSchema,
    ShoplinePaymentStatusSchema,
    CyberbizPaymentStatusSchema,
  ]),
);

const NineOneDeliveryStatusSchema = z.enum([
  "NotYetAllocatedCode",
  "AllocatedCode",
  "Finish",
  "ShippingProcessing",
  "VerifySuccess",
  "ShippingArrived",
  "ShippingFail",

  "VerifyFailLost",
  "VerifyFailAbnormalPackage",
  "VerifyFailRenovation",
  "VerifyFailErrorCode",
  "VerifyFailInvalidCode",

  "PickedUp",
  "ShippingAbnormal",
  "VerifyFail",

  "CashOnDeliveryTransferring",
  "CashOnDeliveryNotAtHome",
  "CashOnDeliveryDistributing",
  "CashOnDeliveryFailDamage",
  "CashOnDeliveryFailLost",
  "CashOnDeliveryFail",
  "CashOnDeliveryAddressError",
  "CashOnDeliveryForwarding",
]);

const ShopifyDeliveryStatusSchema = z.enum([
  "ATTEMPTED_DELIVERY",
  "CANCELED",
  "CONFIRMED",
  "DELIVERED",
  "FAILURE",
  "FULFILLED",
  "IN_TRANSIT",
  "LABEL_PRINTED",
  "LABEL_PURCHASED",
  "LABEL_VOIDED",
  "MARKED_AS_FULFILLED",
  "NOT_DELIVERED",
  "OUT_FOR_DELIVERY",
  "PICKED_UP",
  "READY_FOR_PICKUP",
  "SUBMITTED",
]);

const ShoplineDeliveryStatusSchema = z.enum([
  "pending",
  "shipping",
  "shipped",
  "arrived",
  "collected",
  "returned",
  "returning",
]);

const CyberbizDeliveryStatusSchema = z.enum([
  "unshipped",
  "preparing",
  "cancel",
  "fulfilled",
  "partial",
  "arrived",
  "received",
  "returned",
  "expired",
  "problem",
  "no_need",
]);

const FulfillmentStatusSchema = safeEnumSchema(
  z.union([
    NineOneDeliveryStatusSchema,
    ShopifyDeliveryStatusSchema,
    ShoplineDeliveryStatusSchema,
    CyberbizDeliveryStatusSchema,
  ]),
);

const ShopifyReturnStatusSchema = z.enum([
  "INSPECTION_COMPLETE",
  "IN_PROGRESS",
  "NO_RETURN",
  "RETURNED",
  "RETURN_FAILED",
  "RETURN_REQUESTED",
]);

const CyberbizReturnStatusSchema = z.enum([
  "no_need",
  "request_return",
  "returning",
  "checking",
  "returned",
  "in_hub",
  "problem",
  "processing",
  "in_origin_cvs",
  "refused",
  "partial_return",
]);

const ReturnStatusSchema = safeEnumSchema(
  z.union([ShopifyReturnStatusSchema, CyberbizReturnStatusSchema]),
);

const OrderSchema = z.object({
  type: EcTypeSchema,
  orderId: z.string(),
  orderDate: SafeIso8601DateSchema,
  orderCurrency: SafeIso4217CurrencySchema,
  ecStatus: EcOrderStatusSchema.nullable(),
  ecFinancialStatus: FinancialStatusSchema.nullable(),
  ecFulfillmentStatus: FulfillmentStatusSchema.nullable(),
  ecReturnStatus: ReturnStatusSchema.nullable(),
  orderAmount: z.number(),
  orderShippingRate: z.number(),
  orderItems: OrderItemsSchema.array(),
});

const PrizeSchema = z.object({
  type: EcTypeSchema,
  prizeName: z.string(),
  prizeStartAt: SafeIso8601DateSchema.nullable(),
  prizeEndAt: SafeIso8601DateSchema.nullable(),
  prizeCode: z.string(),
});

const PermissionSchema = z.enum([
  "basic",
  "downloadMemberMessage",
  "editMemberNote",
  "editMemberProfile",
  "editMemberTag",
  "editQuickTemplate",
  "manageOrg",
  "manageSecurityTool",
  "viewMemberMessageAll",
  "viewMemberNote",
  "viewMemberProfile",
  "viewMemberTag",
  "editAssignmentGeneralSetting",
  "viewAssignmentGeneralSetting",
  "editAutoAssignmentRule",
  "viewAutoAssignmentRule",
  "manualAssignmentAll",
  "editTeam",
  "viewTeam",
  "createTeam",
  "deleteTeam",
  "viewDashboard",
]);

type Permission = z.infer<typeof PermissionSchema>;
type PermissionSchemaShape = Record<Permission, z.ZodBoolean>;

const PermissionsSchema = z.object(
  objectFromEntries(
    PermissionSchema.options.map((permission) => [permission, z.boolean()]),
  ) satisfies PermissionSchemaShape as PermissionSchemaShape,
);

const CaacUnifiedMemberSchema = MemberDetailSchema.pick({
  channelId: true,
  originalName: true,
  externalMemberId: true,
  lastMessageAt: true,
}).extend({
  source: z.literal("caac"),
  memberId: MemberDetailSchema.shape.id,
  channelName: z.string(),
  channelType: ChannelTypeSchema,
});

const UnifiedMemberSchema = z.union([
  z.object({
    source: z.literal("maac"),
    memberId: MemberDetailSchema.shape.id,
    channelName: z.null(),
    channelType: z.null(),
    channelId: z.null(),
    originalName: z.null(),
    externalMemberId: z.null(),
    lastMessageAt: z.null(),
  }),
  CaacUnifiedMemberSchema,
]);

const MemberAssignmentFilterSchema = z.enum([
  "all",
  "assignee",
  "me",
  "my-teams",
  "my-teams-pending",
  "unassigned",
]);

const MemberSearchNumberArrayActionSchema = z.enum(["tags"]);

const MemberSearchStringActionSchema = z.enum([
  "names",
  "notes",
  "external-member-id",
  "messages",
  "tags",
  "emails",
  "mobiles",
]);

const MemberSearchActionSchema = z.enum([
  ...MemberSearchNumberArrayActionSchema.options,
  ...MemberSearchStringActionSchema.options,
]);

const UserIdSchema = z.number().int();

const OrgSchema = z.object({
  id: z.number().int(),
  uuid: z.string(),
  name: z.string(),
  logo: z.string().nullable(),
  userId: UserIdSchema,
  userStatus: UserStatusSchema,
  enableTwoFactor: z.boolean(),
  createdAt: Iso8601DateSchema,
});

/**
 * The term "detail" is not quite accurate in this context since it refers to
 * the response from the getById API.
 */
const OrgDetailSchema = OrgSchema.pick({
  id: true,
  name: true,
  logo: true,
  createdAt: true,
  enableTwoFactor: true,
}).extend({
  updatedAt: Iso8601DateSchema,
  ecType: EcTypeSchema.nullable(),
});

const OrgPlanSchema = z.object({
  orgId: z.number().int(),
  type: z.enum([
    /**
     * Slack #product-caac
     *
     * @see https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1691034751583779?thread_ts=1690180132.639659&cid=C02R6ETJMEY
     */
    // latest
    "free",
    "starter",
    "professional",
    "enterprise",
    "maac_combo",
    // FIXME: legacy
    "trial",
    "standard",
  ]),
  seatNum: z.number().int(),
  createdAt: Iso8601DateSchema,
  updatedAt: Iso8601DateSchema,
  expiredAt: Iso8601DateSchema,
});

const ChannelSchema = z.object({
  id: z.number().int(),
  externalChannelId: z.string(),
  name: z.string(),
  type: ChannelTypeSchema,
  userRecognitionEnabled: z.boolean(),
  createdAt: Iso8601DateSchema,
  status: z.enum(["connected", "disconnected", "token-expired"]),
});

// TODO: Refactor it to use more specific constructs, such as enums, for
// improved clarity and maintainability.
const LanguageCodeSchema = z.string();
const TimezoneSchema = z.string();

const ChannelDetailSchema = ChannelSchema.extend({
  // Unused for now. Keep it here for future reference.
  // languageCode: LanguageCodeSchema,
  timezone: TimezoneSchema,
  updatedAt: Iso8601DateSchema,
});

const CdpSettingSchema = z.object({
  orgId: z.number().int(),
  channel: ChannelDetailSchema,
  enable: z.boolean(),
});

/**
 * Slack: [#team-eng-caac](https://chatbotgang.slack.com/archives/C03MSFGNT2A/p1732073854823029?thread_ts=1731997871.696709&cid=C03MSFGNT2A)
 */
const RoleTypeSchema = z.enum([
  "owner",
  "primary_admin",
  "admin",
  "primary_agent",
  "agent",
  "custom",
]);

const RoleSchema = z.object({
  id: z.number().int(),
  orgId: z.number().int(),
  name: z.string(),
  type: RoleTypeSchema,
  description: z.string(),
  associatedUserCount: z.number().int(),
  permission: PermissionsSchema,
  createdAt: Iso8601DateSchema,
});

const UserSchema = z.object({
  avatar: z.string().nullable(),
  chatName: z.string(),
  createdAt: Iso8601DateSchema,
  email: z.string(),
  id: UserIdSchema,
  inviterId: z.number().int().nullable(),
  name: z.string(),
  onDutyStatus: UserOnDutyStatusSchema,
  orgId: OrgSchema.shape.id,
  roleId: RoleSchema.shape.id,
  roleName: RoleSchema.shape.name,
  roleType: RoleSchema.shape.type,
  status: UserStatusSchema,
  externalUserId: z.string().nullable(),
});

const TeamSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string().catch(() => ""),
  routingRule: z.enum(["by-queue", "manual"]),
  editable: z.boolean(),
  externalTeamId: z.string().nullable(),
});

const TeamUserSchema = UserSchema.omit({
  email: true,
  roleName: true,
  roleType: true,
}).extend({
  accountId: z.number().int(),
  deletedAt: Iso8601DateSchema.nullable(),
  enablePopupNotification: z.boolean(),
  enableSoundNotification: z.boolean(),
  languageCode: UserLanguageCodeSchema,
  mobile: z.string().nullable(),
  updatedAt: Iso8601DateSchema,
});

const UserDetailSchema = UserSchema.extend({
  enablePopupNotification: z.boolean(),
  enableSoundNotification: z.boolean(),
  languageCode: UserLanguageCodeSchema,
  mobile: z.string().nullable(),
  permanentAssignmentMembersCount: z.number().int(),
  teams: z.array(TeamSchema),
  updatedAt: Iso8601DateSchema,
});

const UserUpdateInviteSchema = UserDetailSchema.pick({
  id: true,
  orgId: true,
  roleId: true,
  roleName: true,
  roleType: true,
  name: true,
  chatName: true,
  mobile: true,
  email: true,
  avatar: true,
  status: true,
  enableSoundNotification: true,
  enablePopupNotification: true,
  languageCode: true,
  createdAt: true,
  updatedAt: true,
});

const UserMeSchema = UserDetailSchema.omit({
  permanentAssignmentMembersCount: true,
}).extend({
  permission: PermissionsSchema,
  zendeskToken: z.string(),
});

const AssignSchema = z.object({
  assignType: z.enum(["permanent", "unassigned", "case-based"]),
  assignmentRelationship: z.object({
    userId: z.number().int().nullable(),
    teamId: z.number().int().nullable(),
  }),
});

const NotificationSettingSchema = z.object({
  webpushNotificationEnabled: z.boolean(),
  webpushNotificationSoundEnabled: z.boolean(),
  emailNotificationEnabled: z.boolean(),
});

/**
 * Doc: [Notion](https://www.notion.so/cresclab/202312-CAAC-Conversation-History-5ac758358bb74cfda2b994b7a54e4cf6?pvs=4#7a149305c13147809ec51974fc7a4919)
 */
const ConversationSchema = z.object({
  conversationId: z.number().int(),
  memberId: MemberSchema.shape.id,
  processingState: MemberProcessingStateSchema.exclude(["none"]),
  resolverAssignmentRelationship: z
    .object({
      user: z
        .object({
          id: UserSchema.shape.id,
          name: UserSchema.shape.name,
        })
        .nullable(),
      team: z
        .object({
          id: TeamSchema.shape.id,
          name: TeamSchema.shape.name,
        })
        .nullable(),
    })
    .nullable(),
  createdAt: Iso8601DateSchema,
  endedAt: Iso8601DateSchema.nullable(),
});

//#region Quick Template
const QuickTemplateTextSchema = z.object({
  type: z.literal("text"),
  text: z.string(),
  previewUrl: z.null(),
  originUrl: z.null(),
  metadata: z.null(),
});

const QuickTemplateImageSchema = z.object({
  type: z.literal("image"),
  text: z.literal(""),
  previewUrl: z.string(),
  originUrl: z.string(),
  metadata: z.null(),
});

const QuickTemplateVideoSchema = z.object({
  type: z.literal("video"),
  text: z.literal(""),
  previewUrl: z.string(),
  originUrl: z.string(),
  metadata: z.null(),
});

const QuickTemplateFileSchema = z.object({
  type: z.literal("file"),
  text: z.literal(""),
  previewUrl: z.null(),
  originUrl: z.string(),
  metadata: z.object({
    filename: z.string(),
    filesizePrefix: z.string(),
    filesizeBytes: z.number().int(),
    expirationDatePrefix: z.string(),
    // TODO: Use `Iso8601DateSchema` for response and `z.date()` for request.
    downloadExpirationDate: z.coerce.date(),
  }),
});

const QuickTemplateMessageSchema = z.union([
  QuickTemplateTextSchema,
  QuickTemplateImageSchema,
  QuickTemplateVideoSchema,
  QuickTemplateFileSchema,
]);

const QuickTemplateDetailSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  categoryId: z
    .number()
    .int()
    .nullable()
    /**
     * Allow `undefined` but transform it to `null` for consistency.
     *
     * - Slack: [#team-eng-caac](https://chatbotgang.slack.com/archives/C03MSFGNT2A/p1718261355635419?thread_ts=1718260346.780009&cid=C03MSFGNT2A)
     *
     * This might be a temporary solution. We should consider removing it if
     * it's no longer necessary.
     */
    .optional()
    .transform((value) => (value === undefined ? null : value)),
  messages: z.array(QuickTemplateMessageSchema),
});

const QuickTemplateCategorySchema = z.object({
  id: z.number().int(),
  name: z.string(),
});

const QuickTemplateSchema = z.object({
  category: z.union([
    // default category
    z.object({
      id: z.null(),
      name: z.null(),
    }),
    QuickTemplateCategorySchema,
  ]),
  templates: z.array(
    QuickTemplateDetailSchema.pick({
      id: true,
      name: true,
    }),
  ),
});
//#endregion

const FirebaseAuthProviderSchema = z.object({
  pid: z.string(),
  type: z.enum(["saml", "oidc"]),
});

const ErrorSchema = z.union([
  z.object({
    code: z.literal(400),
    name: z.enum([
      "PARAMETER_EMAIL_OR_PASSWORD_INVALID",
      "PARAMETER_INVALID",
      "PARAMETER_USER_EXIST",
      "PARAMETER_WEAK_PASSWORD",
      "QUICK_TEMPLATE_CATEGORY_ALREADY_EXISTED",
      "QUICK_TEMPLATE_NAME_ALREADY_EXISTED",
      "QUICK_TEMPLATE_OVER_QUANTITY_LIMIT",
      "QUICK_TEMPLATE_CATEGORY_OVER_QUANTITY_LIMIT",
      "QUICK_TEMPLATE_CONTAIN_TOO_MANY_MESSAGES",
      "AUTO_ASSIGNMENT_RULE_ASSIGNEE_AN_AGENT_EXISTED",
      "AUTO_ASSIGNMENT_RULE_ASSIGNEE_A_TEAM_EXISTED",
      "AUTO_ASSIGNMENT_RULE_NAME_ALREADY_EXISTED",
      "SEND_MESSAGE_MEMBER_OUT_OF_YOUR_SCOPE",
      /**
       * Slack: [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1729833119468679?thread_ts=1729830255.902529&cid=C02R6ETJMEY)
       */
      "REMOTE_LINE_NG_SENDER_NAME",
    ]),
  }),
  z.object({
    code: z.literal(401),
    name: z.literal("AUTH_NOT_AUTHENTICATED"),
  }),

  /**
   * 2FA
   *
   * Spec:
   * [Notion](https://www.notion.so/cresclab/2FA-21e3c4454185423ebb5a07c526615e7c?pvs=4#5fa43a7028974e968928de67b1a32509)
   */
  z.object({
    code: z.literal(401),
    name: z.literal("AUTH_OTP_MISMATCH"),
    detail: z.object({
      remainAttempts: z.number().int(),
    }),
  }),
  z.object({
    code: z.literal(401),
    name: z.enum(["AUTH_OTP_MAX_ATTEMPTS_EXCEEDED", "AUTH_OTP_EXPIRED"]),
  }),

  z.object({
    code: z.literal(402),
    name: z.enum([
      "PAYMENT_EXPIRED_PLAN",
      "PAYMENT_NO_ENOUGH_SEAT",
      /**
       * TODO: Since we utilize `batchCreate` to send messages, and the server
       * returns error status per message, it appears that maintaining this as a
       * global error may no longer be necessary. Please confirm with Backend
       * engineers and consider removing it.
       */
      "REMOTE_LINE_REACH_BUDGET_LIMIT",
    ]),
  }),
  z.object({
    code: z.literal(403),
    name: z.enum(["AUTH_PERMISSION_DENIED", "AUTH_UNAUTHORIZED_ORG"]),
  }),
  z.object({
    code: z.literal(404),
    name: z.enum([
      "RESOURCE_NOT_FOUND",
      "REMOTE_CDH_PROFILE_NOT_FOUND",
      /**
       * Occurs when there are no members in the LINE group.
       *
       * Spec: [Notion](https://www.notion.so/cresclab/202407-Group-Chat-e12e33d62eff486ba48d7a8f10cef134?pvs=4#9ea6e94350634154a633b6c3db9e1921)
       * API:
       * - api/v1/orgs/:orgId/chat/groups/:groupId/members
       * - api/v1/orgs/:orgId/chat/groups/:groupId/members-no-page
       * - api/v1/orgs/:orgId/chat/groups/:groupId/members/count
       */
      "REMOTE_LINE_RESOURCE_NOT_FOUND",
    ]),
  }),
  z.object({
    code: z.literal(409),
    name: z.enum(["CHANNEL_BELONG_TO_ANOTHER_ORG"]),
  }),
  z.object({
    code: z.literal(429),
    name: z.literal("TOO_MANY_REQUESTS"),
  }),
  z.object({
    code: z.literal(500),
    name: z.enum(["INTERNAL_PROCESS", "UNKNOWN_ERROR"]),
  }),
  z.object({
    remoteCode: z.literal(502),
    name: z.enum([
      "REMOTE_PROCESS_ERROR",
      "REMOTE_LINE_CLIENT_ERROR",
      "REMOTE_OPENAI_CLIENT_ERROR",
      "FB_TOKEN_EXPIRE",
    ]),
  }),

  /**
   * Import users from CSV errors.
   *
   * Notion: [91 Sales binding](https://www.notion.so/cresclab/91-Sales-binding-a1ce40b5c8bb4786b8204f2d3a723ea3?pvs=4#acf2bf29b4864ffeb2eb6dd03c5af8ae)
   */
  z.object({
    code: z.literal(400),
    name: z.enum([
      "BULK_INVITE_USER_ERROR_PARSE_FAIL",
      "BULK_INVITE_USER_ERROR_EXCEED_ROW_LIMIT",
    ]),
  }),
  z.object({
    code: z.literal(402),
    name: z.literal("BULK_INVITE_USER_ERROR_INSUFFICIENT_SEAT"),
    detail: z.object({
      remainSeat: z.number().int(),
      requireSeat: z.number().int(),
    }),
  }),
  z.object({
    code: z.literal(400),
    name: z.literal("BULK_INVITE_USER_ERROR_INVALID_CONTENT"),
    detail: z.object({
      invalidRowCount: z.number().int(),
      errorReport: z.string(),
    }),
  }),
]);

export {
  AssignmentGeneralSettingSchema,
  AssignSchema,
  AutoAssignmentRuleAssigneeSchema,
  AutoAssignmentRuleConditionSchema,
  AutoAssignmentRuleDetailSchema,
  AutoAssignmentRuleSchema,
  CaacUnifiedMemberSchema,
  CdpSettingSchema,
  CdpTagSchema,
  ChannelDetailSchema,
  ChannelSchema,
  ChannelTypeSchema,
  ConversationSchema,
  CrescendoLabProductSchema,
  CyberbizOrderStatusSchema,
  DashboardAverageSchema,
  DashboardDayHourCountSchema,
  DashboardIntervalSchema,
  DashboardMetricsSchema,
  DashboardTotalCountSchema,
  DirectChatSearchMemberSchema,
  EcOrderStatusSchema,
  EcTypeSchema,
  ErrorSchema,
  FbAttachmentTypeSchema,
  FeatureControlsSchema,
  FirebaseAuthProviderSchema,
  GencSchema,
  GroupMemberSchema,
  IgAttachmentTypeSchema,
  KeywordSchema,
  LanguageCodeSchema,
  MemberAssignmentFilterSchema,
  MemberAssignTypeSchema,
  MemberDetailSchema,
  MemberNoteSchema,
  MemberProcessingStateSchema,
  MemberProfileSchema,
  MemberSchema,
  MemberSearchActionSchema,
  MemberSearchNumberArrayActionSchema,
  MemberSearchStringActionSchema,
  MemberStatusSchema,
  MessageDetailSchema,
  MessageMetadataSchema,
  MessagePinnedSchema,
  MessageSchema,
  MessageWithStatusSchema,
  NineOneOrderStatusSchema,
  NotificationSettingSchema,
  OrderSchema,
  OrgDetailSchema,
  OrgPlanSchema,
  OrgSchema,
  PermissionSchema,
  PermissionsSchema,
  PrizeSchema,
  QuickTemplateCategorySchema,
  QuickTemplateDetailSchema,
  QuickTemplateFileSchema,
  QuickTemplateImageSchema,
  QuickTemplateSchema,
  QuickTemplateTextSchema,
  QuickTemplateVideoSchema,
  RoleSchema,
  RoleTypeSchema,
  SalesBindingProviderSchema,
  ShopifyOrderStatusSchema,
  ShoplineOrderStatusSchema,
  TagSchema,
  TeamSchema,
  TeamUserSchema,
  TimezoneSchema,
  UnificationKeysSchema,
  UnifiedMemberSchema,
  UnifyScopeSettingSchema,
  UserDetailSchema,
  UserMeSchema,
  UserOnDutyStatusSchema,
  UserSchema,
  UserStatusSchema,
  UserUpdateInviteSchema,
  UtmSettingSchema,
  WccsAttachmentTypeSchema,
};
