import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import copy from "copy-to-clipboard";
import { mergeWith } from "lodash-es";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { useMessage } from "@/components/message";
import { fcm } from "@/internal/firebase/firebaseMessaging";
import { FormLayout } from "@/layout/FormLayout";
import type { FormValues } from "@/routes/Settings/Notification/NotificationSettings/form";
import {
  initialValues,
  useCurrentQuery,
} from "@/routes/Settings/Notification/NotificationSettings/form";
import { FormTable } from "@/routes/Settings/Notification/NotificationSettings/FormTable";
import { RequestPermission } from "@/routes/Settings/Notification/NotificationSettings/RequestPermission";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  container: css({
    display: "flex",
    flexDirection: "column",
    gap: 32,
  }),
  content: css({
    gap: 8,
  }),
  buttons: css({
    display: "flex",
    gap: 16,
  }),
  description: css({
    color: theme.colors.neutral007,
  }),
});

const CopyFcmTokenButton: FC = () => {
  const fcmTokenQuery = fcm.useFcmTokenQuery();
  const { t } = useTranslation();
  const message = useMessage();
  const copyFcmToken = useHandler(function copyFcmToken() {
    if (!fcmTokenQuery.isSuccess) return;

    copy(fcmTokenQuery.data);
    message.success(<Trans i18nKey="common.copiedToClipboard" />);
  });

  return (
    <Button
      type="primary"
      onClick={copyFcmToken}
      disabled={!fcmTokenQuery.isSuccess}
    >
      {t("notification.setting.fcmToken.copy")}
    </Button>
  );
};

const RefreshFcmTokenButton: FC = () => {
  const { t } = useTranslation();
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const message = useMessage();
  const fcmTokenQuery = fcm.useFcmTokenQuery();
  const revokeFcmTokenMutation = fcm.useRevokeFcmTokenMutation();
  const refreshFcmToken = useHandler(async function refreshFcmToken() {
    if (revokeFcmTokenMutation.isLoading || !fcmTokenQuery.isSuccess) return;

    const result = await safePromise(async () => {
      await revokeFcmTokenMutation.mutateAsync();
      // The `revokeFcmTokenMutation` will reset the query upon settlement. If
      // the reset succeeds, the invalidation will also succeed. Additionally,
      // since the Firebase SDK retrieves the cached token first, it will not
      // make duplicate requests.
      await safeInvalidateQuery(fcmTokenQuery.key);
    });
    if (result.error) {
      message.error(t("notification.setting.fcmToken.refresh.error.message"));
      return;
    }

    message.success(t("notification.setting.fcmToken.refresh.success.message"));
  });

  return (
    <Button
      onClick={refreshFcmToken}
      disabled={!fcmTokenQuery.isSuccess}
      loading={revokeFcmTokenMutation.isLoading}
    >
      {t("notification.setting.fcmToken.refresh")}
    </Button>
  );
};

const NotificationSettings: FC = () => {
  const { t } = useTranslation();
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const [form] = Form.useForm<Partial<FormValues>>();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = useCurrentQuery();
  const message = useMessage();
  const mutation = cantata.notificationSetting.useUpdate(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
        safeInvalidateQuery(query.key);
        form.resetFields();
      },
    },
  );

  const onFinish = useHandler<FormProps<Partial<FormValues>>["onFinish"]>(
    (values) => {
      const mergedValues = mergeWith(
        {},
        initialValues,
        query.data,
        values,
        (objValue, srcValue) => {
          if (srcValue === undefined) return objValue;
          return srcValue;
        },
      );
      mutation.mutate(mergedValues);
    },
  );

  return (
    <Form<Partial<FormValues>>
      form={form}
      disabled={mutation.isLoading}
      onFinish={onFinish}
    >
      <FormLayout.Container
        submit={
          <FormLayout.SubmitButton>
            {t("common.update")}
          </FormLayout.SubmitButton>
        }
      >
        <div css={styles.container}>
          <FormLayout.RowField
            title={t("notification.setting.field.label")}
            content={
              <>
                <FormLayout.Item width="wide">
                  <RequestPermission />
                </FormLayout.Item>
                <FormLayout.Item width="full">
                  <FormTable />
                </FormLayout.Item>
              </>
            }
          />
          <FormLayout.RowField
            title={<Trans i18nKey="notification.setting.fcmToken.title" />}
            content={
              <>
                <FormLayout.Item width="wide" css={styles.content}>
                  <div css={styles.description}>
                    <Trans i18nKey="notification.setting.fcmToken.desc" />
                  </div>
                  <div css={styles.buttons}>
                    <CopyFcmTokenButton />
                    <RefreshFcmTokenButton />
                  </div>
                </FormLayout.Item>
              </>
            }
          />
        </div>
      </FormLayout.Container>
    </Form>
  );
};

export { NotificationSettings };
