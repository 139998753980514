import { css } from "@emotion/react";
import type { TableColumnProps as ColumnProps } from "antd";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { ExternalLink } from "@/components/ExternalLink";
import { Table } from "@/components/Table";
import { SubText, Text } from "@/components/Typography";
import { RoleName } from "@/resources/role/RoleName";

const cssActionWrapper = css`
  margin-bottom: 12px;
  margin-left: auto;
  gap: 16px;
`;

const Roles: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const rolesQuery = cantata.role.useListRoles({
    params: {
      orgId,
    },
  });

  const columns = useMemo<ColumnProps<CantataTypes["Role"]>[]>(
    () => [
      {
        title: (
          <SubText>
            <Trans i18nKey="organization.roleName" />
          </SubText>
        ),
        dataIndex: "name",
        key: "name",
        render: (_, role) => (
          <Text>
            <RoleName role={role} />
          </Text>
        ),
      },
      {
        title: (
          <SubText>
            <Trans i18nKey="organization.roleDescription" />
          </SubText>
        ),
        dataIndex: "description",
        key: "description",
        render: (description: string) => (
          <Text>{description || EMPTY_STRING_PLACEHOLDER}</Text>
        ),
      },
      {
        title: (
          <SubText>
            <Trans i18nKey="organization.memberCount" />
          </SubText>
        ),
        dataIndex: "associatedUserCount",
        key: "associatedUserCount",
        render: (associatedUserCount: number) => (
          <Text>{associatedUserCount}</Text>
        ),
      },
    ],
    [],
  );
  return (
    <>
      <div css={cssActionWrapper}>
        <ExternalLink
          href={t("feature.roleManagement.helpCenter.link")}
          trailingIcon
        >
          {t("organization.rolePermissionList")}
        </ExternalLink>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={rolesQuery.data?.roles}
        loading={rolesQuery.isLoading}
      />
    </>
  );
};

export { Roles };
