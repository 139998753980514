import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { ClassNames } from "@emotion/react";

import { Popover } from "@/components/Popover";

namespace Trigger {
  export type Ref = Popover.Ref;
  export type Props = Popover.Props;
}

/**
 * Similar to `Popover` but disable the container styles.
 */
const Trigger = forwardRef<Trigger.Ref, Trigger.Props>(
  function Trigger(props, ref) {
    return (
      <ClassNames>
        {({ css }) => (
          <Popover
            ref={ref}
            overlayClassName={css`
              .ant-popover-inner {
                padding: 0;
                box-shadow: none;
              }
            `}
            {...props}
          />
        )}
      </ClassNames>
    );
  },
);

assignDisplayName(Trigger, "Trigger");

export { Trigger };
