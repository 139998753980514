import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { random } from "@chatbotgang/etude/string/random";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import { flutterNotificationPermissionMapToNotificationPermission } from "@zeffiroso/flutter-webview-app-sdk/definitions";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { isSupported } from "@/app/messaging/isSupported";

const requestNotificationPermissionMutationMutationKey = [
  `requestNotificationPermissionMutation_${random()}`,
];

function getNotificationPermission(): NotificationPermission {
  if (flutterWebViewAppSdk.isFlutterWebViewApp) {
    return "default";
  }
  if (!("Notification" in window)) return "denied";
  return Notification.permission;
}

const useStatusStore = createWithEqualityFn<{
  permission: NotificationPermission;
}>()(
  () => ({
    permission: getNotificationPermission(),
  }),
  shallow,
);

(async function sync() {
  if (
    flutterWebViewAppSdk.isFlutterWebViewApp &&
    flutterWebViewAppSdk.eventEmitter
  ) {
    flutterWebViewAppSdk.eventEmitter.on(
      "flutterPermissionUpdated",
      (permission) => {
        useStatusStore.setState({ permission });
      },
    );
    (async function init() {
      flutterWebViewAppSdk.log("init");
      const permission =
        await flutterWebViewAppSdk.requestNotificationPermission();
      flutterWebViewAppSdk.log(inspectMessage`init, permission: ${permission}`);
      useStatusStore.setState({
        permission:
          flutterNotificationPermissionMapToNotificationPermission(permission),
      });
    })();
    return;
  }
  if (!(await isSupported())) return;
  const permissionStatus = await navigator.permissions.query({
    name: "notifications",
  });
  permissionStatus.addEventListener("change", () => {
    useStatusStore.setState({ permission: getNotificationPermission() });
  });
})();

/**
 * React hook to get the current notification permission status.
 */
function useNotificationPermission() {
  return useStatusStore((state) => state.permission);
}

function useRequestNotificationPermissionMutation() {
  const mutation = useMutation({
    mutationKey: requestNotificationPermissionMutationMutationKey,
    mutationFn: async () => {
      // TODO: if flutterWebViewAppSdk.isFlutterWebViewApp?
      return Notification.requestPermission();
    },
    onSuccess: (permission) => {
      useStatusStore.setState({ permission });
    },
  });
  return mutation;
}

function useIsRequestingNotificationPermission() {
  const counts = useIsMutating(
    requestNotificationPermissionMutationMutationKey,
  );
  return counts > 0;
}

export {
  getNotificationPermission,
  useIsRequestingNotificationPermission,
  useNotificationPermission,
  useRequestNotificationPermissionMutation,
};
