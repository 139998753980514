import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { Trans } from "@/app/i18n/Trans";
import img from "@/routes/Chat/ui/MemberProfilePanel/Membership/notBound.png";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  NotBound: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    textAlign: "center",
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
    img: {
      width: 80,
      height: 64,
    },
  }),
});

namespace NotBound {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {}
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const NotBoundInternal = forwardRef<NotBound.Ref, NotBound.Props>(
  function NotBoundInternal(props, ref) {
    return (
      <div css={styles.NotBound} ref={ref} {...props}>
        <div>
          <img src={img} alt="Not Bound" />
        </div>
        <Trans i18nKey="chat.memberProfilePanel.membership.member.notBound.label" />
      </div>
    );
  },
);

/**
 * Placeholder for the not bound member.
 */
const NotBound = Object.assign(NotBoundInternal, {
  styles,
});

assignDisplayName(NotBound, "NotBound");

export { NotBound };
