import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import {
  type MutationKey,
  type QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { FIREBASE_CONTENT_STORAGE_VAPID_KEY } from "@zeffiroso/env";
import { caacFirebaseApp } from "@zeffiroso/firebase-app-caac";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import {
  deleteToken as deleteFcmToken,
  getMessaging,
  getToken as getFcmToken,
  type Messaging,
} from "firebase/messaging";
import { useMemo } from "react";

import {
  useIsRequestingNotificationPermission,
  useNotificationPermission,
} from "@/app/browser/notificationPermission";
import { isSupported } from "@/app/messaging/isSupported";
import { getServiceWorkerRegistration } from "@/app/sw/getServiceWorkerRegistration";
import { cantata } from "@/cantata";

const fcmTokenQueryKey = [`fcmToken_${random()}`] satisfies QueryKey;
const revokeFcmTokenMutationKey = [
  `revokeFcmToken_${random()}`,
] satisfies MutationKey;

/**
 * FCM stands for Firebase Cloud Messaging.
 */
const fcm = (() => {
  /**
   * To get the Firebase messaging. It will return `undefined` if it's not
   * supported by the browser.
   */
  async function getFirebaseMessaging(): Promise<Messaging | undefined> {
    if (flutterWebViewAppSdk.isFlutterWebViewApp) return;
    if (!(await isSupported())) return;
    return getMessaging(caacFirebaseApp);
  }

  let tokenGotten = false;

  /**
   * `getToken()` but with customized options.
   */
  async function getToken(): Promise<string> {
    if (flutterWebViewAppSdk.isFlutterWebViewApp) {
      return flutterWebViewAppSdk.getFcmToken();
    }
    if (!(await isSupported()))
      throw new Error("FCM is not supported in this browser");
    const serviceWorkerRegistration = await getServiceWorkerRegistration();
    if (!serviceWorkerRegistration)
      throw new Error("Service worker is not registered");
    const firebaseMessaging = await getFirebaseMessaging();
    if (!firebaseMessaging)
      throw new Error("Firebase messaging is not initialized");

    const token = await getFcmToken(firebaseMessaging, {
      vapidKey: FIREBASE_CONTENT_STORAGE_VAPID_KEY,
      serviceWorkerRegistration,
    });
    tokenGotten = true;
    return token;
  }

  /**
   * `deleteToken()` but only works if the token is already gotten.
   */
  async function deleteToken(): Promise<void> {
    if (flutterWebViewAppSdk.isFlutterWebViewApp) {
      return flutterWebViewAppSdk.revokeFcmToken();
    }
    if (!(await isSupported()))
      throw new Error("FCM is not supported in this browser");
    const firebaseMessaging = await getFirebaseMessaging();
    if (!firebaseMessaging)
      throw new Error("Firebase messaging is not initialized");

    if (!tokenGotten) return;
    await deleteFcmToken(firebaseMessaging);
  }

  function useFcmTokenQuery() {
    const notificationPermission = useNotificationPermission();
    const query = useQuery({
      queryKey: fcmTokenQueryKey,
      queryFn: getToken,
      refetchInterval: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      enabled: notificationPermission === "granted",
    });
    const ret = useMemo(
      () => ({
        key: fcmTokenQueryKey,
        ...query,
      }),
      [query],
    );
    return ret;
  }

  /**
   * Revoke the FCM token and invalidate the query.
   */
  function useRevokeFcmTokenMutation() {
    const queryClient = useQueryClient();
    const fcmTokenQuery = useFcmTokenQuery();
    const revokeFromCantataMutation = cantata.fcmToken.useRevoke();
    const revoke = useHandler(async function revoke() {
      await Promise.all([
        (async function revoke() {
          if (!fcmTokenQuery.data) return;
          revokeFromCantataMutation.mutateAsync({
            fcmToken: fcmTokenQuery.data,
          });
        })(),
        deleteToken(),
      ]);
    });
    return useMutation({
      mutationKey: revokeFcmTokenMutationKey,
      mutationFn: revoke,
      onSettled: async () =>
        queryClient.resetQueries(fcmTokenQueryKey, { exact: true }),
    });
  }

  function useDeviceNotificationEnabled() {
    const notificationPermission = useNotificationPermission();
    const fcmTokenQuery = useFcmTokenQuery();
    return notificationPermission === "granted" && fcmTokenQuery.isSuccess;
  }

  function useDeviceNotificationRequesting() {
    const isRequestingNotificationPermission =
      useIsRequestingNotificationPermission();
    const query = useFcmTokenQuery();
    return (
      isRequestingNotificationPermission ||
      (query.isLoading && query.isFetching)
    );
  }

  const fcm = {
    useFcmTokenQuery,
    useRevokeFcmTokenMutation,
    useDeviceNotificationRequesting,
    useDeviceNotificationEnabled,
  };

  return fcm;
})();

export { fcm };
