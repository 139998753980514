import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { search } from "@chatbotgang/etude/string/search";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import type { ComponentProps, ElementRef, RefObject } from "react";
import { useMemo, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Avatar } from "@/components/Avatar";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { BarLoadingBlock } from "@/components/Loading/BarLoading";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Paper } from "@/components/Paper";
import { Trigger } from "@/components/Trigger";
import { Text } from "@/components/Typography";
import { useLegatoEvent } from "@/legato";
import { UserName } from "@/resources/user/UserName";
import { UserNameById } from "@/resources/user/UserNameById";
import { useAssigneeSelectorContext } from "@/routes/Chat/components/Assignee/AssigneeSelector/AssigneeSelectorContext";
import { FilterBy } from "@/routes/Chat/components/Assignee/AssigneeSelector/FilterBy";
import { OnDutyStatusBadge } from "@/routes/Chat/components/Assignee/AssigneeSelector/OnDutyStatusBadge";
import { DropdownSwitchContext } from "@/routes/Chat/components/Assignee/AssigneeSelector/useDropdownSwitch";
import { useRecentAssignees } from "@/routes/Chat/components/Assignee/useRecentAssignees";
import { useSize } from "@/shared/hooks/useSize";
import { CrescendoLab as LogoSvg } from "@/shared/icons/common/CrescendoLab";

const trigger: Trigger.Props["trigger"] = ["click"];

const cssPaper = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 0;
  gap: 4px;
`;

const OptionItem = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 4px 24px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: ${theme.colors.neutral002};
  }

  ${(props) =>
    props.$selected &&
    css`
      background: ${theme.colors.blue002};
    `};
`;

const GroupHeader = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  padding: 8px 16px;
  background: white;
  color: ${theme.colors.neutral007};
`;

const TeamUserGroup = memo(function ({ team }: { team: CantataTypes["Team"] }) {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const usersQuery = cantata.team.useListUsers({
    params: {
      orgId,
      teamId: team.id,
    },
  });

  useLegatoEvent("user-updated", () => {
    safeInvalidateQuery(usersQuery.key);
  });

  const { searchValue, assignee, onAssigneeChange } =
    useAssigneeSelectorContext();

  const filteredUsers = useMemo(() => {
    if (usersQuery.data === undefined) return [];

    return usersQuery.data.users.filter((user) => {
      if (user.status !== "active") return false;
      return search(user.name, searchValue);
    });
  }, [searchValue, usersQuery.data]);

  if (usersQuery.isLoading) return <BarLoadingBlock />;

  if (usersQuery.isError)
    return <Alert type="error" message={usersQuery.error.message} />;

  if (searchValue && filteredUsers.length === 0) return null;

  return (
    <Group>
      <GroupHeader>{team.name}</GroupHeader>
      <GroupBody>
        {filteredUsers.map(function (user) {
          const selected =
            assignee?.type === "user-in-team" &&
            assignee?.userId === user.id &&
            assignee?.teamId === team.id;
          return (
            <OptionItem
              $selected={selected}
              key={`team-${team.id}-user-${user.id}`}
              onClick={() => {
                onAssigneeChange({
                  type: "user-in-team",
                  userId: user.id,
                  teamId: team.id,
                });
              }}
            >
              <ItemWithIcon
                selected={selected}
                startIcon={
                  <OnDutyStatusBadge onDutyStatus={user.onDutyStatus}>
                    <Avatar size="small" src={user.avatar ?? ""} />
                  </OnDutyStatusBadge>
                }
              >
                <UserNameById userId={user.id} />
              </ItemWithIcon>
            </OptionItem>
          );
        })}
      </GroupBody>
    </Group>
  );
});

const UserOption = memo(function ({ user }: { user: CantataTypes["User"] }) {
  const { onAssigneeChange, assignee } = useAssigneeSelectorContext();
  const selected =
    assignee !== undefined &&
    assignee.type === "user" &&
    assignee.userId === user.id;

  return (
    <OptionItem
      $selected={selected}
      key={`user-${user.id}`}
      onClick={() => {
        onAssigneeChange({ type: "user", userId: user.id });
      }}
    >
      <ItemWithIcon
        selected={selected}
        startIcon={
          <OnDutyStatusBadge onDutyStatus={user.onDutyStatus}>
            <Avatar size="small" src={user.avatar ?? ""} />
          </OnDutyStatusBadge>
        }
      >
        <UserName user={user} />
      </ItemWithIcon>
    </OptionItem>
  );
});

const RecentTeamUserOption = memo(function ({
  user,
  team,
}: {
  user: CantataTypes["User"];
  team: CantataTypes["Team"];
}) {
  const { onAssigneeChange, assignee } = useAssigneeSelectorContext();
  const selected =
    !assignee || assignee.type !== "user-in-team"
      ? false
      : assignee.userId === user.id && assignee.teamId === team.id;
  return (
    <OptionItem
      $selected={selected}
      key={`team-${team.id}-user-${user.id}`}
      onClick={() => {
        onAssigneeChange({
          type: "user-in-team",
          userId: user.id,
          teamId: team.id,
        });
      }}
    >
      <ItemWithIcon
        selected={selected}
        startIcon={
          <OnDutyStatusBadge onDutyStatus={user.onDutyStatus}>
            <Avatar size="small" src={user.avatar ?? ""} />
          </OnDutyStatusBadge>
        }
        mainBlockProps={{
          css: css`
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          `,
        }}
      >
        <UserName user={user} />
        <Text
          css={css`
            color: ${theme.colors.neutral007};
            font-size: 12px;
          `}
        >
          {team.name}
        </Text>
      </ItemWithIcon>
    </OptionItem>
  );
});

const TeamsUsersGroups = memo(function TeamsGroups({
  teams,
}: {
  teams: CantataTypes["Team"][];
}) {
  return (
    <>
      {teams.map((team) => (
        <TeamUserGroup key={team.id} team={team} />
      ))}
    </>
  );
});

const TeamOption = memo(function ({ team }: { team: CantataTypes["Team"] }) {
  const { onAssigneeChange, assignee } = useAssigneeSelectorContext();
  const selected = Boolean(
    assignee && assignee.type === "team" && assignee.teamId === team.id,
  );

  return (
    <OptionItem
      $selected={selected}
      key={`team-${team.id}`}
      onClick={() => {
        onAssigneeChange({ type: "team", teamId: team.id });
      }}
    >
      <ItemWithIcon
        selected={selected}
        startIcon={
          <Avatar
            size="small"
            style={{ backgroundColor: theme.colors.blue006, fontSize: 16 }}
            icon={<LogoSvg />}
          />
        }
      >
        {team.name}
      </ItemWithIcon>
    </OptionItem>
  );
});

const TeamsGroup = memo(function TeamsGroup({
  teams,
}: {
  teams: CantataTypes["Team"][];
}) {
  const { searchValue } = useAssigneeSelectorContext();

  const filteredTeams = useMemo(() => {
    return teams.filter((team) => {
      return search(team.name, searchValue);
    });
  }, [searchValue, teams]);

  if (searchValue && filteredTeams.length === 0) return null;

  return (
    <Group>
      <GroupHeader>
        <Trans i18nKey="chat.manualAssignment.assigneeSelector.option.group.team.label" />
      </GroupHeader>
      <GroupBody>
        {filteredTeams.map((team) => (
          <TeamOption key={team.id} team={team} />
        ))}
      </GroupBody>
    </Group>
  );
});

const AgentsGroup = memo(function AgentsGroup({
  users,
}: {
  users: CantataTypes["User"][];
}) {
  const { searchValue } = useAssigneeSelectorContext();

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      if (user.status !== "active") return false;
      return search(user.name, searchValue);
    });
  }, [searchValue, users]);

  if (searchValue && filteredUsers.length === 0) return null;

  return (
    <Group>
      <GroupHeader>
        <Trans i18nKey="chat.manualAssignment.assigneeSelector.option.group.agent.label" />
      </GroupHeader>
      <GroupBody>
        {filteredUsers.map((user) => (
          <UserOption key={user.id} user={user} />
        ))}
      </GroupBody>
    </Group>
  );
});

const UnassignedOptionItem = styled(OptionItem)`
  padding: 4px 16px;
`;

const UnassignedOption = memo(function UnassignedOption() {
  const { onAssigneeChange, assignee } = useAssigneeSelectorContext();
  const selected = assignee?.type === "unassigned";

  return (
    <UnassignedOptionItem
      $selected={selected}
      onClick={() => {
        onAssigneeChange({ type: "unassigned" });
      }}
    >
      <ItemWithIcon selected={selected} startIcon={<Avatar size="small" />}>
        <Trans i18nKey="glossary.unassigned" />
      </ItemWithIcon>
    </UnassignedOptionItem>
  );
});

const AssigneeDropdownContent = () => {
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [filterBy, setFilterBy] = useState<"agent" | "team" | "all">("all");
  const { searchValue } = useAssigneeSelectorContext();
  const { recentAssignees } = useRecentAssignees();

  const teamsQuery = cantata.team.useList({
    params: {
      orgId,
    },
  });

  const usersQuery = cantata.user.useList({
    params: {
      orgId,
    },
  });

  useLegatoEvent("user-updated", () => {
    safeInvalidateQuery(usersQuery.key);
  });

  const recentAssigneeOptions = useMemo(
    function recentAssigneeOptions() {
      return recentAssignees.flatMap((recentAssignee) => {
        if (!usersQuery.isSuccess || usersQuery.data.users.length === 0)
          return [];
        if (!teamsQuery.isSuccess || teamsQuery.data.teams.length === 0)
          return [];
        if (filterBy === "team" && recentAssignee.type !== "team") return [];
        if (filterBy === "agent" && recentAssignee.type !== "user") return [];

        if (recentAssignee.type === "team") {
          const team = teamsQuery.data.teams
            .filter((team) => search(team.name, searchValue))
            .find((team) => team.id === recentAssignee.teamId);
          if (!team) return [];

          return (
            <TeamOption key={`team-${recentAssignee.teamId}`} team={team} />
          );
        }

        const user = usersQuery.data.users
          .filter((user) => {
            if (user.status !== "active") return false;
            return search(user.name, searchValue);
          })
          .find((user) => user.id === recentAssignee.userId);

        if (!user) return [];
        if (recentAssignee.type === "user")
          return <UserOption key={`user-${user.id}`} user={user} />;

        // handle user in team
        const team = teamsQuery.data.teams.find(
          (team) => team.id === recentAssignee.teamId,
        );
        if (!team) return [];
        return (
          <RecentTeamUserOption
            key={`team/user-${user.id}`}
            user={user}
            team={team}
          />
        );
      });
    },
    [
      filterBy,
      recentAssignees,
      searchValue,
      teamsQuery.data?.teams,
      teamsQuery.isSuccess,
      usersQuery.data?.users,
      usersQuery.isSuccess,
    ],
  );

  if (teamsQuery.isLoading || usersQuery.isLoading) return <BarLoadingBlock />;

  if (teamsQuery.isError)
    return <Alert type="error" message={teamsQuery.error.message} />;

  if (usersQuery.isError)
    return <Alert type="error" message={usersQuery.error.message} />;

  return (
    <div>
      <UnassignedOption />
      <FilterBy value={filterBy} onChange={(draft) => setFilterBy(draft)} />
      <div
        css={css`
          overflow: auto;
          max-height: 250px;
        `}
      >
        {recentAssigneeOptions.length === 0 ? null : (
          <Group>
            <GroupHeader>
              <Trans i18nKey="chat.manualAssignment.assigneeSelector.option.group.recent.label" />
            </GroupHeader>
            <GroupBody>{recentAssigneeOptions}</GroupBody>
          </Group>
        )}
        {filterBy === "agent" ? null : (
          <TeamsGroup teams={teamsQuery.data.teams} />
        )}
        {filterBy === "all" ? (
          <TeamsUsersGroups teams={teamsQuery.data.teams} />
        ) : null}
        {filterBy === "team" ? null : (
          <AgentsGroup users={usersQuery.data.users} />
        )}
      </div>
    </div>
  );
};

export const AssigneeSelectorProvider = memo(function AssigneeSelectorProvider({
  value: dropdownSwitchContextValue,
  children,
}: {
  value: ReturnType<typeof useSwitch>;
  children: Trigger.Props["children"];
}) {
  const mergeFormDisabled = useMergeFormDisabled();
  const [dropdownActive, dropdownSwitch] = dropdownSwitchContextValue;
  const childrenContainerRef = useRef<ElementRef<"span">>(null);
  const onOpenChange = useHandler<Trigger.Props["onOpenChange"]>(
    function onOpenChange(open) {
      if (open) return;
      if (
        childrenContainerRef.current &&
        /**
         * To prevent the dropdown from switching off when focus is still in the
         * dropdown content. This might happen when the user clicks the input
         * slowly, and the dropdown will be closed when the mouse up event is
         * triggered.
         */
        childrenContainerRef.current.querySelectorAll(":focus").length > 0
      )
        return;

      dropdownSwitch.off();
    },
  );
  const childrenContainerSize = useSize(childrenContainerRef);
  /**
   * Apply the width of the children container to the dropdown content to make
   * sure the dropdown content is not narrower than the children container.
   */
  const mergedCssPaper = useMemo(
    () => css`
      ${cssPaper}
      ${!childrenContainerSize
        ? null
        : `min-width: ${childrenContainerSize.width}px;`}
    `,
    [childrenContainerSize],
  );
  return (
    <DropdownSwitchContext.Provider value={dropdownSwitchContextValue}>
      <Trigger
        disabled={mergeFormDisabled(false)}
        open={dropdownActive}
        content={
          <Paper css={mergedCssPaper}>
            <AssigneeDropdownContent />
          </Paper>
        }
        onOpenChange={onOpenChange}
        trigger={trigger}
        placement="bottomLeft"
      >
        <SpanWithExternalRef sizeObserverRef={childrenContainerRef}>
          {children}
        </SpanWithExternalRef>
      </Trigger>
    </DropdownSwitchContext.Provider>
  );
});

const SpanWithExternalRef = memo(
  forwardRef<
    ElementRef<"span">,
    ComponentProps<"span"> & {
      sizeObserverRef: RefObject<ElementRef<"span">>;
    }
  >(function SpanWithExternalRef({ sizeObserverRef, ...props }, ref) {
    return <span {...props} ref={mergeRefs([ref, sizeObserverRef])} />;
  }),
);
