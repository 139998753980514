import filenamify from "filenamify/browser";

import { blobToDataUrl } from "./blobToDataUrl";

namespace downloadFile {
  export type Options = {
    obj: Parameters<typeof URL.createObjectURL>[0];
    fileName: string;
  };
  export type Base64Options = {
    obj: Blob;
    base64: true;
    fileName: string;
  };
  export type DownloadFileWithHref = (options: {
    href: string;
    fileName: string;
  }) => void;
}

const downloadFileWithHref: downloadFile.DownloadFileWithHref = (options) => {
  const a = document.createElement("a");
  a.href = options.href;
  a.download = filenamify(options.fileName);
  a.click();
};

function downloadFile(options: downloadFile.Options): void;
function downloadFile(options: downloadFile.Base64Options): Promise<void>;
function downloadFile(
  options: downloadFile.Options | downloadFile.Base64Options,
): void | Promise<void> {
  if ("base64" in options && options.base64) {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        try {
          const dataUrl = await blobToDataUrl(options.obj);
          const href = dataUrl;
          downloadFileWithHref({
            href,
            fileName: options.fileName,
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      })();
    });
  }
  const href = URL.createObjectURL(options.obj);
  downloadFileWithHref({
    href,
    fileName: options.fileName,
  });
  URL.revokeObjectURL(href);
}

export { downloadFile };
