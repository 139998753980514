import { Iso8601DateSchema } from "@chatbotgang/etude/zod/Iso8601DateSchema";
import { z } from "zod";

import { safeSchema } from "./safeSchema";

/**
 * Creates a flexible schema that can parse strings against a date format.
 * schemas.
 *
 * ```ts
 * const result = SafeIso8601DateSchema.parse("2021-01-01T00:00:00Z"); // { value: Date(2021-01-01T00:00:00Z) }
 * const unexpectedDate = SafeIso8601DateSchema.parse("Yellow"); // { raw: "Yellow" }
 * ```
 */
const SafeIso8601DateSchema = safeSchema(
  Iso8601DateSchema,
  /**
   * Should always be a string
   */
  z.string(),
);

export { SafeIso8601DateSchema };
