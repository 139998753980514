import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { Overwrite } from "@mui/types";
import { type ElementRef, useMemo } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";

const styles = defineStyles({});

const t = fakeT;

const transLookup = define<
  Array<{
    type: CantataTypes["Order"]["type"];
    status: Extract<
      CantataTypes["Order"]["ecFinancialStatus"],
      { expected: true }
    >["value"];
    i18nKey: string;
  }>
>([
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.authorized.label"),
    status: "AUTHORIZED",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.expired.label"),
    status: "EXPIRED",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.paid.label"),
    status: "PAID",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.partiallyPaid.label"),
    status: "PARTIALLY_PAID",
  },
  {
    type: "shopify",
    i18nKey: t(
      "resource.order.financialStatus.shopify.partiallyRefunded.label",
    ),
    status: "PARTIALLY_REFUNDED",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.pending.label"),
    status: "PENDING",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.refunded.label"),
    status: "REFUNDED",
  },
  {
    type: "shopify",
    i18nKey: t("resource.order.financialStatus.shopify.voided.label"),
    status: "VOIDED",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.temp.label"),
    status: "temp",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.pending.label"),
    status: "pending",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.failed.label"),
    status: "failed",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.expired.label"),
    status: "expired",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.completed.label"),
    status: "completed",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.refunding.label"),
    status: "refunding",
  },
  {
    type: "shopline",
    i18nKey: t("resource.order.financialStatus.shopline.refunded.label"),
    status: "refunded",
  },
  {
    type: "shopline",
    i18nKey: t(
      "resource.order.financialStatus.shopline.partiallyRefunded.label",
    ),
    status: "partially_refunded",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.paid.label"),
    status: "paid",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.pending.label"),
    status: "pending",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.cod.label"),
    status: "cod",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.failed.label"),
    status: "failed",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.abandoned.label"),
    status: "abandoned",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.refunded.label"),
    status: "refunded",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.noRefunded.label"),
    status: "no_refunded",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.pendingRefund.label"),
    status: "pending_refund",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.processing.label"),
    status: "processing",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.remitted.label"),
    status: "remitted",
  },
  {
    type: "cyberbiz",
    i18nKey: t(
      "resource.order.financialStatus.cyberbiz.pendingPartialRefund.label",
    ),
    status: "pending_partial_refund",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.partialRefunded.label"),
    status: "partial_refunded",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.refunding.label"),
    status: "refunding",
  },
  {
    type: "cyberbiz",
    i18nKey: t("resource.order.financialStatus.cyberbiz.refundFailed.label"),
    status: "refund_failed",
  },
]);

namespace FinancialStatus {
  export type Ref = ElementRef<"span">;
  export interface OwnProps {
    type: CantataTypes["Order"]["type"];
    status: CantataTypes["Order"]["ecFinancialStatus"];
  }
  export type Props = Overwrite<ComponentProps<"span">, OwnProps>;
}

type StatusTrans = (typeof transLookup)[number];

const unexpected = {
  i18nKey: t("resource.order.status.unknown.label"),
} satisfies { i18nKey: string };

const supportFinicalStatus = new Set(transLookup.map((lookup) => lookup.type));

function isSupportFinicalStatus(type: CantataTypes["Order"]["type"]) {
  return supportFinicalStatus.has(type);
}

const useFinancialStatusTrans = ({
  status,
  type,
}: FinancialStatus.OwnProps) => {
  return useMemo<StatusTrans | undefined>(
    function lookupTrans() {
      if (!status || !status.expected) return;
      const matched = transLookup.find(
        (lookup) => lookup.type === type && lookup.status === status.value,
      );
      return matched;
    },
    [status, type],
  );
};

const FinancialStatusInternal = forwardRef<
  FinancialStatus.Ref,
  FinancialStatus.Props
>(function FinancialStatusInternal({ type, status, ...props }, ref) {
  const enabledRenderRawEcValue = useFeatureFlag("renderRawEcValue");
  const statusTrans = useFinancialStatusTrans({ status, type });

  return (
    <span {...props} ref={ref}>
      {!status ? (
        EMPTY_STRING_PLACEHOLDER
      ) : (
        <>
          <Trans
            i18nKey={!statusTrans ? unexpected.i18nKey : statusTrans.i18nKey}
          />
          {statusTrans && !enabledRenderRawEcValue ? null : (
            <>
              {HIERARCHY_STRING_JOINER}
              {!status.expected ? status.raw : status.value}
            </>
          )}
        </>
      )}
    </span>
  );
});

/**
 * Display the financial status of an order.
 */
const FinancialStatus = Object.assign(FinancialStatusInternal, {
  styles,
  unexpected,
  useFinancialStatusTrans,
  isSupportFinicalStatus,
});

assignDisplayName(FinancialStatus, "FinancialStatus");

export { FinancialStatus };
