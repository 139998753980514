import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";

import { cantataClient } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import type {
  AttachmentFeature as Feature,
  BaseParams,
} from "@/resources/attachment/uploadToFirebaseStorage";
import { internal as uploadToFirebaseStorage } from "@/resources/attachment/uploadToFirebaseStorage";

type Params<
  T extends CantataTypes["Channel"]["type"],
  F extends Feature,
> = BaseParams<F> & {
  channelType: T;
  orgId: CantataTypes["Org"]["id"];
  channelId: CantataTypes["Channel"]["id"];
  /**
   * Triggered during upload, when the progress changes.
   * @param progress 0 ~ 1
   * @returns
   */
  onProgress?: (
    /**
     * 0 ~ 1
     */
    progress: number,
  ) => void;
};

async function uploadLineAttachment<F extends Feature>(
  params: Params<"line", F>,
) {
  const onNext: typeof params.onNext = (...args) => {
    params.onNext?.(...args);
    const uploadTaskSnapshot = args[0];
    const progress =
      uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes;
    params.onProgress?.(progress);
  };
  const uploadResult = await uploadToFirebaseStorage({
    ...params,
    randomizeFileName: false,
    onNext,
  });
  params.signal?.throwIfAborted();
  params.onProgress?.(1);
  return {
    inputParams: params,
    uploadResult,
  };
}

const uploadFbAttachment = (function declareUploadFbAttachment() {
  function getFileType(file: File): CantataTypes["FbAttachmentType"] {
    if (file.type.startsWith("image/")) return "image";

    if (file.type.startsWith("video/")) return "video";

    if (file.type.startsWith("audio/")) return "audio";

    return "file";
  }

  return async function uploadFbAttachment<F extends Feature>(
    params: Params<"fb", F>,
  ) {
    const uploadProgress = 0.8;
    const onNext: typeof params.onNext = (...args) => {
      params.onNext?.(...args);
      const uploadTaskSnapshot = args[0];
      const progress =
        (uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes) *
        uploadProgress;
      params.onProgress?.(progress);
    };
    const fileType = getFileType(params.file);
    const uploadResult = await uploadToFirebaseStorage({
      ...params,
      onNext,
      randomizeFileName: fileType !== "file",
    });
    params.signal?.throwIfAborted();
    params.onProgress?.(uploadProgress);
    const apiResult = await cantataClient.fbAttachment.create(
      {
        type: fileType,
        url: uploadResult.downloadUrl,
      },
      {
        params: {
          orgId: params.orgId,
          channelId: params.channelId,
        },
        signal: params.signal,
      },
    );
    params.signal?.throwIfAborted();
    params.onProgress?.(1);
    return {
      inputParams: params,
      uploadResult,
      apiResult,
    };
  };
})();

const uploadIgAttachment = (function declareUploadIgAttachment() {
  function getFileType(file: File): CantataTypes["IgAttachmentType"] {
    if (file.type.startsWith("image/")) return "image";

    if (file.type.startsWith("video/")) return "video";

    if (file.type.startsWith("audio/")) return "audio";

    const err = new Error(`Unsupported file type: ${file.type}`);
    err.name = "UnsupportedFileType";
    throw err;
  }

  return async function uploadIgAttachment<F extends Feature>(
    params: Params<"ig", F>,
  ) {
    const uploadProgress = 0.8;
    const onNext: typeof params.onNext = (...args) => {
      params.onNext?.(...args);
      const uploadTaskSnapshot = args[0];
      const progress =
        (uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes) *
        uploadProgress;
      params.onProgress?.(progress);
    };
    const uploadResult = await uploadToFirebaseStorage({
      ...params,
      onNext,
    });
    params.signal?.throwIfAborted();
    params.onProgress?.(uploadProgress);
    const apiResult = await cantataClient.igAttachment.create(
      {
        type: getFileType(params.file),
        url: uploadResult.downloadUrl,
      },
      {
        params: {
          orgId: params.orgId,
          channelId: params.channelId,
        },
        signal: params.signal,
      },
    );
    params.signal?.throwIfAborted();
    params.onProgress?.(1);
    return {
      inputParams: params,
      uploadResult,
      apiResult,
    };
  };
})();

const uploadWccsAttachment = (function declareUploadWccsAttachment() {
  return async function uploadWccsAttachment<F extends Feature>(
    params: Params<"wccs", F>,
  ) {
    const uploadProgress = 1;
    const onNext: typeof params.onNext = (...args) => {
      params.onNext?.(...args);
      const uploadTaskSnapshot = args[0];
      const progress =
        (uploadTaskSnapshot.bytesTransferred / uploadTaskSnapshot.totalBytes) *
        uploadProgress;
      params.onProgress?.(progress);
    };
    const uploadResult = await uploadToFirebaseStorage({
      ...params,
      onNext,
    });
    params.signal?.throwIfAborted();
    params.onProgress?.(1);
    return {
      inputParams: params,
      uploadResult,
    };
  };
})();

function checkParams<T extends CantataTypes["Channel"]["type"]>(
  target: Params<any, any>,
  expected: T,
): target is Params<T, any> {
  return target.channelType === expected;
}

async function uploadAttachment<
  T extends CantataTypes["Channel"]["type"],
  F extends Feature,
>(params: Params<T, F>) {
  if (checkParams(params, "line")) return uploadLineAttachment(params);
  if (checkParams(params, "fb")) return uploadFbAttachment(params);
  if (checkParams(params, "ig")) return uploadIgAttachment(params);
  if (checkParams(params, "wccs")) return uploadWccsAttachment(params);
  throw new Error(
    inspectMessage`Unsupported channel type: ${params.channelType}`,
  );
}

export { uploadAttachment };
