import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { NotFoundContent } from "@/components/Empty/NotFoundContent";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { Prize } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Prizes/Prize";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Prizes: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  header: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral009,
  }),
  prizeList: css({
    display: "flex",
    flexDirection: "column",
    gap: "inherit",
    margin: 0,
  }),
});

namespace Prizes {
  export type Props = {
    prizes: Array<CantataTypes["Prize"]>;
  };
}

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div css={styles.Prizes}>
      <div css={styles.header}>
        <Trans i18nKey="chat.memberProfilePanel.membership.prize.content.title" />
      </div>
      {children}
    </div>
  );
};

const CLIP_SIZE = 2;

const Prizes: FC<Prizes.Props> = ({ prizes }) => {
  const [open, toggle] = useSwitch(false);
  const itemsToShow = useMemo(
    () => (open ? prizes : prizes.slice(0, CLIP_SIZE)),
    [open, prizes],
  );
  const member = memberQueriesContext.useMember();
  const trackPrizeDetailButtonClick = useHandler(
    function trackPrizeDetailButtonClick() {
      ga4Event("commerceProfileClick", {
        channelId: member.channelId,
        memberId: member.id,
        source: "prizeDetailButton",
      });
    },
  );

  if (itemsToShow.length === 0) {
    return (
      <Layout>
        <NotFoundContent
          message={
            <Trans i18nKey="chat.memberProfilePanel.membership.prize.noData" />
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <ul css={styles.prizeList}>
        {itemsToShow.map((prize) => (
          <Prize
            key={prize.prizeCode}
            prize={prize}
            detailButtonProps={{
              onClick: trackPrizeDetailButtonClick,
            }}
          />
        ))}
      </ul>
      {prizes.length <= CLIP_SIZE ? null : (
        <ShowMoreButton
          expanded={open}
          onChange={open ? toggle.off : toggle.on}
        />
      )}
    </Layout>
  );
};
export { Prizes };
