import type { ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

const channelTypeTransMap: {
  [channelType in CantataTypes["Channel"]["type"]]: {
    [key in
      | "breadcrumbLabel"
      | "header"
      | "subHeader"
      | "updateCredentialHeader"
      | "updateCredentialExpiredHeader"
      | "updateCredentialDescription"
      | "updateCredentialExpiredDescription"
      | "disconnectDescription"]: ReactNode;
  };
} = {
  line: {
    breadcrumbLabel: <Trans i18nKey="resource.channel.breadcrumb.line.label" />,
    header: <Trans i18nKey="resource.channel.header.line.label" />,
    subHeader: <Trans i18nKey="resource.channel.subHeader.line.label" />,
    updateCredentialHeader: "",
    updateCredentialExpiredHeader: "",
    updateCredentialDescription: "",
    updateCredentialExpiredDescription: "",
    disconnectDescription: "",
  },
  fb: {
    breadcrumbLabel: <Trans i18nKey="resource.channel.breadcrumb.fb.label" />,
    header: <Trans i18nKey="resource.channel.header.fb.label" />,
    subHeader: <Trans i18nKey="resource.channel.subHeader.fb.label" />,
    updateCredentialHeader: (
      <Trans i18nKey="resource.channel.update.credential.fb.header" />
    ),
    updateCredentialExpiredHeader: (
      <Trans i18nKey="resource.channel.update.credential.expired.fb.header" />
    ),
    updateCredentialDescription: (
      <Trans i18nKey="resource.channel.update.credential.fb.description" />
    ),
    updateCredentialExpiredDescription: (
      <Trans i18nKey="resource.channel.update.credential.expired.fb.description" />
    ),
    disconnectDescription: (
      <Trans i18nKey="resource.channel.update.connection.fb.description" />
    ),
  },
  ig: {
    breadcrumbLabel: <Trans i18nKey="resource.channel.breadcrumb.ig.label" />,
    header: <Trans i18nKey="resource.channel.header.ig.label" />,
    subHeader: <Trans i18nKey="resource.channel.subHeader.ig.label" />,
    updateCredentialHeader: (
      <Trans i18nKey="resource.channel.update.credential.ig.header" />
    ),
    updateCredentialExpiredHeader: (
      <Trans i18nKey="resource.channel.update.credential.expired.ig.header" />
    ),
    updateCredentialDescription: (
      <Trans i18nKey="resource.channel.update.credential.ig.description" />
    ),
    updateCredentialExpiredDescription: (
      <Trans i18nKey="resource.channel.update.credential.expired.ig.description" />
    ),
    disconnectDescription: (
      <Trans i18nKey="resource.channel.update.connection.ig.description" />
    ),
  },
  wccs: {
    breadcrumbLabel: "",
    header: "",
    subHeader: "",
    updateCredentialHeader: "",
    updateCredentialExpiredHeader: "",
    updateCredentialDescription: "",
    updateCredentialExpiredDescription: "",
    disconnectDescription: "",
  },
  whatsapp: {
    breadcrumbLabel: "",
    header: "",
    subHeader: "",
    updateCredentialHeader: "",
    updateCredentialExpiredHeader: "",
    updateCredentialDescription: "",
    updateCredentialExpiredDescription: "",
    disconnectDescription: "",
  },
};

export { channelTypeTransMap };
