import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import type { SafeIso8601DateSchema } from "@zeffiroso/utils/zod/SafeIso8601DateSchema";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import { RANGE_STRING_JOINER } from "@/appConstant";

import { useFormatDateTime } from ".";

type SafeIso8601Date = z.infer<typeof SafeIso8601DateSchema>;
type DateValue = Date | SafeIso8601Date | null;
type FormatOptions = Parameters<ReturnType<typeof useFormatDateTime>>[1];

namespace YmdHmRange {
  export interface Props {
    /**
     * The start date of the range.
     */
    startDate?: DateValue;
    /**
     * The end date of the range.
     */
    endDate?: DateValue;
    /**
     * formatOptions
     */
    formatOptions?: FormatOptions;
  }
}

const useFormatYmdHmRange = () => {
  const { t } = useTranslation();
  const formatTimestamp = useFormatDateTime();
  const formatYmdHm = useCallback(
    function formatYmdHm(
      datetime: DateValue | undefined,
      formatOptions?: FormatOptions,
    ): string | null {
      if (!datetime) return null;
      if (datetime instanceof Date)
        return formatTimestamp(datetime, formatOptions);
      if (datetime.isSuccess)
        return formatTimestamp(datetime.data, formatOptions);
      return datetime.raw;
    },
    [formatTimestamp],
  );

  return useCallback(
    function formatYmdHmRange(props: YmdHmRange.Props) {
      const startDate = formatYmdHm(props.startDate, props.formatOptions);
      const endDate = formatYmdHm(props.endDate, props.formatOptions);

      if (!startDate && !endDate)
        return t("resource.datetime.YmdHmRange.noLimit.label");

      if (startDate && !endDate) {
        return t("resource.datetime.YmdHmRange.onlyStart.label", {
          startDate,
        });
      }

      if (!startDate && endDate) {
        return t("resource.datetime.YmdHmRange.onlyEnd.label", {
          endDate,
        });
      }

      return `${startDate}${RANGE_STRING_JOINER}${endDate}`;
    },
    [formatYmdHm, t],
  );
};

const YmdHmRangeInternal: FC<YmdHmRange.Props> = (props) => {
  const formatYmdHmRange = useFormatYmdHmRange();
  const formattedRange = useMemo(
    () => formatYmdHmRange(props),
    [formatYmdHmRange, props],
  );
  return <>{formattedRange}</>;
};

/**
 * Display the datetime range of a resource.
 */
const YmdHmRange = Object.assign(YmdHmRangeInternal, {
  useFormatYmdHmRange,
});

assignDisplayName(YmdHmRange, "YmdHmRange");

export { YmdHmRange };
