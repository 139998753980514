import type { SafeIso4217CurrencySchema } from "@zeffiroso/utils/zod/SafeIso4217CurrencySchema";
import type { FC } from "react";
import { useMemo } from "react";
import type { z } from "zod";

import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";

namespace SafeCurrencyNumberFormat {
  type Currency = z.infer<typeof SafeIso4217CurrencySchema>;
  export interface Props {
    currency: Currency | null;
    value: number;
  }
}

const SafeCurrencyNumberFormat: FC<SafeCurrencyNumberFormat.Props> = ({
  value,
  currency,
}) => {
  const formatCurrency = useNumberFormat(
    !currency || !currency.isSuccess
      ? {}
      : {
          numberFormatPreset: "currency",
          numberFormatOptions: {
            currency: currency.data,
          },
        },
  );

  const formatted = useMemo(
    function format() {
      return formatCurrency.numberFormat(value);
    },
    [formatCurrency, value],
  );

  return (
    <>
      {!currency || !currency.isError ? null : <>{currency.raw} </>}
      {formatted}
    </>
  );
};

export { SafeCurrencyNumberFormat };
