import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Badge: css({
    display: "inline-flex",
    padding: "0 6px",
    fontSize: "0.75rem",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "20px",
    minWidth: "20px",
    borderRadius: "20px",
    fontWeight: 500,
  }),
});

const variantOptions = define<Array<string>>()([
  "superior",
  "plain",
  "primary",
  "positive",
  "negative",
  "attentive",
  "disabled",
]);

type Variant = (typeof variantOptions)[number];

namespace Badge {
  export type Ref = ElementRef<"span">;

  export interface OwnProps {
    variant?: Variant;
  }
  export type Props = Overwrite<ComponentProps<"span">, OwnProps>;
}

const badgeVariantStyles: Record<Variant, SerializedStyles> = {
  superior: css({
    color: theme.colors.white,
    backgroundColor: theme.colors.blue005,
  }),
  plain: css({
    backgroundColor: theme.colors.neutral002,
    color: theme.colors.neutral009,
  }),
  primary: css({
    backgroundColor: theme.colors.blue002,
    color: theme.colors.blue006,
  }),
  positive: css({
    backgroundColor: theme.colors.green002,
    color: theme.colors.green006,
  }),
  attentive: css({
    backgroundColor: theme.colors.yellow002,
    color: theme.colors.yellow006,
  }),
  negative: css({
    backgroundColor: theme.colors.red002,
    color: theme.colors.red006,
  }),
  disabled: css({
    backgroundColor: theme.colors.neutral001,
    color: theme.colors.neutral006,
  }),
};

const BadgeInternal = forwardRef<Badge.Ref, Badge.Props>(function Badge(
  { variant = "superior", ...props },
  ref,
) {
  return (
    <span
      {...props}
      ref={ref}
      css={css([styles.Badge, badgeVariantStyles[variant]])}
    />
  );
});

const Badge = Object.assign(BadgeInternal, {
  styles,
  variantOptions,
});

export { Badge };
