import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { Trans } from "@/app/i18n/Trans";
import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  BeforeIntegrate: css({
    borderRadius: 4,
    border: `1px solid ${theme.colors.blue006}`,
    display: "flex",
    padding: "8px 12px",
    flexDirection: "column",
    gap: "8px",
    alignSelf: "stretch",
    color: theme.colors.neutral009,
    wordBreak: "break-word",
  }),
  icons: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "inherit",
  }),
  action: css({
    display: "flex",
    alignSelf: "stretch",
    justifyContent: "center",
  }),
});

namespace BeforeIntegrate {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {
    /**
     * The props for the contact us button
     */
    contactUsButtonProps?: ComponentProps<typeof Button>;
  }
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const BeforeIntegrateInternal = forwardRef<
  BeforeIntegrate.Ref,
  BeforeIntegrate.Props
>(function BeforeIntegrateInternal({ contactUsButtonProps, ...props }, ref) {
  return (
    <div css={styles.BeforeIntegrate} ref={ref} {...props}>
      <Trans i18nKey="chat.memberProfilePanel.items.membership.beforeIntegrate.desc" />
      <div css={styles.icons}>
        <MotifIcon un-i-motif="logo-nine_one_app_logo" />
        <MotifIcon un-i-motif="logo-shopline_logo" />
        <MotifIcon un-i-motif="logo-cyberbiz_logo" />
        <MotifIcon un-i-motif="logo-shopify_logo" />
      </div>
      <div css={styles.action}>
        <Button
          size="small"
          type="link"
          icon={<MotifIcon un-i-motif="new_window" />}
          iconPosition="end"
          {...contactUsButtonProps}
        >
          <Trans i18nKey="chat.memberProfilePanel.items.membership.beforeIntegrate.button" />
        </Button>
      </div>
    </div>
  );
});

/**
 * Displays the message and icons before integrating with a commerce platform.
 * - [Figma](https://www.figma.com/design/taxRwfDgzLjfhtSzwRtcdW/Chat-Commerce?node-id=7870-27499&node-type=frame&m=dev)
 */
const BeforeIntegrate = Object.assign(BeforeIntegrateInternal, {
  styles,
});

assignDisplayName(BeforeIntegrate, "BeforeIntegrate");

export { BeforeIntegrate };
