const config = {
  text: {
    maxLength: {
      /**
       * @see https://developers.line.biz/en/reference/messaging-api/#text-message
       */
      line: 5000,
      /**
       * @see https://developers.facebook.com/docs/messenger-platform/reference/send-api/
       */
      fb: 2000,
      /**
       * @see https://developers.facebook.com/docs/messenger-platform/instagram/features/send-message#send-api
       */
      ig: 1000,
      /**
       * @see https://docs.google.com/spreadsheets/d/1ivkh2k7rLIDm1x_lTsY60btBaig1zLf0euxyyqPrqqM/edit?gid=97920953#gid=97920953&range=G3
       */
      wccs: 1000,
      /**
       * @see https://developers.facebook.com/docs/whatsapp/cloud-api/messages/text-messages
       */
      whatsapp: 4096,
    },
  },
};

export { config };
