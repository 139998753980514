import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { Overwrite } from "@mui/types";
import { type ElementRef, useMemo } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";
const styles = defineStyles({});

const t = fakeT;

const transLookup = define<
  Array<{
    type: CantataTypes["Order"]["type"];
    status: Extract<
      CantataTypes["Order"]["ecReturnStatus"],
      { expected: true }
    >["value"];
    i18nKey: string;
  }>
>([
  {
    type: "shopify",
    status: "INSPECTION_COMPLETE",
    i18nKey: "resource.order.returnStatus.shopify.inspectionComplete.label",
  },
  {
    type: "shopify",
    status: "IN_PROGRESS",
    i18nKey: "resource.order.returnStatus.shopify.inProgress.label",
  },
  {
    type: "shopify",
    status: "NO_RETURN",
    i18nKey: "resource.order.returnStatus.shopify.noReturn.label",
  },
  {
    type: "shopify",
    status: "RETURNED",
    i18nKey: "resource.order.returnStatus.shopify.returned.label",
  },
  {
    type: "shopify",
    status: "RETURN_FAILED",
    i18nKey: "resource.order.returnStatus.shopify.returnFailed.label",
  },
  {
    type: "shopify",
    status: "RETURN_REQUESTED",
    i18nKey: "resource.order.returnStatus.shopify.returnRequested.label",
  },
  {
    type: "cyberbiz",
    status: "no_need",
    i18nKey: "resource.order.returnStatus.cyberbiz.noNeed.label",
  },
  {
    type: "cyberbiz",
    status: "request_return",
    i18nKey: "resource.order.returnStatus.cyberbiz.requestReturn.label",
  },
  {
    type: "cyberbiz",
    status: "returning",
    i18nKey: "resource.order.returnStatus.cyberbiz.returning.label",
  },
  {
    type: "cyberbiz",
    status: "checking",
    i18nKey: "resource.order.returnStatus.cyberbiz.checking.label",
  },
  {
    type: "cyberbiz",
    status: "returned",
    i18nKey: "resource.order.returnStatus.cyberbiz.returned.label",
  },
  {
    type: "cyberbiz",
    status: "in_hub",
    i18nKey: "resource.order.returnStatus.cyberbiz.inHub.label",
  },
  {
    type: "cyberbiz",
    status: "problem",
    i18nKey: "resource.order.returnStatus.cyberbiz.problem.label",
  },
  {
    type: "cyberbiz",
    status: "processing",
    i18nKey: "resource.order.returnStatus.cyberbiz.processing.label",
  },
  {
    type: "cyberbiz",
    status: "in_origin_cvs",
    i18nKey: "resource.order.returnStatus.cyberbiz.inOriginCvs.label",
  },
  {
    type: "cyberbiz",
    status: "refused",
    i18nKey: "resource.order.returnStatus.cyberbiz.refused.label",
  },
  {
    type: "cyberbiz",
    status: "partial_return",
    i18nKey: "resource.order.returnStatus.cyberbiz.partialReturn.label",
  },
]);

namespace ReturnStatus {
  export type Ref = ElementRef<"span">;
  export interface OwnProps {
    type: CantataTypes["Order"]["type"];
    status: CantataTypes["Order"]["ecReturnStatus"];
  }
  export type Props = Overwrite<ComponentProps<"span">, OwnProps>;
}

type StatusTrans = (typeof transLookup)[number];

const unexpected = {
  i18nKey: t("resource.order.status.unknown.label"),
} satisfies { i18nKey: string };

const supportReturnStatus = new Set(transLookup.map((lookup) => lookup.type));

function isSupportReturnStatus(type: CantataTypes["Order"]["type"]) {
  // TODO: Remove this once it is supported
  if (type === "shopify") return false;

  return supportReturnStatus.has(type);
}

const useReturnStatusTrans = ({ status, type }: ReturnStatus.OwnProps) => {
  return useMemo<StatusTrans | undefined>(
    function lookupTrans() {
      if (!status || !status.expected) return;
      const matched = transLookup.find(
        (lookup) => lookup.type === type && lookup.status === status.value,
      );
      return matched;
    },
    [status, type],
  );
};

const ReturnStatusInternal = forwardRef<ReturnStatus.Ref, ReturnStatus.Props>(
  function ReturnStatusInternal({ type, status, ...props }, ref) {
    const enabledRenderRawEcValue = useFeatureFlag("renderRawEcValue");
    const statusTrans = useReturnStatusTrans({ status, type });

    return (
      <span {...props} ref={ref}>
        {!status ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <>
            <Trans
              i18nKey={!statusTrans ? unexpected.i18nKey : statusTrans.i18nKey}
            />
            {statusTrans && !enabledRenderRawEcValue ? null : (
              <>
                {HIERARCHY_STRING_JOINER}
                {!status.expected ? status.raw : status.value}
              </>
            )}
          </>
        )}
      </span>
    );
  },
);

/**
 * Display the return status of an order.
 */
const ReturnStatus = Object.assign(ReturnStatusInternal, {
  styles,
  unexpected,
  useReturnStatusTrans,
  isSupportReturnStatus,
});

assignDisplayName(ReturnStatus, "ReturnStatus");

export { ReturnStatus };
