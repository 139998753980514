import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { memo } from "@zeffiroso/utils/react/memo";
import { constant } from "lodash-es";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { FormItem } from "@/components/Form";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { MotifIcon } from "@/components/MotifIcon";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { defineStyles } from "@/shared/emotion";
import type { ArrayPredicate } from "@/shared/types/ArrayPredicate";

const styles = defineStyles({
  TeamSelector: css({
    height: "auto",
  }),
  /**
   * Design:
   * [Figma](https://www.figma.com/design/k0XwP83RAV16nVdyFx7crb/Omnichannel-inbox?node-id=11207-50744)
   */
  item: css({
    maxWidth: "360px",
  }),
  label: css({
    whiteSpace: "pre-wrap",
  }),
});

type OptionType = {
  key: number;
  value: number;
  label: ReactNode;
  text: string;
};

namespace TeamSelector {
  export type Props = Omit<
    SelectProps<number, OptionType>,
    "options" | "mode"
  > & {
    /**
     * filter function to filter teams.
     */
    filter?: ArrayPredicate<CantataTypes["Team"]>;
  };
}

/**
 * Team selector
 *
 * allows customization using all props of the Select component, except for `options` and `mode`.
 *
 * Note: A value of `NaN` indicates that no team is selected.
 */
export const TeamSelectorInternal = memo<TeamSelector.Props>(
  function TeamSelectorInternal({ filter = constant(true), ...props }) {
    const { t } = useTranslation();
    const orgId = useActiveOrgIdStore((state) => state.value);
    const query = cantata.team.useList({
      params: {
        orgId,
      },
    });
    const options = useMemo<SelectProps<number, OptionType>["options"]>(() => {
      if (!query.isSuccess) return [];

      return query.data.teams.filter(filter).map((team) => ({
        key: team.id,
        value: team.id,
        text: team.name,
        label: (
          <ItemWithIcon
            css={styles.item}
            gap={8}
            startIcon={
              <Avatar
                style={{ backgroundColor: theme.colors.blue006, fontSize: 12 }}
                icon={<MotifIcon un-i-motif="logo-cresclab_icon" />}
                size={16}
              />
            }
            mainBlockProps={{ css: styles.label }}
          >
            {team.name}
          </ItemWithIcon>
        ),
      }));
    }, [filter, query.data, query.isSuccess]);
    if (query.isLoading) return <Select {...props} loading disabled />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled />
        </FormItem>
      );
    }
    return (
      <Select<number, OptionType>
        showSearch
        css={styles.TeamSelector}
        placeholder={t("assignment.searchTeam")}
        {...props}
        optionFilterProp="text"
        options={options}
        popupMatchSelectWidth={false}
      />
    );
  },
);

assignDisplayName(TeamSelectorInternal, "TeamSelector");

const TeamSelector = Object.assign(TeamSelectorInternal, {
  styles,
});

export { TeamSelector };
