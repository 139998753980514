import { z } from "zod";

import { safeSchema } from "./safeSchema";

const unexpectedSymbol = Symbol("unexpectEnumValue");

type EnumSchema = z.ZodEnum<[string, ...string[]]>;
type EnumOrUnionSchema = EnumSchema | UnionSchema;
type UnionSchema = z.ZodUnion<
  Readonly<[EnumOrUnionSchema, EnumOrUnionSchema, ...EnumOrUnionSchema[]]>
>;

type EnumDef = z.ZodEnumDef<[string, ...string[]]>;
type UnionDef = z.ZodUnionDef<
  [EnumOrUnionSchema, EnumOrUnionSchema, ...EnumOrUnionSchema[]]
>;
type EnumOrUnionDef = EnumDef | UnionDef;

/**
 * Creates a flexible schema that can parse strings against a union of enum
 * schemas.
 *
 * ```ts
 * const colorSchema = safeEnumSchemaInternal(z.enum(["Red", "Green", "Blue"]));
 * const color = colorSchema.parse("Red"); // { value: "Red" }
 * const unexpectedColor = colorSchema.parse("Yellow"); // { value: safeEnumSchema.unexpected, raw: "Yellow" }
 * ```
 */
function safeEnumSchemaInternal<
  TOutput extends string = string,
  TDef extends EnumOrUnionDef = EnumOrUnionDef,
  TInput = TOutput,
>(schema: z.ZodType<TOutput, TDef, TInput>) {
  return safeSchema(
    schema,
    /**
     * Should always be a string.
     */
    z.string(),
  ).transform((result) => {
    const ret:
      | {
          expected: true;
          value: TOutput;
        }
      | {
          expected: false;
          value: typeof unexpectedSymbol;
          raw: string;
        } = result.isSuccess
      ? {
          expected: true,
          value: result.data,
        }
      : {
          expected: false,
          value: unexpectedSymbol,
          raw: result.raw,
        };
    return ret;
  });
}

const safeEnumSchema = Object.assign(safeEnumSchemaInternal, {
  unexpected: unexpectedSymbol,
});

export { safeEnumSchema };
