import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { format } from "date-fns";
import EventEmitter from "eventemitter3";
import PQueue from "p-queue";
import { createWithEqualityFn } from "zustand/traditional";

import {
  flutterNotificationPermissionMapToNotificationPermission,
  type FlutterWebviewAppSdk,
} from "./definitions";

const MAX_LOG_SIZE = 100;

const useFlutterDebugLogsStore = createWithEqualityFn<{
  logs: string[];
}>(
  () => ({
    logs: [],
  }),
  shallow,
);

function log(message: string) {
  useFlutterDebugLogsStore.setState((state) => ({
    logs: [
      `[${format(new Date(), "mm:ss.sss")}] ${message}`,
      ...state.logs,
    ].slice(0, MAX_LOG_SIZE),
  }));
}

const isFlutterWebViewApp = Boolean(
  "flutter_inappwebview" in window && window.flutter_inappwebview,
);

class NonFlutterWebViewAppError extends Error {
  override name = "NonFlutterWebViewAppError";
}

/**
 * The queue for the requests.
 */
const pQueue = new PQueue({ concurrency: 1 });

const requestNotificationPermission = async (): Promise<
  FlutterWebviewAppSdk.RequestMap["requestNotificationPermission"]
> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  log("requestNotificationPermission");
  return window.flutter_inappwebview.callHandler(
    "requestNotificationPermission",
  );
};

const queuedRequestNotificationPermission: typeof requestNotificationPermission =
  async (...args) => {
    log("queuedRequestNotificationPermission");
    const result = await pQueue.add(async () =>
      requestNotificationPermission(...args),
    );
    log(inspectMessage`queuedRequestNotificationPermission, result: ${result}`);
    if (result === undefined)
      throw new Error(inspectMessage`result is undefined, result: ${result}`);
    return result;
  };

const getFcmToken = async (): Promise<string> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  log("getFcmToken");
  return window.flutter_inappwebview.callHandler("getCaacWebViewAppFcmToken");
};

const queuedGetFcmToken: typeof getFcmToken = async (...args) => {
  log("queuedGetFcmToken");
  const result = await pQueue.add(async () => getFcmToken(...args));
  log(inspectMessage`queuedGetFcmToken, result: ${result}`);
  if (result === undefined)
    throw new Error(inspectMessage`result is undefined, result: ${result}`);
  return result;
};

const revokeFcmToken = async (): Promise<void> => {
  if (!isFlutterWebViewApp || !window.flutter_inappwebview) {
    throw new NonFlutterWebViewAppError();
  }
  log("revokeFcmToken");
  return window.flutter_inappwebview.callHandler(
    "revokeCaacWebViewAppFcmToken",
  );
};

const queuedRevokeFcmToken: typeof revokeFcmToken = async (...args) => {
  log("queuedRevokeFcmToken");
  await pQueue.add(async () => revokeFcmToken(...args));
  log("queuedRevokeFcmToken, done");
};

const eventEmitter = (() => {
  if (!isFlutterWebViewApp) return;
  const eventEmitter = new EventEmitter<FlutterWebviewAppSdk.EventMap>();
  const originalCallback = window.flutterPermissionUpdated;
  window.flutterPermissionUpdated = (...args) => {
    // prevent overriding the original callback
    originalCallback?.(...args);
    const [permission] = args;
    eventEmitter.emit(
      "flutterPermissionUpdated",
      flutterNotificationPermissionMapToNotificationPermission(permission),
    );
  };
  return eventEmitter;
})();

/**
 * The SDK for the Flutter WebView App.
 */
const flutterWebViewAppSdk = {
  isFlutterWebViewApp,
  requestNotificationPermission: queuedRequestNotificationPermission,
  getFcmToken: queuedGetFcmToken,
  revokeFcmToken: queuedRevokeFcmToken,
  eventEmitter,
  useFlutterDebugLogsStore,
  log,
};

export { flutterWebViewAppSdk };
