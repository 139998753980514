import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
// eslint-disable-next-line no-restricted-imports -- Wrap the internal function to add the `base64` option for Flutter Webview App.
import { downloadFile as downloadFileInternal } from "@zeffiroso/utils/file/downloadFile";

import { getFeatureFlag } from "@/app/featureFlag";

const downloadFile: typeof downloadFileInternal = async function downloadFile(
  options,
) {
  const base64Download = getFeatureFlag("base64Download");
  return downloadFileInternal({
    ...(base64Download === "never"
      ? {
          base64: false,
        }
      : base64Download === "always"
        ? {
            base64: true,
          }
        : /**
           * Flutter Webview cannot download files with object URLs., use base64 instead.
           */
          !flutterWebViewAppSdk.isFlutterWebViewApp
          ? null
          : {
              base64: true,
            }),
    ...options,
  });
};

export { downloadFile };
