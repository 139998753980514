import { type FC, useMemo } from "react";

import { useFormatDateTime } from ".";

namespace FormatDateTime {
  type Args = Parameters<ReturnType<typeof useFormatDateTime>>;

  export interface Props {
    date: Args[0];
    formatOptions?: Args[1];
  }
}

const FormatDateTime: FC<FormatDateTime.Props> = (props) => {
  const formatDateTime = useFormatDateTime();
  const formatted = useMemo(
    function computeDateTime() {
      return formatDateTime(props.date, props.formatOptions);
    },
    [formatDateTime, props.date, props.formatOptions],
  );
  return <>{formatted}</>;
};

export { FormatDateTime };
