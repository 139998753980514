import { css } from "@emotion/react";
import type { FC } from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Channel } from "@/routes/Chat/ui/ChatPanel/Header/components/Channel";
import { MemberName } from "@/routes/Chat/ui/ChatPanel/Header/components/MemberName";
import { Presence } from "@/routes/Chat/ui/ChatPanel/Header/components/Presence";
import { Actions } from "@/routes/Chat/ui/ChatPanel/Header/UiNarrow/Actions";
import { Assignee } from "@/routes/Chat/ui/ChatPanel/Header/UiNarrow/Assignee";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css(css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
  `),
  main: css(cssFlexInheritAndFill),
  info: css([
    cssFlexInheritAndFill,
    css`
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      & > div {
        overflow: hidden;
        width: 100%;
      }
    `,
  ]),
  infoFirstRow: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  }),
});

const UiNarrow: FC = () => {
  const clearMember = memberIdUtils.useClear();
  return (
    <div css={styles.root}>
      <div>
        <NarrowIconButton
          icon={<MotifIcon un-i-motif="arrow_left" />}
          onClick={clearMember}
        />
      </div>
      <div css={styles.info}>
        <div css={styles.infoFirstRow}>
          <MemberName />
          <Presence />
        </div>
        <div>
          <Channel />
        </div>
      </div>
      <Assignee />
      <Actions />
    </div>
  );
};

export { UiNarrow };
