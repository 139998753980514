import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC, ReactNode } from "react";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Trans } from "@/app/i18n/Trans";
import { Button } from "@/components/Button";
import { RadioSelect } from "@/components/RadioSelect";
import { LoginSlogan } from "@/routes/Auth/components/LoginSlogan";
import {
  Container,
  LeftWrapper,
  RightWrapper,
} from "@/routes/Auth/components/Styled";
import { SiteSelectorEntry } from "@/routes/Auth/SiteSelectorEntry";

const styles = {
  SiteSelectorContent: css({
    display: "flex",
    flexDirection: "column",
  }),
};

function useSetupPageInfo() {
  const [searchParams] = useSearchParams();
  const targetPathAndHash = useMemo(
    () => searchParams.get("targetPathAndHash"),
    [searchParams],
  );
  const pageInfo = useMemo(
    () => ({
      targetPathAndHash,
    }),
    [targetPathAndHash],
  );
  return pageInfo;
}

const Context = (() => {
  function useSetupContext() {
    const pageInfo = useSetupPageInfo();
    const contextValue = useMemo(
      () => ({
        pageInfo,
      }),
      [pageInfo],
    );

    return contextValue;
  }
  const Context = createContext<ReturnType<typeof useSetupContext>>({
    name: "SiteSelectorContext",
  });
  const Provider: FC<{ children: ReactNode }> = function Provider(props) {
    const value = useSetupContext();
    return <Context.Provider value={value} {...props} />;
  };
  return {
    useValue: Context.useContext,
    Provider,
  };
})();

const SiteSelector: FC = () => {
  const [devMode, setDevMode] = useState(
    () =>
      location.host !== SiteSelectorEntry.siteToHostRecord.production &&
      location.host !== SiteSelectorEntry.siteToHostRecord.jp,
  );
  const [host, setHost] = useState(window.location.host);
  const { pageInfo } = Context.useValue();
  const onChange = useHandler<ComponentProps<typeof RadioSelect>["onChange"]>(
    function onChange(event) {
      setHost(event.target.value);
    },
  );
  const showOtherOptions = useMemo(
    () =>
      (host !== SiteSelectorEntry.siteToHostRecord.production &&
        host !== SiteSelectorEntry.siteToHostRecord.jp) ||
      devMode,
    [devMode, host],
  );
  return (
    <Container>
      <LeftWrapper>
        <LoginSlogan />
      </LeftWrapper>
      <RightWrapper>
        <div css={styles.SiteSelectorContent}>
          <div
            style={{
              fontWeight: 500,
              marginBottom: 16,
              color: theme.colors.neutral010,
            }}
            onDoubleClick={() => setDevMode(true)}
          >
            <Trans i18nKey="login.siteSelectorEntry.message" />
          </div>
          <RadioSelect
            value={host}
            onChange={onChange}
            style={{ marginBottom: 28 }}
          >
            <RadioSelect.Option
              value={SiteSelectorEntry.siteToHostRecord.production}
            >
              {SiteSelectorEntry.siteToNameDisplayRecord.production}
            </RadioSelect.Option>
            <RadioSelect.Option value={SiteSelectorEntry.siteToHostRecord.jp}>
              {SiteSelectorEntry.siteToNameDisplayRecord.jp}
            </RadioSelect.Option>
            {!showOtherOptions ? null : (
              <RadioSelect.Option
                value={SiteSelectorEntry.siteToHostRecord.staging}
              >
                {SiteSelectorEntry.siteToNameDisplayRecord.staging}
              </RadioSelect.Option>
            )}
          </RadioSelect>
          <Button
            href={`//${host}${pageInfo.targetPathAndHash ?? ""}`}
            external
            target={undefined}
            icon={undefined}
            type="primary"
          >
            <Trans i18nKey="login.siteSelectorEntry.next" />
          </Button>
        </div>
      </RightWrapper>
    </Container>
  );
};

const Wrapped: FC = function Wrapped() {
  return (
    <Context.Provider>
      <SiteSelector />
    </Context.Provider>
  );
};

export { Wrapped as SiteSelector };
