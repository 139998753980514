import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ReactNode } from "react";
import { useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Divider } from "@/components/Divider";
import { MotifIcon } from "@/components/MotifIcon";
import { FormatDateTime } from "@/resources/datetime/FormatDateTime";
import { FinancialStatus } from "@/resources/order/FinancialStatus";
import { FulfillmentStatus } from "@/resources/order/FulfillmentStatus";
import { OrderStatus } from "@/resources/order/OrderStatus";
import { ReturnStatus } from "@/resources/order/ReturnStatus";
import { Popover } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Popover";
import { SafeCurrencyNumberFormat } from "@/routes/Chat/ui/MemberProfilePanel/Membership/SafeCurrencyNumberFormat";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Order: css({
    display: "flex",
    padding: "12px 12px 6px",
    flexDirection: "column",
    gap: "4px",
    alignSelf: "stretch",
    borderRadius: 8,
    background: theme.colors.neutral001,

    ">*": {
      width: "100%",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
  orderId: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
    "::before": {
      content: '"#"',
    },
  }),
  orderDate: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  toggleButton: css({
    ".anticon": {
      color: theme.colors.neutral009,
    },
  }),
  label: css({
    fontSize: "0.75rem",
    fontWeight: 500,
    color: theme.colors.neutral007,
  }),
  fee: css({
    color: theme.colors.blue006,
    fontSize: "1rem",
    fontWeight: 700,
  }),
});

const orderDetailStyles = defineStyles({
  OrderDetail: css({
    color: theme.colors.neutral009,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    width: 236,
  }),
  header: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    gap: "inherit",
    maxHeight: 332,
    overflowY: "scroll",
  }),
  logo: css({
    fontSize: "24px",
    lineHeight: 1,
  }),
  orderId: css({
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.colors.neutral009,
    "::before": {
      content: '"#"',
    },
  }),
  footer: css({
    display: "flex",
    gap: 2,
    flexDirection: "column",
  }),
  summary: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  fee: css({
    color: theme.colors.blue006,
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "right",
  }),
  close: css({
    position: "absolute",
    top: -4,
    right: -4,
  }),
});

const supportMap = define<
  Record<
    CantataTypes["Order"]["type"],
    Record<"supportItemPrize" | "supportItemCount", boolean>
  >
>({
  "91app": {
    supportItemCount: false,
    supportItemPrize: false,
  },
  shopline: {
    supportItemCount: false,
    supportItemPrize: true,
  },
  cyberbiz: {
    supportItemCount: false,
    supportItemPrize: true,
  },
  shopify: {
    supportItemCount: true,
    supportItemPrize: true,
  },
  flaps: {
    supportItemCount: true,
    supportItemPrize: true,
  },
});

function isSupportOrderItemPrize(type: CantataTypes["Order"]["type"]) {
  return supportMap[type].supportItemPrize;
}

function isSupportOrderItemCount(type: CantataTypes["Order"]["type"]) {
  return supportMap[type].supportItemCount;
}

namespace Order {
  export type Ref = ElementRef<"li">;
  export interface OwnProps {
    order: CantataTypes["Order"];
    detailButtonProps?: ComponentProps<typeof NarrowIconButton>;
  }
  export type Props = Overwrite<ComponentProps<"li">, OwnProps>;
}

const orderTypeLogoMap: Record<CantataTypes["Order"]["type"], ReactNode> = {
  "91app": <MotifIcon un-i-motif="logo-nine_one_app_logo" />,
  shopline: <MotifIcon un-i-motif="logo-shopline_logo" />,
  cyberbiz: <MotifIcon un-i-motif="logo-cyberbiz_logo" />,
  shopify: <MotifIcon un-i-motif="logo-shopify_logo" />,
  flaps: <MotifIcon un-i-motif="logo-flaps_logo" />,
};

const OrderInternal = forwardRef<Order.Ref, Order.Props>(function OrderInternal(
  { order, detailButtonProps, ...props },
  ref,
) {
  const [open, toggle] = useSwitch(false);

  const isSupport = useMemo(
    function checkSupport() {
      return {
        orderStatus: OrderStatus.isSupportOrderStatus(order.type),
        financialStatus: FinancialStatus.isSupportFinicalStatus(order.type),
        fulfillmentStatus: FulfillmentStatus.isSupportFulfillmentStatus(
          order.type,
        ),
        returnStatus: ReturnStatus.isSupportReturnStatus(order.type),
        itemPrize: isSupportOrderItemPrize(order.type),
        itemCount: isSupportOrderItemCount(order.type),
      };
    },
    [order.type],
  );

  const handleDetailButtonClick = useHandler<
    ComponentProps<typeof NarrowIconButton>["onClick"]
  >(function handleDetailButtonClick(...args) {
    toggle.on();
    detailButtonProps?.onClick?.(...args);
  });

  return (
    <li css={styles.Order} ref={ref} {...props}>
      <div>
        <div css={styles.orderId}>{order.orderId}</div>
        {!isSupport.orderStatus ? null : (
          <OrderStatus.Badge type={order.type} status={order.ecStatus} />
        )}
      </div>
      <div>
        <div css={styles.orderDate}>
          {!order.orderDate.isSuccess ? (
            order.orderDate.raw
          ) : (
            <FormatDateTime date={order.orderDate.data} />
          )}
        </div>
        <Popover
          open={open}
          placement="topRight"
          closeButtonProps={{
            onClick: toggle.off,
          }}
          content={
            <div css={orderDetailStyles.OrderDetail}>
              <div css={orderDetailStyles.header}>
                <div css={orderDetailStyles.logo}>
                  {orderTypeLogoMap[order.type]}
                </div>
                <span css={orderDetailStyles.orderId}>{order.orderId}</span>
              </div>
              <div css={orderDetailStyles.content}>
                <div>
                  <div css={styles.label}>
                    <Trans i18nKey="chat.memberProfilePanel.membership.order.createdAt.label" />
                  </div>
                  <div>
                    {!order.orderDate.isSuccess ? (
                      order.orderDate.raw
                    ) : (
                      <FormatDateTime date={order.orderDate.data} />
                    )}
                  </div>
                </div>
                {!isSupport.orderStatus ? null : (
                  <div>
                    <div css={styles.label}>
                      <Trans i18nKey="chat.memberProfilePanel.membership.order.status.label" />
                    </div>
                    <div>
                      <OrderStatus type={order.type} status={order.ecStatus} />
                    </div>
                  </div>
                )}
                {!isSupport.financialStatus ? null : (
                  <div>
                    <div css={styles.label}>
                      <Trans i18nKey="chat.memberProfilePanel.membership.order.paymentStatus.label" />
                    </div>
                    <div>
                      <FinancialStatus
                        type={order.type}
                        status={order.ecFinancialStatus}
                      />
                    </div>
                  </div>
                )}
                {!isSupport.fulfillmentStatus ? null : (
                  <div>
                    <div css={styles.label}>
                      <Trans i18nKey="chat.memberProfilePanel.membership.order.deliveryStatus.label" />
                    </div>
                    <div>
                      <FulfillmentStatus
                        type={order.type}
                        status={order.ecFulfillmentStatus}
                      />
                    </div>
                  </div>
                )}
                {!isSupport.returnStatus ? null : (
                  <div>
                    <div css={styles.label}>
                      <Trans i18nKey="chat.memberProfilePanel.membership.order.returnStatus.label" />
                    </div>
                    <div>
                      <ReturnStatus
                        type={order.type}
                        status={order.ecReturnStatus}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <div css={styles.label}>
                    <Trans i18nKey="chat.memberProfilePanel.membership.order.totalAmount.label" />
                  </div>
                  <div css={styles.fee}>
                    <SafeCurrencyNumberFormat
                      value={order.orderAmount}
                      currency={order.orderCurrency}
                    />
                  </div>
                </div>
                <Divider />
                <div css={orderDetailStyles.footer}>
                  <div css={styles.label}>
                    <Trans i18nKey="chat.memberProfilePanel.membership.order.orderDetails.label" />
                  </div>
                  <div css={orderDetailStyles.summary}>
                    {order.orderItems.map((item, index) => (
                      <div key={index}>
                        <div>{item.productName}</div>
                        {!isSupport.itemPrize && !isSupport.itemCount ? null : (
                          <div css={orderDetailStyles.fee}>
                            {!isSupport.itemCount ? null : <>{item.count} x </>}
                            {!isSupport.itemPrize ? null : (
                              <SafeCurrencyNumberFormat
                                value={item.price}
                                currency={order.orderCurrency}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <NarrowIconButton
            iconSize="small"
            css={styles.toggleButton}
            icon={<MotifIcon un-i-motif="file_view" />}
            {...detailButtonProps}
            onClick={handleDetailButtonClick}
          />
        </Popover>
      </div>
    </li>
  );
});

/**
 * item of order list
 */
const Order = Object.assign(OrderInternal, {
  styles,
});

assignDisplayName(Order, "Order");

export { Order };
