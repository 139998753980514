import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { ClassNames, css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import type { ComponentRef } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Popover } from "@/components/Popover";
import { Tooltip } from "@/components/Tooltip";
import { Home } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/Home";
import { OnDutyStatus } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/OnDutyStatus";
import {
  closeAccountPanel,
  openAccountPanel,
  useAccountPanelStore,
} from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/useAccountPaneStore";
import { OrgAvatar } from "@/resources/organization/OrgAvatar";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  switch: css({
    fontSize: "32px",
    height: "1em",
    width: "1em",
    maxHeight: "1em",
    maxWidth: "1em",
    minHeight: "1em",
    minWidth: "1em",
  }),
  content: css({
    minWidth: "min(300px, 100%)",
    width: 300,
    maxWidth: "min(300px, 100%)",
    maxHeight: "calc(100dvh - 24px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  }),
});

type AccountPanelProps = {
  popoverProps?: Omit<
    Popover.Props,
    "content" | "children" | "trigger" | "open" | "onOpenChange"
  >;
};

const trigger: Popover.Props["trigger"] = ["click"];

/**
 * Figma: https://www.figma.com/design/k0XwP83RAV16nVdyFx7crb/Omnichannel-inbox?m=dev&node-id=4544%3A34340
 */
const AccountPanel = forwardRef<
  ComponentRef<typeof NarrowIconButton>,
  AccountPanelProps
>(function AccountPanel({ popoverProps }, ref) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const page = useAccountPanelStore((state) => state.page);

  const opened = page !== "closed";

  const onOpenChange = useHandler<Popover.Props["onOpenChange"]>((open) => {
    if (open) {
      openAccountPanel();
      toggleTooltip.off();
      return;
    }
    closeAccountPanel();
  });

  const [popoverOpened, togglePopover] = useSwitch(false, opened, onOpenChange);
  const [tooltipOpened, toggleTooltip] = useSwitch(false);

  return (
    <Tooltip
      title={t("layout.nav.my.tooltip")}
      placement="right"
      onOpenChange={toggleTooltip}
      open={!popoverOpened && tooltipOpened}
    >
      <span>
        <ClassNames>
          {({ css }) => (
            <Popover
              rootClassName={css({
                "& .ant-popover-inner": {
                  borderRadius: 8,
                  padding: 0,
                },
              })}
              {...popoverProps}
              content={
                <div css={styles.content}>
                  {page === "onDutyStatus" ? <OnDutyStatus /> : <Home />}
                </div>
              }
              trigger={trigger}
              open={popoverOpened}
              onOpenChange={togglePopover}
            >
              <NarrowIconButton
                css={styles.switch}
                icon={<OrgAvatar orgId={orgId} />}
                ref={ref}
              />
            </Popover>
          )}
        </ClassNames>
      </span>
    </Tooltip>
  );
});

export { AccountPanel };
