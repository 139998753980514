import type { FC, ReactNode } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberTypeToChannelTypeMap } from "@/resources/member/typeToChannelTypeMap";
import { UnifyKeyLabel } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/CdpProfile/UnifyKeyLabel";
import { BorderLessCopyInput } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/Input";

const translationMap = {
  line: <Trans i18nKey="chat.profile.line.externalMemberId.label" />,
  fb: <Trans i18nKey="chat.profile.fb.externalMemberId.label" />,
  ig: <Trans i18nKey="chat.profile.ig.externalMemberId.label" />,
  wccs: <Trans i18nKey="chat.profile.wccs.externalMemberId.label" />,
  whatsapp: <Trans i18nKey="chat.profile.whatsapp.externalMemberId.label" />,
} satisfies Record<CantataTypes["Channel"]["type"], ReactNode>;

const ExternalMemberIdLabel: FC = () => {
  const profile = memberQueriesContext.useMember();
  const channelType = memberTypeToChannelTypeMap[profile.type];

  return (
    <UnifyKeyLabel
      unifyKey={channelType === "line" ? "line_id" : "external_member_id"}
    >
      {translationMap[channelType]}
    </UnifyKeyLabel>
  );
};

const ExternalMemberIdField: FC = () => {
  const profile = memberQueriesContext.useMember();
  return (
    <BorderLessCopyInput
      size="small"
      variant="borderless"
      value={profile.externalMemberId}
    />
  );
};

export { ExternalMemberIdField, ExternalMemberIdLabel };
