import { define } from "@chatbotgang/etude/util/define";
import { z } from "zod";

namespace safeSchema {
  export type Output<TOutput = any, TPreOutput = unknown> =
    | {
        isSuccess: false;
        isError: true;
        raw: TPreOutput;
      }
    | {
        isSuccess: true;
        isError: false;
        data: TOutput;
      };
}

/**
 * Transforms a Zod schema to always return an object containing a result, ensuring
 * the original schema's output is preserved while preventing any parsing errors.
 */
function safeSchema<
  TOutput = any,
  TDef extends z.ZodTypeDef = z.ZodTypeDef,
  TInput = TOutput,
  TPreOutput = unknown,
  TPreDef extends z.ZodTypeDef = z.ZodTypeDef,
  TPreInput = TInput,
>(
  schema: z.ZodType<TOutput, TDef, TInput>,
  /**
   * If provided, the preSchema will be used to parse the input before applying
   * the main schema.
   *
   * This can be useful for narrowing the type of the raw input.
   */
  preSchema?: z.ZodType<TPreOutput, TPreDef, TPreInput>,
) {
  const newSchema = schema
    .transform((data) =>
      define<safeSchema.Output<TOutput, TPreOutput>>({
        isSuccess: true,
        isError: false,
        data,
      }),
    )
    .catch((ctx) => {
      return {
        isSuccess: false,
        isError: true,
        raw: ctx.input as unknown as TPreOutput,
      };
    });
  if (preSchema) {
    return z.pipeline(preSchema, newSchema);
  }
  return newSchema;
}

export { safeSchema };
