import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Presence: css({
    width: 8,
    minWidth: 8,
    height: 8,
    borderRadius: 4,
  }),
  online: css({
    backgroundColor: theme.colors.green006,
  }),
  offline: css({
    border: `2px solid ${theme.colors.neutral006}`,
    backgroundColor: theme.colors.white,
  }),
});

namespace Presence {
  export type Ref = ElementRef<"div">;
  export interface OwnProps {}
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const PresenceInternal = forwardRef<Presence.Ref, Presence.Props>(
  function PresenceInternal(props, ref) {
    const member = memberQueriesContext.useMember();

    if (member.isOnline === null) return null;

    return (
      <div
        css={css([
          styles.Presence,
          member.isOnline ? styles.online : styles.offline,
        ])}
        ref={ref}
        {...props}
      />
    );
  },
);

/**
 * displays a presence indicator for a member
 */
const Presence = Object.assign(PresenceInternal, {
  styles,
});

assignDisplayName(Presence, "Presence");

export { Presence };
