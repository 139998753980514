import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";

import { TeamSelector as OriginalTeamSelector } from "@/resources/team/TeamSelector";

export const TeamSelector = memo(function TeamSelector({
  value,
  onChange,
  ...props
}: {
  value?: number;
  onChange?: (value: number) => void;
} & Omit<OriginalTeamSelector.Props, "value" | "onChange">) {
  return (
    <OriginalTeamSelector
      value={
        value === undefined ? value : Number.isNaN(value) ? undefined : value
      }
      onChange={useHandler((value) => {
        if (!onChange) return;

        if (value === undefined) {
          onChange(Number.NaN);
          return;
        }
        onChange(Number(value));
      })}
      {...props}
    />
  );
});
