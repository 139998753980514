import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { Overwrite } from "@mui/types";
import { type ElementRef, useMemo } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { defineStyles } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";

const styles = defineStyles({});

const t = fakeT;

const transLookup = define<
  Array<{
    type: CantataTypes["Order"]["type"];
    status: Extract<
      CantataTypes["Order"]["ecFulfillmentStatus"],
      { expected: true }
    >["value"];
    i18nKey: string;
  }>
>([
  {
    type: "91app",
    status: "NotYetAllocatedCode",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.notYetAllocatedCode.label",
    ),
  },
  {
    type: "91app",
    status: "AllocatedCode",
    i18nKey: t("resource.order.fulfillmentStatus.91app.allocatedCode.label"),
  },
  {
    type: "91app",
    status: "Finish",
    i18nKey: t("resource.order.fulfillmentStatus.91app.finish.label"),
  },
  {
    type: "91app",
    status: "ShippingProcessing",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.shippingProcessing.label",
    ),
  },
  {
    type: "91app",
    status: "VerifySuccess",
    i18nKey: t("resource.order.fulfillmentStatus.91app.verifySuccess.label"),
  },
  {
    type: "91app",
    status: "ShippingArrived",
    i18nKey: t("resource.order.fulfillmentStatus.91app.shippingArrived.label"),
  },
  {
    type: "91app",
    status: "ShippingFail",
    i18nKey: t("resource.order.fulfillmentStatus.91app.shippingFail.label"),
  },
  {
    type: "91app",
    status: "VerifyFailLost",
    i18nKey: t("resource.order.fulfillmentStatus.91app.verifyFailLost.label"),
  },
  {
    type: "91app",
    status: "VerifyFailAbnormalPackage",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.verifyFailAbnormalPackage.label",
    ),
  },
  {
    type: "91app",
    status: "VerifyFailRenovation",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.verifyFailRenovation.label",
    ),
  },
  {
    type: "91app",
    status: "VerifyFailErrorCode",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.verifyFailErrorCode.label",
    ),
  },
  {
    type: "91app",
    status: "VerifyFailInvalidCode",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.verifyFailInvalidCode.label",
    ),
  },
  {
    type: "91app",
    status: "PickedUp",
    i18nKey: t("resource.order.fulfillmentStatus.91app.pickedUp.label"),
  },
  {
    type: "91app",
    status: "ShippingAbnormal",
    i18nKey: t("resource.order.fulfillmentStatus.91app.shippingAbnormal.label"),
  },
  {
    type: "91app",
    status: "VerifyFail",
    i18nKey: t("resource.order.fulfillmentStatus.91app.verifyFail.label"),
  },
  {
    type: "91app",
    status: "CashOnDeliveryTransferring",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryTransferring.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryNotAtHome",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryNotAtHome.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryDistributing",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryDistributing.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryFailDamage",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryFailDamage.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryFailLost",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryFailLost.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryFail",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryFail.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryAddressError",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryAddressError.label",
    ),
  },
  {
    type: "91app",
    status: "CashOnDeliveryForwarding",
    i18nKey: t(
      "resource.order.fulfillmentStatus.91app.cashOnDeliveryForwarding.label",
    ),
  },
  {
    type: "shopify",
    status: "ATTEMPTED_DELIVERY",
    i18nKey: t(
      "resource.order.fulfillmentStatus.shopify.attemptedDelivery.label",
    ),
  },
  {
    type: "shopify",
    status: "CANCELED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.canceled.label"),
  },
  {
    type: "shopify",
    status: "CONFIRMED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.confirmed.label"),
  },
  {
    type: "shopify",
    status: "DELIVERED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.delivered.label"),
  },
  {
    type: "shopify",
    status: "FAILURE",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.failure.label"),
  },
  {
    type: "shopify",
    status: "FULFILLED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.fulfilled.label"),
  },
  {
    type: "shopify",
    status: "IN_TRANSIT",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.inTransit.label"),
  },
  {
    type: "shopify",
    status: "LABEL_PRINTED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.labelPrinted.label"),
  },
  {
    type: "shopify",
    status: "LABEL_PURCHASED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.labelPurchased.label"),
  },
  {
    type: "shopify",
    status: "LABEL_VOIDED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.labelVoided.label"),
  },
  {
    type: "shopify",
    status: "MARKED_AS_FULFILLED",
    i18nKey: t(
      "resource.order.fulfillmentStatus.shopify.markedAsFulfilled.label",
    ),
  },
  {
    type: "shopify",
    status: "NOT_DELIVERED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.notDelivered.label"),
  },
  {
    type: "shopify",
    status: "OUT_FOR_DELIVERY",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.outForDelivery.label"),
  },
  {
    type: "shopify",
    status: "PICKED_UP",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.pickedUp.label"),
  },
  {
    type: "shopify",
    status: "READY_FOR_PICKUP",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.readyForPickup.label"),
  },
  {
    type: "shopify",
    status: "SUBMITTED",
    i18nKey: t("resource.order.fulfillmentStatus.shopify.submitted.label"),
  },
  {
    type: "shopline",
    status: "pending",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.pending.label"),
  },
  {
    type: "shopline",
    status: "shipping",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.shipping.label"),
  },
  {
    type: "shopline",
    status: "shipped",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.shipped.label"),
  },
  {
    type: "shopline",
    status: "arrived",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.arrived.label"),
  },
  {
    type: "shopline",
    status: "collected",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.collected.label"),
  },
  {
    type: "shopline",
    status: "returned",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.returned.label"),
  },
  {
    type: "shopline",
    status: "returning",
    i18nKey: t("resource.order.fulfillmentStatus.shopline.returning.label"),
  },
  {
    type: "cyberbiz",
    status: "unshipped",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.unshipped.label"),
  },
  {
    type: "cyberbiz",
    status: "preparing",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.preparing.label"),
  },
  {
    type: "cyberbiz",
    status: "cancel",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.cancel.label"),
  },
  {
    type: "cyberbiz",
    status: "fulfilled",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.fulfilled.label"),
  },
  {
    type: "cyberbiz",
    status: "partial",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.partial.label"),
  },
  {
    type: "cyberbiz",
    status: "arrived",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.arrived.label"),
  },
  {
    type: "cyberbiz",
    status: "received",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.received.label"),
  },
  {
    type: "cyberbiz",
    status: "returned",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.returned.label"),
  },
  {
    type: "cyberbiz",
    status: "expired",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.expired.label"),
  },
  {
    type: "cyberbiz",
    status: "problem",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.problem.label"),
  },
  {
    type: "cyberbiz",
    status: "no_need",
    i18nKey: t("resource.order.fulfillmentStatus.cyberbiz.noNeed.label"),
  },
]);

namespace FulfillmentStatus {
  export type Ref = ElementRef<"span">;
  export interface OwnProps {
    type: CantataTypes["Order"]["type"];
    status: CantataTypes["Order"]["ecFulfillmentStatus"];
  }
  export type Props = Overwrite<ComponentProps<"span">, OwnProps>;
}

type StatusTrans = (typeof transLookup)[number];

const unexpected = {
  i18nKey: t("resource.order.status.unknown.label"),
} satisfies { i18nKey: string };

const supportFulfillmentStatus = new Set(
  transLookup.map((lookup) => lookup.type),
);

function isSupportFulfillmentStatus(type: CantataTypes["Order"]["type"]) {
  return supportFulfillmentStatus.has(type);
}

const useFulfillmentStatusTrans = ({
  status,
  type,
}: FulfillmentStatus.OwnProps) => {
  return useMemo<StatusTrans | undefined>(
    function lookupTrans() {
      if (!status || !status.expected) return;
      const matched = transLookup.find(
        (lookup) => lookup.type === type && lookup.status === status.value,
      );
      return matched;
    },
    [status, type],
  );
};

const FulfillmentStatusInternal = forwardRef<
  FulfillmentStatus.Ref,
  FulfillmentStatus.Props
>(function FulfillmentStatusInternal({ type, status, ...props }, ref) {
  const enabledRenderRawEcValue = useFeatureFlag("renderRawEcValue");
  const statusTrans = useFulfillmentStatusTrans({ status, type });

  return (
    <span {...props} ref={ref}>
      {!status ? (
        EMPTY_STRING_PLACEHOLDER
      ) : (
        <>
          <Trans
            i18nKey={!statusTrans ? unexpected.i18nKey : statusTrans.i18nKey}
          />
          {statusTrans && !enabledRenderRawEcValue ? null : (
            <>
              {HIERARCHY_STRING_JOINER}
              {!status.expected ? status.raw : status.value}
            </>
          )}
        </>
      )}
    </span>
  );
});

/**
 * Display the fulfillment status of an order.
 */
const FulfillmentStatus = Object.assign(FulfillmentStatusInternal, {
  styles,
  useFulfillmentStatusTrans,
  unexpected,
  isSupportFulfillmentStatus,
});

assignDisplayName(FulfillmentStatus, "FulfillmentStatus");

export { FulfillmentStatus };
