import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeString } from "@chatbotgang/etude/string/safeString";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isThisYear, isToday } from "date-fns";
import type { FC } from "react";
import { useCallback, useId, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Badge } from "@/components/Badge";
import { Blockquote } from "@/components/Blockquote";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Dropdown } from "@/components/Dropdown";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ExpandableLineClampBox } from "@/components/ExpandableLineClampBox";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { InputTextArea, type TextAreaProps } from "@/components/Input";
import { HotKeys } from "@/components/Kbd/Hotkeys";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { useMessage } from "@/internal/message";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import {
  useFormatDateTime,
  useGetIntlDateTimeFormatter,
} from "@/resources/datetime";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { draftNoteUtils } from "@/resources/note/draftNoteUtils";
import { noteValidator } from "@/resources/note/noteValidator";
import { useUnifiedNotesQueries } from "@/resources/note/useUnifiedNotesQueries";
import { useUserPermission } from "@/shared/application/user";
import {
  cssFlexInheritAndFill,
  cssInheritGap,
  cssOneLine,
} from "@/shared/emotion";
const cssToggleButton = css({
  padding: 0,
  height: "28px",
});

const cssFgNote = css({
  fontSize: "0.875rem",
  color: theme.colors.neutral007,
});

const cssHotkeyTooltip = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  lineHeight: "normal",
  gap: 8,
});

const submitNoteHotkey = "Mod+Enter";

const NOTE_AUTO_SIZE: TextAreaProps["autoSize"] = {
  minRows: 4,
  maxRows: 20,
};

const EasyForm = createEasyForm<{
  content: string;
}>();

const editIcon = <MotifIcon un-i-motif="edit" />;
const deleteIcon = <MotifIcon un-i-motif="bin" />;

const AddNote: FC = () => {
  const { t } = useTranslation();
  const reactId = useId();
  const textAreaId = `chat-create_note-textarea-${reactId}`;
  const orgQueriesData = orgQueriesContext.useData();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const userId = orgQueriesData.me.id;
  const member = memberQueriesContext.useMember();
  const memberId = member.id;
  const [content, setContent] = useState(
    () =>
      draftNoteUtils.getDraftNoteContent({
        orgId,
        memberId,
      }) || "",
  );
  const trimmedSafeContent = safeString(content, {
    trim: true,
  });
  const contentInvalid =
    trimmedSafeContent.length === 0 ||
    content.length > noteValidator.content.maxLength;
  const clearDraftNote = useHandler(function clearDraftNote() {
    setContent("");
    draftNoteUtils.clear({
      orgId,
      memberId,
    });
  });
  const mutation = cantata.memberNote.useCreate(
    {
      params: {
        orgId,
        memberId,
      },
    },
    {
      onSuccess() {
        clearDraftNote();
      },
    },
  );

  const handleChange = useHandler<
    ComponentProps<typeof InputTextArea>["onChange"]
  >(function handleChange(e) {
    const content = e.target.value;
    if (!content) {
      clearDraftNote();
      return;
    }
    setContent(content);
    draftNoteUtils.setContent({
      content,
      selectDraftNoteParameters: {
        orgId,
        memberId,
      },
    });
  });

  const handleCreate = useHandler(function handleCreate() {
    if (mutation.isLoading || contentInvalid) return;

    mutation.mutate({
      content,
    });
  });

  return (
    <DisabledContextProvider disabled={mutation.isLoading}>
      <div>
        <InputTextArea
          id={textAreaId}
          crescendolab-selector="chat-create_note-textarea"
          autoSize={NOTE_AUTO_SIZE}
          placeholder={t("feat.member.note.placeholder")}
          maxLength={noteValidator.content.maxLength}
          value={content}
          onChange={handleChange}
          status={
            content.length > noteValidator.content.maxLength
              ? "error"
              : undefined
          }
        />
        <div
          css={css({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "8px",
          })}
        >
          <span css={cssFgNote}>
            {content.length}/{noteValidator.content.maxLength}
          </span>
          <Tooltip
            title={
              <div css={cssHotkeyTooltip}>
                <Trans i18nKey="feat.member.note.add" />
                <HotKeys hotkey={submitNoteHotkey} />
              </div>
            }
          >
            <Button
              type="link"
              size="small"
              onClick={handleCreate}
              disabled={mutation.isLoading || contentInvalid}
              crescendolab-selector="chat-create_note-submit"
              hotkeyScope={textAreaId}
              hotkey={submitNoteHotkey}
              onEnabledHotkeyFire={(e) => {
                ga4Event("hotkey", {
                  orgId,
                  orgUserId: userId,
                  feature: "addNote",
                  hotkey: submitNoteHotkey,
                  path: e.detail.path.join(" "),
                });
              }}
            >
              <Trans i18nKey="feat.member.note.add" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </DisabledContextProvider>
  );
};

const DeleteNoteModal: FC<
  {
    note: CantataTypes["MemberNote"];
  } & Pick<ModalProps, "open" | "onCancel">
> = (props) => {
  const { t } = useTranslation();
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const deleteMutation = cantata.memberNote.useDeleteById(
    {
      params: {
        orgId,
        source: props.note.memberSource,
        memberId: props.note.memberId,
        noteId: props.note.id,
      },
    },
    {
      onSuccess() {
        message.success(t("feat.member.note.delete.message"));
      },
    },
  );
  const handleDelete = useHandler(function handleDelete() {
    if (deleteMutation.isLoading) return;
    deleteMutation.mutate(undefined);
  });

  return (
    <DisabledContextProvider disabled={deleteMutation.isLoading}>
      <Modal
        title={<Trans i18nKey="feat.member.note.deleteModal.title" />}
        open={props.open}
        onCancel={props.onCancel}
        cancelText={<Trans i18nKey="common.cancel" />}
        okText={<Trans i18nKey="common.confirm" />}
        okButtonProps={{
          type: "primary",
          onClick: handleDelete,
          loading: deleteMutation.isLoading,
        }}
      >
        <p>
          <Trans i18nKey="feat.member.note.deleteModal.content" />
        </p>
        <Blockquote>
          <p>{props.note.content}</p>
        </Blockquote>
      </Modal>
    </DisabledContextProvider>
  );
};

const Note = (() => {
  const cssNote = {
    self: css([
      cssFlexInheritAndFill,
      {
        display: "flex",
        alignItems: "normal",
        padding: "12px 12px 6px",
        flexDirection: "column",
        gap: "4px",
        borderRadius: "8px",
        background: theme.colors.neutral001,
      },
    ]),
    info: css([
      cssFlexInheritAndFill,
      {
        flexDirection: "row",
        width: "100%",
        gap: "4px",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "0.75rem",
        color: theme.colors.neutral007,
      },
    ]),
    channelInfo: css([
      {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
      },
      cssInheritGap,
    ]),
    noteInfo: css([
      {
        display: "flex",
        flex: "0 0 auto",
        flexDirection: "row",
        alignItems: "center",
      },
      cssInheritGap,
    ]),
    formFooter: css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "12px",
    }),
    formButton: css({
      padding: "0 6px",
      height: "28px",
    }),
  } satisfies Record<string, SerializedStyles>;

  const NoteContent: FC<{ content: string }> = ({ content }) => {
    const expandableLineClampBoxUtilities =
      ExpandableLineClampBox.useExpandableLineClampBoxUtilities();

    return (
      <div>
        <ExpandableLineClampBox
          lineClamp={3}
          expandableLineClampBoxUtilities={expandableLineClampBoxUtilities}
        >
          {content}
        </ExpandableLineClampBox>
        {expandableLineClampBoxUtilities.expanded ? null : (
          <Button
            type="link"
            size="small"
            onClick={expandableLineClampBoxUtilities.expand}
            css={cssToggleButton}
          >
            <Trans i18nKey="feat.member.note.expand" />
          </Button>
        )}
      </div>
    );
  };

  const Time = ({ time }: { time: Date }) => {
    const { t } = useTranslation();
    const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
    const formatDatetime = useFormatDateTime();
    const formatTime = useCallback(
      function formatTime(date: Date): string {
        if (isToday(date)) return t("glossary.today");
        const formatter = getIntlDateTimeFormatter({
          month: "short",
          day: "numeric",
          ...(isThisYear(date) ? {} : { year: "numeric" }),
        });
        return formatter.format(date);
      },
      [getIntlDateTimeFormatter, t],
    );

    return (
      <Tooltip title={<span>{formatDatetime(time)}</span>}>
        <span>{formatTime(time)}</span>
      </Tooltip>
    );
  };

  const EditNote: FC<{
    note: CantataTypes["MemberNote"];
    onClose: () => void;
  }> = ({ note, onClose }) => {
    const { t } = useTranslation();
    const [_, form] = EasyForm.useForm();
    const reactId = useId();
    const textAreaId = `chat-edit_note-textarea-${reactId}`;
    const orgQueriesData = orgQueriesContext.useData();
    const orgId = useActiveOrgIdStore((state) => state.value);
    const userId = orgQueriesData.me.id;
    const [content, setContent] = useState(note.content);
    const message = useMessage();
    const editMutation = cantata.memberNote.useUpdate(
      {
        params: {
          orgId,
          source: note.memberSource,
          memberId: note.memberId,
          noteId: note.id,
        },
      },
      {
        onSuccess() {
          message.success(t("common.updatedSuccessfully"));
          onClose();
        },
      },
    );
    const handleChange = useHandler<
      ComponentProps<typeof InputTextArea>["onChange"]
    >(function handleChange(e) {
      setContent(e.target.value);
    });
    return (
      <EasyForm
        form={form}
        initialValues={{ content: note.content }}
        disabled={editMutation.isLoading}
        onFinish={editMutation.mutate}
      >
        <EasyForm.Item
          // auto height form item
          css={css({ height: "auto", marginBottom: "4px" })}
          name="content"
          rules={[
            {
              required: true,
              message: <Trans i18nKey="validation.pleaseInputNote" />,
            },
            {
              max: noteValidator.content.maxLength,
              message: (
                <Trans
                  values={{ count: noteValidator.content.maxLength }}
                  i18nKey="validation.maxCharLength"
                />
              ),
            },
          ]}
        >
          <InputTextArea
            id={textAreaId}
            autoFocus
            autoSize={NOTE_AUTO_SIZE}
            placeholder={t("feat.member.note.placeholder")}
            maxLength={noteValidator.content.maxLength}
            onChange={handleChange}
            status={
              content.length > noteValidator.content.maxLength
                ? "error"
                : undefined
            }
          />
        </EasyForm.Item>
        <div css={cssNote.formFooter}>
          <span css={cssFgNote}>
            {content.length}/{noteValidator.content.maxLength}
          </span>
          <div
            css={css([
              {
                display: "flex",
                flexDirection: "row",
              },
              cssInheritGap,
            ])}
          >
            <Button
              type="link"
              size="small"
              onClick={onClose}
              css={cssNote.formButton}
            >
              <Trans i18nKey="common.cancel" />
            </Button>
            <Tooltip
              title={
                <div css={cssHotkeyTooltip}>
                  <Trans i18nKey="common.save" />
                  <HotKeys hotkey={submitNoteHotkey} />
                </div>
              }
            >
              <Button
                type="link"
                htmlType="submit"
                size="small"
                css={cssNote.formButton}
                hotkeyScope={textAreaId}
                hotkey={submitNoteHotkey}
                onEnabledHotkeyFire={(e) =>
                  ga4Event("hotkey", {
                    orgId,
                    orgUserId: userId,
                    feature: "editNote",
                    hotkey: submitNoteHotkey,
                    path: e.detail.path.join(" "),
                  })
                }
              >
                <Trans i18nKey="common.save" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </EasyForm>
    );
  };

  return fc<{ note: CantataTypes["MemberNote"] }>(function Note({ note }) {
    const [openEdit, toggleOpenEdit] = useSwitch(false);
    const [openDelete, toggleOpenDelete] = useSwitch(false);
    const { hasPermission } = useUserPermission();
    return (
      <div css={cssNote.self}>
        {openEdit ? (
          <EditNote note={note} onClose={toggleOpenEdit.off} />
        ) : (
          <NoteContent content={note.content} />
        )}
        <div css={cssNote.info}>
          <div css={cssNote.channelInfo}>
            <ChannelTypeChattingIcon
              channelType={note.channelType}
              css={{ fontSize: "1rem" }}
            />
            <Tooltip title={note.channelName}>
              <span css={cssOneLine}>{note.channelName}</span>
            </Tooltip>
          </div>
          <div css={cssNote.noteInfo}>
            {note.userId === null ? null : <Time time={note.createdAt} />}
            {!hasPermission("editMemberNote") ? null : (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "edit",
                      label: <Trans i18nKey="feat.member.note.edit" />,
                      icon: editIcon,
                      onClick: toggleOpenEdit.on,
                    },
                    {
                      key: "delete",
                      label: <Trans i18nKey="feat.member.note.delete" />,
                      icon: deleteIcon,
                      danger: true,
                      onClick: toggleOpenDelete.on,
                    },
                  ],
                }}
                placement="bottomRight"
              >
                <NarrowIconButton
                  icon={<MotifIcon un-i-motif="more" />}
                  size="28px"
                  iconSize="16px"
                />
              </Dropdown>
            )}
            <DeleteNoteModal
              note={note}
              open={openDelete}
              onCancel={toggleOpenDelete.off}
            />
          </div>
        </div>
      </div>
    );
  });
})();

const NOTE_CLIP_SIZE = 2;

const Notes: FC = () => {
  const inView = useInView();
  const [size, setSize] = useState(NOTE_CLIP_SIZE);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const notesQueries = useUnifiedNotesQueries(
    {
      orgId,
      memberId: memberIdUtils.useGet(),
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: inView.inView,
    },
  );
  const notesToShow = useMemo(
    () => (notesQueries.notes ?? []).slice(0, size),
    [notesQueries.notes, size],
  );
  const onClickMore = useHandler(function onClickMore() {
    setSize((n) => n + NOTE_CLIP_SIZE);
  });

  return (
    <div
      ref={inView.ref}
      css={css({
        display: "flex",
        gap: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
      })}
    >
      <div
        css={css({
          display: "flex",
          color: theme.colors.neutral009,
          fontSize: "0.75rem",
          fontWeight: 500,
          gap: 4,
          alignItems: "center",
        })}
      >
        <Trans i18nKey="feat.member.notes.label" />
        {notesQueries.notes.length === 0 ? null : (
          <Badge variant="plain">{notesQueries.notes.length}</Badge>
        )}
      </div>
      {notesQueries.notes.length === 0 ? (
        <span css={cssFgNote}>
          <Trans i18nKey="feat.member.note.noData" />
        </span>
      ) : (
        <>
          {notesToShow.map((note) => (
            <Note key={note.id} note={note} />
          ))}
          {size >= notesQueries.notes.length ? null : (
            <Button
              type="link"
              size="small"
              onClick={onClickMore}
              css={cssToggleButton}
            >
              <Trans i18nKey="feat.member.note.loadMore" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const UnifiedNotes: FC = () => {
  const { hasPermission } = useUserPermission();
  return (
    <ErrorBoundary.Alert>
      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        })}
      >
        {!hasPermission("editMemberNote") ? null : <AddNote />}
        <Notes />
      </div>
    </ErrorBoundary.Alert>
  );
};

export { UnifiedNotes };
