import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { memo } from "@zeffiroso/utils/react/memo";

import { UserSelector as OriginalUserSelector } from "@/resources/user/UserSelector";

export const UserSelector = memo(function UserSelector({
  value,
  onChange,
  ...props
}: {
  value?: number;
  onChange?: (value: number) => void;
} & Omit<OriginalUserSelector.Props, "value" | "onChange">) {
  return (
    <OriginalUserSelector
      value={
        value === undefined ? value : Number.isNaN(value) ? undefined : value
      }
      onChange={useHandler((value) => {
        if (!onChange) return;

        if (value === undefined) {
          onChange(Number.NaN);
          return;
        }
        onChange(Number(value));
      })}
      {...props}
    />
  );
});
