import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { Popover as BasePopover } from "@/components/Popover";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  closeButton: css({
    position: "absolute",
    top: 12,
    right: 12,
  }),
});

const overlayInnerStyle: ComponentProps<
  typeof BasePopover
>["overlayInnerStyle"] = {
  padding: 16,
};

namespace Popover {
  export type Ref = BasePopover.Ref;
  export type CloseButtonProps = ComponentProps<typeof NarrowIconButton>;
  export interface OwnProps {
    closeButtonProps?: CloseButtonProps;
  }
  export type Props = Overwrite<BasePopover.Props, OwnProps>;
}

const PopoverInner = forwardRef<Popover.Ref, Popover.Props>(
  function PopoverInner({ content, closeButtonProps, ...props }, ref) {
    const handleClose = useHandler<Popover.CloseButtonProps["onClick"]>(
      function (...args) {
        closeButtonProps?.onClick?.(...args);
        props.onOpenChange?.(false);
      },
    );

    return (
      <BasePopover
        overlayInnerStyle={overlayInnerStyle}
        {...props}
        ref={ref}
        content={
          <>
            {content}
            <NarrowIconButton
              iconSize="small"
              css={styles.closeButton}
              icon={<MotifIcon un-i-motif="cross" />}
              {...closeButtonProps}
              onClick={handleClose}
            />
          </>
        }
      />
    );
  },
);

const Popover = Object.assign(PopoverInner, {
  styles,
  overlayInnerStyle,
});

assignDisplayName(Popover, "Popover");

export { Popover };
