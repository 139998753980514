import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { useMutation } from "@tanstack/react-query";
import { ErrorSchema } from "@zeffiroso/cantata/models";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";
import { theme } from "@zeffiroso/theme";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { getDomainFromEmail } from "@zeffiroso/utils/string/getDomainFromEmail";
import { isAbortError } from "@zeffiroso/utils/vanilla/isAbortError";
import { AxiosError } from "axios";
import type { AuthProvider } from "firebase/auth";
import { isEqual, merge } from "lodash-es";
import type { ElementRef, FC, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  authProviderStore,
  authSdk,
  getFirstSsoProvider,
  useSignInUtils,
} from "@/app/auth";
import { fromSsoPageStore } from "@/app/auth/fromSsoPageStore";
import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { Input } from "@/components/Input";
import { Link } from "@/components/Link";
import { useTwoFaFormUtils } from "@/resources/auth/twoFa/useTwoFaFormUtils";
import { LoginForm } from "@/routes/Auth/components/Form";
import { LoginSlogan } from "@/routes/Auth/components/LoginSlogan";
import {
  Container,
  LeftWrapper,
  MainTitle,
  RightWrapper,
} from "@/routes/Auth/components/Styled";
import { SiteSelectorEntry } from "@/routes/Auth/SiteSelectorEntry";
import { useGetErrorMessage } from "@/shared/application/error/handleError";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";

function useSetupPageInfo() {
  const pageParams = useParams();
  const ssoDomain = useMemo(() => pageParams["domain"] || "", [pageParams]);
  const isSsoPage = useMemo(() => Boolean(ssoDomain), [ssoDomain]);
  const pageInfo = useMemo(
    () => ({ ssoDomain, isSsoPage }),
    [isSsoPage, ssoDomain],
  );
  return pageInfo;
}

type LoginMethod =
  | {
      type: "usernamePassword";
    }
  | {
      type: "domain";
    }
  | {
      type: "domainFromEmail";
    };

function initialLoginMethod(): LoginMethod {
  const authProvider = authProviderStore.getValue();
  if (!authProvider) return { type: "usernamePassword" };
  return {
    type: "domain",
  };
}

const SignInWithPassword: FC = () => {
  const { t } = useTranslation();
  const enabledSiteSelector = useFeatureFlag("siteSelector");

  const contextValue = Context.useValue();
  const { errorMessage, isLoading, signInWithPassword } = contextValue;

  const switchToSignInWithDomain = useHandler(
    function switchToSignInWithDomain() {
      contextValue.setLoginMethod({ type: "domain" });
    },
  );

  return (
    <>
      <MainTitle>
        <MainTitle.Logo />
        <Trans i18nKey="login.title" />
      </MainTitle>
      {!flutterWebViewAppSdk.isFlutterWebViewApp &&
      !enabledSiteSelector ? null : (
        <SiteSelectorEntry css={css({ marginBottom: "40px" })} />
      )}
      <LoginForm
        errorMessage={errorMessage}
        loading={isLoading}
        onSubmit={signInWithPassword}
        shouldShowResetPasswordLink
      />
      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "18px",
        })}
      >
        <div
          css={css({
            color: theme.colors.neutral009,
            fontSize: "0.75rem",
          })}
        >
          {t("login.method.display.or")}
        </div>
        <Button css={css({ width: "100%" })} onClick={switchToSignInWithDomain}>
          {t("login.method.action.signInWithDomain.label")}
        </Button>
      </div>
    </>
  );
};

type SignInWithDomainFormValues = {
  domain: string;
};
const SignInWithDomainForm = createEasyForm<SignInWithDomainFormValues>();

const DomainField = forwardRef<
  ElementRef<typeof Input>,
  ComponentProps<typeof Input>
>(function DomainField(props, ref) {
  const safeString = useMemo<ComponentProps<typeof Input>["safeString"]>(
    () =>
      merge(
        {
          trim: true,
        } satisfies ComponentProps<typeof Input>["safeString"],
        props.safeString,
      ),
    [props.safeString],
  );
  const preSafeString = useHandler<
    ComponentProps<typeof Input>["preSafeString"]
  >(function preSafeString(value) {
    return value.includes("@") ? getDomainFromEmail(value) : value;
  });
  const postSafeString = useHandler<
    ComponentProps<typeof Input>["postSafeString"]
  >(function postSafeString(value) {
    return value && !value.startsWith("@") ? `@${value}` : value;
  });
  const onKeyPress = useHandler<ComponentProps<typeof Input>["onKeyDown"]>(
    function onKeyPress(...args) {
      const e = args[0];
      if (checkKey(e, "Enter"))
        /**
         * Blur to format the input value before submitting.
         */
        e.currentTarget.blur();
      props.onKeyDown?.(...args);
    },
  );
  return (
    <Input
      {...props}
      ref={ref}
      safeString={safeString}
      preSafeString={preSafeString}
      postSafeString={postSafeString}
      onKeyPress={onKeyPress}
    />
  );
});

const SignInWithDomain: FC = function SignInWithDomain() {
  const [easyForm, form] = SignInWithDomainForm.useForm();
  const { t } = useTranslation();
  const contextValue = Context.useValue();
  const { pageInfo } = contextValue;
  const getDomainFromForm = useHandler(function getDomainFromForm() {
    const value = easyForm.useStore.getState().values?.domain ?? "";
    const prefix = "@";
    return value.startsWith(prefix) ? value.slice(prefix.length) : value;
  });
  const loginWithPassword = useHandler(function loginWithPassword() {
    contextValue.setLoginMethod({ type: "usernamePassword" });
    authProviderStore.clearValue();
  });
  const initialValues = useMemo<SignInWithDomainFormValues>(
    () => ({
      domain: (() => {
        // Enforce domain login if the page is SSO.
        if (pageInfo.isSsoPage) return `@${pageInfo.ssoDomain}`;
        const authProvider = authProviderStore.getValue();
        if (!authProvider) return "";
        return `@${authProvider.domain}`;
      })(),
    }),
    [pageInfo.isSsoPage, pageInfo.ssoDomain],
  );
  const onFinish = useHandler<
    ComponentProps<typeof SignInWithDomainForm>["onFinish"]
  >(() => {
    contextValue.loginWithDomain(getDomainFromForm());
  });
  const enabledSiteSelector = useFeatureFlag("siteSelector");
  useEffect(
    function abortWhenUnmount() {
      return function cleanup() {
        contextValue.useAbortControllerStore.getState().abort();
      };
    },
    [contextValue.useAbortControllerStore],
  );

  const isLoading = contextValue.isLoading;
  return (
    <SignInWithDomainForm
      form={form}
      requiredMark={false}
      layout="vertical"
      css={css`
        display: flex;
        flex-direction: column;
        gap: 50px;
      `}
      disabled={isLoading}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <div
        css={css([
          cssFlexInheritAndFill,
          css`
            gap: 48px;
          `,
        ])}
      >
        <div
          css={css([
            cssFlexInheritAndFill,
            css`
              gap: 8;
            `,
          ])}
        >
          <div
            css={css([
              cssFlexInheritAndFill,
              css`
                gap: 20px;
              `,
            ])}
          >
            <h1
              css={css`
                font-size: 24px;
                font-weight: 500;
              `}
            >
              {contextValue.loginMethod.type === "domain"
                ? t("login.title")
                : t("login.signInWithDomainFromEmail.title")}
            </h1>
            <h2
              css={css`
                font-size: 20px;
                font-weight: 500;
              `}
            >
              {contextValue.loginMethod.type === "domain"
                ? t("login.signInWithDomain.subTitle")
                : t("login.signInWithDomainFromEmail.subTitle")}
            </h2>
          </div>
          {!flutterWebViewAppSdk.isFlutterWebViewApp &&
          !enabledSiteSelector ? null : (
            <SiteSelectorEntry />
          )}
        </div>
        <div>
          <SignInWithDomainForm.Item
            label={t("login.signInWithDomain.field.domain.label")}
            name="domain"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DomainField
              autoFocus
              placeholder={t("login.signInWithDomain.field.domain.placeholder")}
              {...(!pageInfo.isSsoPage
                ? null
                : {
                    /**
                     * If the page is SSO, this value will use the domain from
                     * the URL directly and readonly.
                     */
                    disabled: true,
                  })}
            />
          </SignInWithDomainForm.Item>
          {!contextValue.errorMessage ? (
            <Alert
              message={t("login.signInWithDomain.helperText.content")}
              type="primary"
            />
          ) : (
            <Alert message={contextValue.errorMessage} type="error" />
          )}
        </div>
        <Button type="primary" htmlType="submit">
          {t("login.signInWithDomain.action.signIn.label")}
        </Button>
      </div>
      {
        // We don't provider other login methods if the page is SSO.
        pageInfo.isSsoPage ? null : (
          <Link
            css={css`
              align-self: center;
              font-size: 1rem;
            `}
            onClick={loginWithPassword}
            disabled={isLoading}
          >
            {t("login.signInWithDomain.action.loginWithPassword.label")}
          </Link>
        )
      }
    </SignInWithDomainForm>
  );
};

const TwoFa = (() => {
  const styles = defineStyles({
    content: css({
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 24,
      "& p": {
        marginBottom: 0,
      },
      "& .ant-form-item": {
        marginBottom: 0,
      },
    }),
  });
  const TwoFa: FC = () => {
    const { t } = useTranslation();
    const contextValue = Context.useValue();
    const { signInUtils } = contextValue;
    const twoFaFormUtils = useTwoFaFormUtils({
      signInUtils,
    });

    const verifyTwoFaMutation = signInUtils.twoFa.mutations.verifyTwoFaMutation;

    return (
      <twoFaFormUtils.EasyForm {...twoFaFormUtils.easyFormProps}>
        <MainTitle>
          <MainTitle.Logo />
          <Trans i18nKey="login.title" />
        </MainTitle>
        <div css={styles.content}>
          {twoFaFormUtils.content}
          <div
            style={{
              display: "flex",
              flexDirection: "inherit",
              alignItems: "inherit",
              gap: 16,
            }}
          >
            {twoFaFormUtils.expired ? null : (
              <Button
                type="primary"
                htmlType="submit"
                loading={verifyTwoFaMutation.isLoading}
              >
                {t("page.login.2fa.actions.verify")}
              </Button>
            )}
            {twoFaFormUtils.resendButton}
            <Button
              type="link"
              style={{
                margin: "auto",
                padding: "8px 11px",
              }}
              onClick={() =>
                signInUtils.twoFa.utils.cancel("User canceled 2FA")
              }
            >
              {t("page.login.2fa.actions.signInWithAnotherEmail")}
            </Button>
          </div>
        </div>
      </twoFaFormUtils.EasyForm>
    );
  };
  return TwoFa;
})();

const Context = (() => {
  function useSetupContext() {
    const { t } = useTranslation();
    const pageInfo = useSetupPageInfo();
    const fromSsoPage = fromSsoPageStore.useValue();

    const getErrorMessage = useGetErrorMessage();

    const [loginMethod, setLoginMethod] = useState<LoginMethod>(
      pageInfo.isSsoPage
        ? // Enforce domain login if the page is SSO.
          { type: "domain" }
        : initialLoginMethod,
    );
    useEffect(
      function clearFromSsoPageIfNonSsoPageVisited() {
        if (pageInfo.isSsoPage || !fromSsoPage) return;
        fromSsoPageStore.clearValue();
      },
      [fromSsoPage, pageInfo.isSsoPage],
    );
    useEffect(
      function syncLoginMethodFromLoginDomainController() {
        // Enforce domain login if the page is SSO.
        if (pageInfo.isSsoPage) {
          return;
        }
        return authProviderStore.useStore.subscribe(
          function syncLoginMethodFromAuthProviderController(current, prev) {
            const currentAuthProvider = current.value;
            const prevCurrentAuth = prev.value;
            if (isEqual(currentAuthProvider, prevCurrentAuth)) return;
            if (currentAuthProvider) {
              const nextMethod: LoginMethod = {
                type: "domainFromEmail",
              };
              if (loginMethod.type !== "usernamePassword") return;
              setLoginMethod(nextMethod);
              return;
            }
            if (!currentAuthProvider) {
              const nextMethod: LoginMethod = { type: "usernamePassword" };
              if (isEqual(nextMethod, loginMethod)) return;
              setLoginMethod(nextMethod);
            }
          },
        );
      },
      [loginMethod, pageInfo.isSsoPage, pageInfo.ssoDomain],
    );
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const clearErrorMessage = useHandler(function clearErrorMessage() {
      setErrorMessage(null);
    });
    useChange(
      loginMethod.type,
      function syncLoginMethodToLoginDomainController(state, prevState) {
        if (state === prevState) return;
        clearErrorMessage();
        if (state === "usernamePassword") authProviderStore.clearValue();
      },
    );

    const signInUtils = useSignInUtils({
      onSuccess() {
        if (pageInfo.isSsoPage) {
          fromSsoPageStore.setValue(true);
        }
      },
      onError(error) {
        if (isAbortError(error)) return;
        if (error instanceof AxiosError) {
          const statusCode = error.response?.status;
          if (!statusCode) return;
          if (statusCode < 400 || statusCode >= 500) return;
          const parsedErrorResult = ErrorSchema.safeParse(error.response?.data);

          setErrorMessage(
            parsedErrorResult.success &&
              parsedErrorResult.data.name ===
                "PARAMETER_EMAIL_OR_PASSWORD_INVALID" ? (
              <Trans i18nKey="login.wrongEmailOrPassword" />
            ) : (
              getErrorMessage(error)
            ),
          );
          return;
        }
        setErrorMessage(getErrorMessage(error));
      },
    });

    const signInMutation = signInUtils.mutation;

    const signInWithPassword = useHandler(function signInWithPassword({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) {
      clearErrorMessage();
      signInMutation.mutate({ email, password });
    });

    const useAbortControllerStore = useUseAbortControllerStore();
    useEffect(
      function abortWhenUnmount() {
        return function cleanup() {
          useAbortControllerStore.getState().abort();
        };
      },
      [useAbortControllerStore],
    );
    const firebaseSignInMutation = useMutation({
      mutationFn: async (params: {
        domain: string;
        provider: AuthProvider;
      }) => {
        const signal = useAbortControllerStore.getState().signal;
        const firebaseSignInResult = await authSdk.signInPopup({
          provider: params.provider,
          signal,
        });
        signal.throwIfAborted();
        if (!firebaseSignInResult.idToken) throw new Error("No access token");
        /**
         * We don't check the domain for now because the Firebase Blocking
         * Function already does that. Currently bypassing to the backend.
         *
         * - Slack: [#proj-sso](https://chatbotgang.slack.com/archives/C06F4CL5CCS/p1723008978188579?thread_ts=1722409416.575659&cid=C06F4CL5CCS)
         */
        const needCheckDomain: boolean = false;
        if (
          !firebaseSignInResult.email ||
          (needCheckDomain &&
            getDomainFromEmail(firebaseSignInResult.email) !== params.domain)
        ) {
          setErrorMessage(t("login.wrongEmailOrPassword"));
          return;
        }
        signInMutation.mutate({
          domain: params.domain,
          provider: params.provider,
          idToken: firebaseSignInResult.idToken,
          uid: firebaseSignInResult.uid,
        });
      },
    });
    const domainMutation = useMutation({
      mutationFn: async (params: { domain: string }) => {
        const signal = useAbortControllerStore.getState().signal;
        const provider = await getFirstSsoProvider(params.domain);
        signal.throwIfAborted();
        if (!provider) {
          setErrorMessage(
            t("login.signInWithDomain.error.providerNotFound.content"),
          );
          return;
        }
        firebaseSignInMutation.mutate({ provider, domain: params.domain });
      },
    });
    const loginWithDomain = useHandler(function loginWithDomain(
      domain: string,
    ) {
      clearErrorMessage();
      domainMutation.mutate({ domain });
    });

    const isLoading = domainMutation.isLoading || signInMutation.isLoading;

    const contextValue = useMemo(
      () => ({
        pageInfo,
        errorMessage,
        loginMethod,
        signInUtils,
        setLoginMethod,
        signInWithPassword,
        loginWithDomain,
        isLoading,
        useAbortControllerStore,
      }),
      [
        errorMessage,
        isLoading,
        loginMethod,
        loginWithDomain,
        pageInfo,
        signInUtils,
        signInWithPassword,
        useAbortControllerStore,
      ],
    );
    return contextValue;
  }
  const Context = createContext<ReturnType<typeof useSetupContext>>({
    name: "LoginContext",
  });
  const Provider: FC<{ children: ReactNode }> = function Provider(props) {
    const value = useSetupContext();
    return <Context.Provider value={value} {...props} />;
  };
  return {
    useValue: Context.useContext,
    Provider,
  };
})();

const Login: FC = () => {
  const contextValue = Context.useValue();
  const twoFaState = contextValue.signInUtils.twoFa.utils.useState();

  return (
    <Container>
      <LeftWrapper>
        <LoginSlogan />
      </LeftWrapper>
      <RightWrapper>
        {twoFaState ? (
          <TwoFa />
        ) : contextValue.loginMethod.type === "usernamePassword" ? (
          <SignInWithPassword />
        ) : (
          <SignInWithDomain />
        )}
      </RightWrapper>
    </Container>
  );
};

const Wrapped: FC = function Wrapped() {
  return (
    <Context.Provider>
      <Login />
    </Context.Provider>
  );
};

export { Wrapped as Login };
