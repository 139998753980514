import { SettingOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { Badge, Checkbox, Menu } from "antd";
// eslint-disable-next-line no-restricted-imports -- It's not exposed from `antd`.
import type {
  ItemType,
  MenuDividerType,
  MenuItemType,
} from "antd/es/menu/hooks/useItems";
import type { ElementRef, ForwardedRef, ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Primitive } from "type-fest";

import { Button, type ButtonProps } from "@/components/Button";
import { Paper } from "@/components/Paper";
import { Trigger } from "@/components/Trigger";
import { defineStyles } from "@/shared/emotion";
import { isPrimitive } from "@/shared/types/isPrimitive";

import type { Table } from ".";

const styles = defineStyles({
  button: css({
    "&:not([disabled], :active, :focus, :hover)": {
      borderColor: theme.colors.blue006,
      color: theme.colors.blue006,
    },
  }),
  description: css({
    all: "unset",
    padding: "5px 12px",
    color: theme.colors.neutral007,
  }),
  paper: css({
    display: "flex",
    flexDirection: "column",
    padding: 8,
    gap: 8,
  }),
  menu: css({
    borderInlineEnd: "none",
    ".ant-menu-title-content": {
      paddingInline: 16,
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  }),
});

/**
 * We handle the selection with Ant Design's `Menu` component. So the value
 * should be the key of the selected items.
 */
type BaseValue = NonNullable<
  ComponentProps<typeof Menu>["selectedKeys"]
>[number];

type PrimitiveOption<TValue extends BaseValue> = TValue & Primitive;
type SimpleOption<TValue extends BaseValue> = OverrideWith<
  ComponentProps<"li"> & MenuItemType,
  { key: TValue; label?: ReactNode }
>;
type DividerOption = MenuDividerType;

type Option<TValue extends BaseValue> =
  | PrimitiveOption<TValue>
  | SimpleOption<TValue>
  | DividerOption;

function isPrimitiveOption<TValue extends BaseValue>(
  option: Option<TValue>,
): option is PrimitiveOption<TValue> {
  return typeof option !== "object";
}

function isDividerOption<TValue extends BaseValue>(
  option: Option<TValue>,
): option is DividerOption {
  return (
    typeof option === "object" && "type" in option && option.type === "divider"
  );
}

type ColumnsFilterRef = ElementRef<typeof Button>;

type ColumnsFilterProps<TValue extends BaseValue = BaseValue> = OverrideWith<
  ButtonProps,
  {
    /**
     * The options of the columns filter. The key of the option should be the
     * value of the selected items.
     */
    options: Array<Option<TValue>>;

    /**
     * The value of the selected items. If you want to control the value, you
     */
    value?: Array<TValue>;

    /**
     * The callback function that is triggered when the selected items are
     * changed.
     */
    onChange?: (value: Array<TValue>) => void;

    /**
     * The children of the button. If it's not defined, the default title will
     * be used. It can be `null` to hide the title.
     */
    children?: ReactNode;

    /**
     * The props of the trigger component.
     */
    triggerProps?: Omit<Trigger.Props, "content">;

    /**
     * The props of the popup paper.
     */
    paperProps?: Omit<ComponentProps<typeof Paper>, "children">;

    /**
     * Menu props.
     */
    menuProps?: Omit<
      ComponentProps<typeof Menu>,
      "multiple" | "inlineIndent" | "mode" | "items" | "selectedKeys"
    >;
  }
>;

interface ColumnsFilterType {
  <TValue extends BaseValue = BaseValue>(
    props: ColumnsFilterProps<TValue>,
    ref?: ColumnsFilterRef,
  ): ReactNode;
}

/**
 * This component provides a column filter for the table. It's a button that
 * opens a popup allowing users to select which columns to display.
 *
 * Typically, this component is not used directly in the application. Instead,
 * use the `useColumnsFilter` hook.
 *
 * @see {@link useColumnsFilter}
 */
const ColumnsFilter: ColumnsFilterType = forwardRef(function ColumnsFilter<
  TValue extends BaseValue,
>(
  {
    options,
    onChange,
    triggerProps,
    paperProps,
    menuProps,
    ...props
  }: ColumnsFilterProps<TValue>,
  ref: ForwardedRef<ColumnsFilterRef>,
): ReactNode {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState<Array<TValue>>(
    Array.isArray(props.value) ? props.value : [],
  );
  const mergedValue = useMemo<Array<TValue>>(
    () => (Array.isArray(props.value) ? props.value : localValue),
    [localValue, props],
  );

  const optionsToMenuItems = useMemo(
    () =>
      function optionsToMenuItems(
        options: Array<Option<TValue>>,
      ): Array<ItemType> {
        return options.map<ItemType>((option) =>
          isPrimitiveOption(option)
            ? ({
                key: option,
                label: option,
              } satisfies MenuItemType)
            : isDividerOption(option)
              ? (option satisfies MenuDividerType)
              : ({
                  ...option,
                  // Allow fallback to key if label is not defined.
                  label: "label" in option ? option.label : option.key,
                } satisfies MenuItemType as MenuItemType),
        );
      },
    [],
  );

  const menuItems = useMemo(
    () => optionsToMenuItems(options),
    [options, optionsToMenuItems],
  );

  const menuOnSelect = useHandler<ComponentProps<typeof Menu>["onSelect"]>(
    function menuOnSelect(...args) {
      menuProps?.onSelect?.(...args);
      const selectInfo = args[0];
      const selectedKeys = selectInfo.selectedKeys as Array<TValue>;
      setLocalValue(selectedKeys);
      onChange?.(selectedKeys);
    },
  );

  const menuOnDeselect = useHandler<ComponentProps<typeof Menu>["onDeselect"]>(
    function menuOnDeselect(...args) {
      menuProps?.onDeselect?.(...args);
      const selectInfo = args[0];
      const selectedKeys = selectInfo.selectedKeys as Array<TValue>;
      setLocalValue(selectedKeys);
      onChange?.(selectedKeys);
    },
  );

  const cssPaper = useMemo(
    () => css([styles.paper, paperProps?.css]),
    [paperProps?.css],
  );

  const cssMenu = useMemo(
    () => css([styles.menu, menuProps?.css]),
    [menuProps?.css],
  );

  const content = useMemo<NonNullable<Trigger.Props["content"]>>(
    () => (
      <Paper {...paperProps} css={cssPaper}>
        <div>
          <p css={styles.description}>
            {t("component.table.columnsFilter.popup.description")}
          </p>
          <Menu
            {...menuProps}
            css={cssMenu}
            multiple
            inlineIndent={0}
            mode="inline"
            items={menuItems}
            selectedKeys={mergedValue}
            onSelect={menuOnSelect}
            onDeselect={menuOnDeselect}
          />
        </div>
      </Paper>
    ),
    [
      cssMenu,
      cssPaper,
      menuItems,
      menuOnDeselect,
      menuOnSelect,
      menuProps,
      mergedValue,
      paperProps,
      t,
    ],
  );
  const children = useMemo<ButtonProps["children"]>(
    () =>
      !("children" in props)
        ? t("component.table.columnsFilter.button.title")
        : props.children,
    [props, t],
  );
  return (
    <Trigger content={content} trigger={["click"]} {...triggerProps}>
      <Button
        css={styles.button}
        icon={<SettingOutlined />}
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    </Trigger>
  );
}) as ColumnsFilterType;

type GetCurrentKeyFromColumnsType<
  TColumnType extends Table.ColumnsType<any>[number],
> = TColumnType extends {
  key: infer TValue extends BaseValue;
}
  ? TValue
  : never;

type GetChildrenKeyFromColumnsType<
  TColumnType extends Table.ColumnsType<any>[number],
  TAllColumnType = never,
> = TColumnType extends TAllColumnType
  ? /**
     * Prevent infinite recursion.
     */
    never
  : TColumnType extends {
        children: infer TChildren extends Table.ColumnsType<any>;
      }
    ? ColumnKeyFromColumns<TChildren, TAllColumnType | TChildren[number]>
    : never;

type ColumnKeyFromColumns<
  TColumnsType extends Table.ColumnsType<any>,
  TAllColumnType = never,
> =
  | GetChildrenKeyFromColumnsType<TColumnsType[number], TAllColumnType>
  | GetCurrentKeyFromColumnsType<TColumnsType[number]>;

function getAllKeysFromColumns<
  TColumns extends Table.ColumnsType<any> = Table.ColumnsType<object>,
>(columns: TColumns): Array<ColumnKeyFromColumns<TColumns>> {
  return columns.flatMap((column) =>
    "key" in column
      ? [column.key as ColumnKeyFromColumns<TColumns>]
      : "children" in column
        ? getAllKeysFromColumns(column.children)
        : [],
  );
}

type UseColumnsFilterOptions<
  TRecordType extends object = object,
  TColumns extends
    Table.ColumnsType<TRecordType> = Table.ColumnsType<TRecordType>,
> = {
  /**
   * Data source is not really needed for the column filter. It's only used to
   * infer the type of the record.
   */
  dataSource?: Array<TRecordType>;
  columns: TColumns;
} & NoInfer<{
  options: Array<Option<ColumnKeyFromColumns<TColumns>>>;
  columnsFilterProps?: Omit<
    ColumnsFilterProps<ColumnKeyFromColumns<TColumns>>,
    "options" | "value" | "onChange"
  >;
  columnsFilterBadgeProps?: Omit<
    ComponentProps<typeof Badge>,
    "children" | "count"
  >;
}>;

/**
 * This hook provides a column filter for the table.
 *
 * ```tsx
 * const columns = useMemo(
 *   () =>
 *     [
 *       // Define columns here. Same as the `columns` prop of the `Table` component.
 *       // To make the type inference work, we need to use `as const`.
 *     ] as const satisfies ColumnType<RecordType>,
 *   []
 * );
 * // Define columns filter options. Recommend to use useMemo to prevent re-render.
 * const columnsFilterOptions = useMemo<
 *   UseColumnsFilterOptions<RecordType, typeof columns>
 * >(
 *   () => ({
 *     columns,
 *     options: [
 *       // Type is inferred from `columns`.
 *       // Notice that because selectedKeys is `Array<string>`, only string keys are allowed.
 *
 *       // It can be key of the column:
 *       "firstName",
 *       // or a custom label:
 *       {
 *         key: "firstName",
 *         label: <Label>First Name</Label>,
 *       },
 *       // Divider:
 *       {
 *         key: "divider",
 *       }
 *     ],
 *   }),
 *   []
 * );
 *
 * // Get columns filter utility.
 * const columnsFilter = useColumnsFilter(columnsFilterOptions);
 *
 * return (
 *   <Container>
 *     {columnsFilter.node} // render filter node
 *     <Table
 *       {...tableProps}
 *       // Override columns with filtered columns
 *       columns={columnsFilter.columns}
 *     />
 *   </Container>
 * );
 *   ```
 */
function useColumnsFilter<
  TRecordType extends object = object,
  TColumns extends
    Table.ColumnsType<TRecordType> = Table.ColumnsType<TRecordType>,
>({
  columns,
  options,
  columnsFilterProps,
  columnsFilterBadgeProps,
}: UseColumnsFilterOptions<TRecordType, TColumns>) {
  type TColumnKey = ColumnKeyFromColumns<typeof columns>;
  const allKeys: Array<TColumnKey> = useMemo(
    () => getAllKeysFromColumns(columns),
    [columns],
  );
  const allOptionsKeys: Array<TColumnKey> = useMemo(
    () =>
      allKeys.flatMap<TColumnKey>((key) =>
        options.some((option) =>
          isPrimitiveOption(option) ? option === key : option.key === key,
        )
          ? [key]
          : [],
      ),
    [allKeys, options],
  );
  const [selectedKeys, setSelectedKeys] =
    useState<Array<TColumnKey>>(allOptionsKeys);
  const resetSelectedKeys = useHandler(function resetSelectedKeys() {
    setSelectedKeys(allKeys);
  });
  const filteredColumns = useMemo<Table.ColumnsType<TRecordType>>(() => {
    function filterColumns(
      columns: Table.ColumnsType<TRecordType>,
    ): Table.ColumnsType<TRecordType> {
      return columns.flatMap((column) => {
        if ("children" in column) {
          const filteredChildren = filterColumns(column.children);
          if (filteredChildren.length === 0) {
            return [];
          }
          if (filteredChildren.length === 1) {
            return filteredChildren;
          }
          return [{ ...column, children: filteredChildren }];
        }
        if ("key" in column) {
          return !allOptionsKeys.includes(column.key as TColumnKey) ||
            selectedKeys.includes(column.key as TColumnKey)
            ? [column]
            : [];
        }
        return [column];
      });
    }
    return filterColumns(columns);
  }, [allOptionsKeys, columns, selectedKeys]);
  const flattenColumns = useMemo(() => {
    function getFlattenColumns(
      column: Table.ColumnsType<TRecordType>[number],
    ): Table.ColumnsType<TRecordType> {
      const arr = [];
      if ("children" in column) {
        arr.push(...column.children.flatMap(getFlattenColumns));
      }
      if ("key" in column) {
        arr.push(column);
      }
      return arr;
    }
    return columns.flatMap(getFlattenColumns);
  }, [columns]);
  const findColumnLabel = useMemo(
    () =>
      function findColumnLabel(option: TColumnKey): ReactNode {
        const column = flattenColumns.find((column) => column.key === option);
        const title = (() => {
          if (!column) {
            return option;
          }
          return "title" in column
            ? typeof column.title === "function"
              ? column.title({})
              : column.title
            : option;
        })();
        return (
          <>
            <Checkbox checked={selectedKeys.includes(option)} />
            <div>{title}</div>
          </>
        );
      },
    [flattenColumns, selectedKeys],
  );
  const columnFilterOptions = useMemo<Array<Option<TColumnKey>>>(
    () =>
      options.map((option) =>
        isPrimitive(option)
          ? {
              key: option,
              label: findColumnLabel(option),
            }
          : isDividerOption(option)
            ? option
            : {
                ...option,
                label: (
                  <>
                    <Checkbox
                      checked={selectedKeys.includes(option.key)}
                      disabled={option.disabled}
                    />
                    <div>{"label" in option ? option.label : option.key}</div>
                  </>
                ),
              },
      ),
    [findColumnLabel, options, selectedKeys],
  );
  const badgeCount = useMemo(
    () =>
      allOptionsKeys.length === selectedKeys.length
        ? null
        : selectedKeys.length,
    [allOptionsKeys.length, selectedKeys.length],
  );
  const getScrollX = useCallback(
    function getScrollX({ min, max }: { min: number; max: number }) {
      const range = max - min;
      const step = range / allOptionsKeys.length;
      return step * selectedKeys.length + min;
    },
    [allOptionsKeys.length, selectedKeys.length],
  );
  const columnsFilter = useMemo(() => {
    return {
      node: (
        <Badge
          showZero
          color={theme.colors.blue006}
          {...columnsFilterBadgeProps}
          count={badgeCount}
        >
          <ColumnsFilter<TColumnKey>
            {...columnsFilterProps}
            options={columnFilterOptions}
            value={selectedKeys}
            onChange={setSelectedKeys}
          />
        </Badge>
      ),
      columns: filteredColumns,
      resetSelectedKeys,
      selectedKeys,
      setSelectedKeys,
      getScrollX,
    };
  }, [
    badgeCount,
    columnFilterOptions,
    columnsFilterBadgeProps,
    columnsFilterProps,
    filteredColumns,
    resetSelectedKeys,
    selectedKeys,
    getScrollX,
  ]);
  return columnsFilter;
}

export { ColumnsFilter, useColumnsFilter };
export type {
  BaseValue,
  ColumnKeyFromColumns,
  ColumnsFilterProps,
  Option,
  UseColumnsFilterOptions,
};
