import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type React from "react";
import type { ElementRef, ReactNode } from "react";

import { RadioGroup } from "@/components/Radio";
import { RadioSelectOption } from "@/components/RadioSelect/RadioSelectOption";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  RadioSelect: css({
    display: "flex",
    gap: 8,
    flexDirection: "column",
    alignItems: "stretch",
  }),
});

namespace RadioSelect {
  export type Ref = ElementRef<typeof RadioGroup>;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<typeof RadioGroup>, OwnProps>;
  export type Type = (props: Props, ref?: React.Ref<Ref>) => ReactNode;
}

const RadioSelectInternal: RadioSelect.Type = forwardRef<
  RadioSelect.Ref,
  RadioSelect.Props
>(function RadioSelectInternal(props, ref) {
  return <RadioGroup css={styles.RadioSelect} ref={ref} {...props} />;
});

/**
 * A radio select component. It's a styled `RadioGroup`.
 *
 * Reference: [Figma](https://www.figma.com/design/86kJNE9cWYYI9cHpau4EE3/APP?node-id=7514-17101&m=dev)
 */
const RadioSelect = Object.assign(RadioSelectInternal, {
  Option: RadioSelectOption,
  styles,
});

assignDisplayName(RadioSelect, "RadioSelect");

export { RadioSelect };
