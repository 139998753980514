import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef } from "react";

import { Radio } from "@/components/Radio";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  RadioSelectOption: css({
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.colors.neutral003,
    padding: 16,
    gap: 4,
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    marginRight: 0,
    "> *:not(.ant-radio)": {
      flex: 1,
      content: undefined,
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
    },
    "&:after": {
      content: "none",
    },
    "&.ant-radio-wrapper-checked": {
      outlineWidth: 2,
      outlineStyle: "solid",
      outlineColor: theme.colors.blue006,
    },
  }),
});

namespace RadioSelectOption {
  export type Ref = ElementRef<typeof Radio>;
  export interface OwnProps {
    // props here
  }
  export type Props = Overwrite<ComponentProps<typeof Radio>, OwnProps>;
}

const RadioSelectOptionInternal = forwardRef<
  RadioSelectOption.Ref,
  RadioSelectOption.Props
>(function RadioSelectOptionInternal(props, ref) {
  return <Radio css={styles.RadioSelectOption} ref={ref} {...props} />;
});

/**
 * A radio select option component. It's a styled `Radio`.
 *
 * Reference: [Figma](https://www.figma.com/design/86kJNE9cWYYI9cHpau4EE3/APP?node-id=7514-17100&m=dev)
 */
const RadioSelectOption = Object.assign(RadioSelectOptionInternal, {
  styles,
});

assignDisplayName(RadioSelectOption, "RadioSelectOption");

export { RadioSelectOption };
