import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import type { Overwrite } from "@mui/types";
import { type ElementRef, useMemo } from "react";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import {
  EMPTY_STRING_PLACEHOLDER,
  HIERARCHY_STRING_JOINER,
} from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { Badge } from "@/components/Badge";
import { defineStyles } from "@/shared/emotion";
import { fakeT } from "@/shared/g11n/fakeT";

const styles = defineStyles({});

namespace OrderStatus {
  export type Ref = ElementRef<"span">;
  export interface OwnProps {
    type: CantataTypes["Order"]["type"];
    status: CantataTypes["Order"]["ecStatus"];
  }
  export type Props = Overwrite<Omit<Badge.Props, "variant">, OwnProps>;
}

const t = fakeT;

const transLookup = define<
  Array<{
    type: CantataTypes["Order"]["type"];
    status: Extract<
      CantataTypes["Order"]["ecStatus"],
      { expected: true }
    >["value"];
    i18nKey: string;
    variant: Badge.Props["variant"];
  }>
>([
  {
    type: "91app",
    status: "WaitingToShipping",
    i18nKey: t("resource.order.status.91app.WaitingToShipping.label"),
    variant: "primary",
  },
  {
    type: "91app",
    status: "ConfirmedToShipping",
    i18nKey: t("resource.order.status.91app.ConfirmedToShipping.label"),
    variant: "primary",
  },
  {
    type: "91app",
    status: "Finish",
    i18nKey: t("resource.order.status.91app.Finish.label"),
    variant: "positive",
  },
  {
    type: "91app",
    status: "Cancel",
    i18nKey: t("resource.order.status.91app.Cancel.label"),
    variant: "negative",
  },
  {
    type: "91app",
    status: "WaitingToCreditCheck",
    i18nKey: t("resource.order.status.91app.WaitingToCreditCheck.label"),
    variant: "primary",
  },
  {
    type: "91app",
    status: "WaitingToPay",
    i18nKey: t("resource.order.status.91app.WaitingToPay.label"),
    variant: "primary",
  },
  {
    type: "91app",
    status: "WaitingToModifyStore",
    i18nKey: t("resource.order.status.91app.WaitingToModifyStore.label"),
    variant: "negative",
  },
  {
    type: "shopify",
    status: "FULFILLED",
    i18nKey: t("resource.order.status.shopify.FULFILLED.label"),
    variant: "positive",
  },
  {
    type: "shopify",
    status: "IN_PROGRESS",
    i18nKey: t("resource.order.status.shopify.IN_PROGRESS.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "ON_HOLD",
    i18nKey: t("resource.order.status.shopify.ON_HOLD.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "OPEN",
    i18nKey: t("resource.order.status.shopify.OPEN.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "PARTIALLY_FULFILLED",
    i18nKey: t("resource.order.status.shopify.PARTIALLY_FULFILLED.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "PENDING_FULFILLMENT",
    i18nKey: t("resource.order.status.shopify.PENDING_FULFILLMENT.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "RESTOCKED",
    i18nKey: t("resource.order.status.shopify.RESTOCKED.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "SCHEDULED",
    i18nKey: t("resource.order.status.shopify.SCHEDULED.label"),
    variant: "primary",
  },
  {
    type: "shopify",
    status: "UNFULFILLED",
    i18nKey: t("resource.order.status.shopify.UNFULFILLED.label"),
    variant: "negative",
  },
  {
    type: "shopline",
    status: "pending",
    i18nKey: t("resource.order.status.shopline.pending.label"),
    variant: "primary",
  },
  {
    type: "shopline",
    status: "confirmed",
    i18nKey: t("resource.order.status.shopline.confirmed.label"),
    variant: "primary",
  },
  {
    type: "shopline",
    status: "completed",
    i18nKey: t("resource.order.status.shopline.completed.label"),
    variant: "positive",
  },
  {
    type: "shopline",
    status: "cancelled",
    i18nKey: t("resource.order.status.shopline.cancelled.label"),
    variant: "negative",
  },
  {
    type: "cyberbiz",
    status: "open",
    i18nKey: t("resource.order.status.cyberbiz.open.label"),
    variant: "primary",
  },
  {
    type: "cyberbiz",
    status: "closed",
    i18nKey: t("resource.order.status.cyberbiz.closed.label"),
    variant: "positive",
  },
  {
    type: "cyberbiz",
    status: "cancelled",
    i18nKey: t("resource.order.status.cyberbiz.cancelled.label"),
    variant: "negative",
  },
]);

type OrderStatusTrans = (typeof transLookup)[number];

const unexpected = {
  i18nKey: t("resource.order.status.unknown.label"),
  variant: "plain",
} satisfies { i18nKey: string; variant: Badge.Props["variant"] };

const supportOrderStatus = new Set(transLookup.map((lookup) => lookup.type));

function isSupportOrderStatus(type: CantataTypes["Order"]["type"]) {
  return supportOrderStatus.has(type);
}

const useOrderStatusTrans = ({ status, type }: OrderStatus.OwnProps) => {
  return useMemo<OrderStatusTrans | undefined>(
    function lookupTrans() {
      if (!status || !status.expected) return;
      const matched = transLookup.find(
        (lookup) => lookup.type === type && lookup.status === status.value,
      );
      return matched;
    },
    [status, type],
  );
};

const OrderStatusInternal = forwardRef<OrderStatus.Ref, OrderStatus.Props>(
  function OrderStatusInternal({ status, type, ...props }, ref) {
    const enabledRenderRawEcValue = useFeatureFlag("renderRawEcValue");
    const statusTrans = useOrderStatusTrans({ status, type });

    return (
      <span {...props} ref={ref}>
        {!status ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <>
            <Trans
              i18nKey={!statusTrans ? unexpected.i18nKey : statusTrans.i18nKey}
            />
            {statusTrans && !enabledRenderRawEcValue ? null : (
              <>
                {HIERARCHY_STRING_JOINER}
                {!status.expected ? status.raw : status.value}
              </>
            )}
          </>
        )}
      </span>
    );
  },
);

namespace OrderStatusBadge {
  export type Ref = ElementRef<typeof Badge>;
  export type OwnProps = OrderStatus.OwnProps;
  export type Props = Overwrite<Omit<Badge.Props, "variant">, OwnProps>;
}

const OrderStatusBadge = forwardRef<Badge.Ref, OrderStatusBadge.Props>(
  function OrderStatusBadge({ status, type, ...props }, ref) {
    const statusTrans = useOrderStatusTrans({ status, type });

    if (!status || !status.expected || !statusTrans) {
      return (
        <Badge variant={unexpected.variant} {...props} ref={ref}>
          <Trans i18nKey={unexpected.i18nKey} />
        </Badge>
      );
    }

    return (
      <Badge variant={statusTrans.variant} {...props} ref={ref}>
        <Trans i18nKey={statusTrans.i18nKey} />
      </Badge>
    );
  },
);

/**
 * A component that displays the status of an order.
 */
const OrderStatus = Object.assign(OrderStatusInternal, {
  useOrderStatusTrans,
  unexpected,
  styles,
  isSupportOrderStatus,
  Badge: OrderStatusBadge,
});

assignDisplayName(OrderStatus, "OrderStatus");

export { OrderStatus };
