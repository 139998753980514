import {
  CloseOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { isInvalidDate } from "@chatbotgang/etude/pitch-shifter/date";
import { fc } from "@chatbotgang/etude/react/fc";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { parseJson } from "@crescendolab/parse-json";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isSameDay, secondsToMilliseconds } from "date-fns";
import type { ElementRef, FC, ReactNode } from "react";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Avatar } from "@/components/Avatar";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Divider } from "@/components/Divider";
import { LINEFlexMessage } from "@/components/LINEFlexMessage";
import { FilePreview } from "@/components/MessagePreview/FilePreview";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import {
  useFormatDateTime,
  useGetIntlDateTimeFormatter,
  useTimeLabelFormat,
} from "@/resources/datetime";
import { GroupMemberAvatarById } from "@/resources/groupMember/GroupMemberAvatarById";
import { GroupMemberNameById } from "@/resources/groupMember/GroupMemberNameById";
import { getMemberDisplayName } from "@/resources/member/displayName";
import { isDeleted } from "@/resources/message/isDeleted";
import { isFileMetadata, useGetMedialUrl } from "@/resources/message/utils";
import { UserNameById } from "@/resources/user/UserNameById";
import { IgStoryMention } from "@/routes/Chat/ui/ChatPanel/History/Messages/IgStoryMention";
import { Img } from "@/routes/Chat/ui/ChatPanel/History/Messages/Img";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { More } from "@/routes/Chat/ui/ChatPanel/History/Messages/More";
import { Pinned } from "@/routes/Chat/ui/ChatPanel/History/Messages/Pinned";
import { TextBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/TextBubble";
import { TextMessage } from "@/routes/Chat/ui/ChatPanel/History/Messages/TextMessage";
import { VideoBubble } from "@/routes/Chat/ui/ChatPanel/History/Messages/VideoBubble";
import type { ExpectedSendingMessagesError } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { useJumpToMessageController } from "@/routes/Chat/ui/jumpToMessage";
import { defineStyles } from "@/shared/emotion";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";

const styles = defineStyles({
  innerMessageWrapper: css({
    display: "flex",
    width: "100%",
    gap: "8px",
  }),
  memberInnerMessageWrapper: css({
    flexDirection: "row-reverse",
  }),
  messageContent: css({
    display: "flex",
    flexDirection: "column",
    marginLeft: "32px",
    gap: "8px",
  }),
  userMessageContent: css([
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginRight: "32px",
      gap: "8px",
    },
    emotionMedia(
      String.raw,
      "<=mobile",
      (css) => css`
        margin-right: 24px;
      `,
    ),
  ]),
  /**
   * Design: [Figma](https://www.figma.com/design/mx37wzAD7RmXI0FPiQfxST/Inbox?node-id=991-116967&m=dev)
   */
  systemMessage: css({
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    textWrap: ["balance", "pretty"],
    paddingBlock: 4,
    paddingInline: 12,
    borderRadius: 22,
    color: theme.colors.neutral007,
    background: theme.colors.neutral001,
    fontSize: "0.75rem",
    lineHeight: "normal",
    maxWidth: "50em",
    minHeight: 16,
  }),
  messageBox: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
    gap: 4,
  }),
  rightMessageBox: css({
    alignItems: "flex-end",
  }),
  time: css({
    display: "flex",
    gap: "0.25rem",
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
  }),
  userNameAvatar: css({
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
  divider: css({
    marginInline: 0,
    height: "auto",
    top: 0,
  }),
});

const ActiveChannelName = fc(function ActiveChannelName() {
  const channel = memberQueriesContext.useMemberChannel();
  return <div>{channel.name}</div>;
});

const errorToReactNodeMap: Record<ExpectedSendingMessagesError, ReactNode> = {
  FB_EXCEED_MESSAGE_WINDOW: (
    <Trans i18nKey="chat.error.exceedFbMessageWindow" />
  ),
  FB_TOKEN_EXPIRE: <Trans i18nKey="chat.error.fbTokenExpired" />,
  REMOTE_LINE_CLIENT_ERROR: <Trans i18nKey="common.apiError.serverError" />,
  MESSAGE_NOT_SEND: <Trans i18nKey="common.apiError.serverError" />,
  REMOTE_LINE_REACH_BUDGET_LIMIT: (
    <Trans i18nKey="chat.error.reachMonthlyLimit" />
  ),
  REMOTE_PROCESS_ERROR: <Trans i18nKey="common.apiError.serverError" />,
  REMOTE_LINE_NG_SENDER_NAME: <Trans i18nKey="chat.error.lineNgSenderName" />,
};

/**
 * Load content only when it is visible.
 *
 * 1. Prevent unnecessary loading of content when the user is not looking at it.
 * 2. Prevent the height of the page from changing when the content is loaded to make scroll position stable.
 */
const LazyMessage = fc<{
  children: ReactNode;
}>(function LazyMessage({ children }) {
  const messagesContext = messageUtil.useMessages();
  const isMessagesLoading = messagesContext.isLoading;
  const [active, setActive] = useSwitch(false);
  const { inView, ref: inViewRef } = useInView();
  useEffect(
    function delayAnActive() {
      if (!inView) return;
      if (isMessagesLoading) return;
      if (active) return;
      /**
       * If the content is in view over the threshold, load the content.
       * The delay is mainly to prevent stopping the smooth while `scrollTo` is
       * triggered because the height of the page changes.
       */
      const timeout = setTimeout(() => {
        setActive.on();
      }, 300);
      return function cleanup() {
        clearTimeout(timeout);
      };
    },
    [active, inView, isMessagesLoading, setActive],
  );
  return active ? (
    <div>{children}</div>
  ) : (
    <div ref={inViewRef} style={{ height: "10em", width: "100%" }} />
  );
});
const SendingMessageStatus = fc(function SendingMessageStatus({
  message,
}: {
  message: CantataTypes["MessageDetail"];
}) {
  const sendingMessagesController = useSendingMessagesController();
  const request = sendingMessagesController
    .useStore((state) => state.requests)
    .find((request) =>
      request.messages.some((m) => m.body.uuid === message.uuid),
    );
  const messageInSendingMessages = request?.messages.find(
    (m) => m.body.uuid === message.uuid,
  );
  const retry = useHandler(function retry() {
    if (!request) return;
    sendingMessagesController.updateRequest(request.sendingMessageId, (req) =>
      define<typeof req>({
        ...req,
        messages: req.messages.map((m) => ({
          ...m,
          error: undefined,
        })),
        status: "pending",
      }),
    );
  });
  const remove = useHandler(function remove() {
    if (!request) return;
    sendingMessagesController.removeRequest(request.sendingMessageId);
  });
  /**
   * Because the message
   */
  return (
    /**
     * Use the same style as the timestamp in the same position.
     */ <div css={styles.time}>
      {!request || !messageInSendingMessages ? (
        <Alert
          css={css`
            container-type: normal;

            & .ant-alert-action {
              align-self: center;
            }
          `}
          type="error"
          message={
            !request
              ? "Request not found"
              : !messageInSendingMessages
                ? "Message not found"
                : "Unexpected error"
          }
          action={
            <NarrowIconButton
              size="small"
              icon={<ReloadOutlined />}
              onClick={retry}
            />
          }
        />
      ) : request.status === "loading" ? (
        <LoadingOutlined />
      ) : request.status === "error" ? (
        <Alert
          css={css`
            container-type: normal;

            & .ant-alert-action {
              display: flex;
              align-items: center;
              align-self: center;
              gap: 0.5em;
            }
          `}
          type="error"
          message={
            messageInSendingMessages.error instanceof Error
              ? messageInSendingMessages.error.message
              : !messageInSendingMessages.error ||
                  !(messageInSendingMessages.error in errorToReactNodeMap)
                ? inspectMessage`Unexpected error: ${messageInSendingMessages.error}`
                : errorToReactNodeMap[messageInSendingMessages.error]
          }
          action={
            <Fragment>
              <NarrowIconButton
                size="small"
                icon={<ReloadOutlined />}
                onClick={retry}
              />
              <NarrowIconButton
                size="small"
                icon={<CloseOutlined />}
                onClick={remove}
              />
            </Fragment>
          }
        />
      ) : (
        (() => {
          /**
           * Rest of the cases.
           */
          request.status satisfies "pending";
          return null;
        })()
      )}
    </div>
  );
});

const isSame = (
  a: CantataTypes["MessageDetail"],
  b: CantataTypes["MessageDetail"],
  key: keyof CantataTypes["MessageDetail"],
) => {
  return a[key] === b[key];
};

const isSendAtSameDay = (
  a: CantataTypes["MessageDetail"],
  b: CantataTypes["MessageDetail"],
) => {
  /**
   * We use several message[index - 1] in this function, so we need to check if it's undefined.
   * We'll enable `noUncheckedIndexedAccess` in the future.
   *
   * @see https://www.typescriptlang.org/tsconfig#noUncheckedIndexedAccess
   */
  return !a || !b || isSameDay(a.createdAt, b.createdAt);
};

const shouldNotRenderAvatar = (
  prev: CantataTypes["MessageDetail"],
  next: CantataTypes["MessageDetail"],
) => {
  const sameSentDay = isSendAtSameDay(next, prev);
  const isSomeSending =
    isInvalidDate(next.createdAt) || isInvalidDate(prev.createdAt);
  const sameSenderType = isSame(next, prev, "senderType");
  const isSameSpeaker = isSame(next, prev, "speakerId");

  return (sameSentDay || isSomeSending) && sameSenderType && isSameSpeaker;
};

const animateDuration = secondsToMilliseconds(0.5);

const MemberAvatarAndName: FC = (() => {
  const styles = defineStyles({
    root: css({
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      fontSize: "0.875rem",
    }),
  });

  return function MemberAvatarAndName() {
    const member = memberQueriesContext.useMember();
    const message = messageUtil.useMessage();

    if (member.type === "line_group" && message.speakerId !== null) {
      return (
        <div css={styles.root}>
          <GroupMemberAvatarById
            groupId={member.id}
            memberId={message.speakerId}
            size={24}
          />
          <GroupMemberNameById
            groupId={member.id}
            memberId={message.speakerId}
          />
        </div>
      );
    }

    return (
      <div css={styles.root}>
        <Avatar size={24} src={member.avatar} />
        <div>{getMemberDisplayName(member)}</div>
      </div>
    );
  };
})();

const DateLabel: FC = () => {
  const message = messageUtil.useMessage();
  const formatTimeLabel = useTimeLabelFormat();
  return (
    <span css={styles.systemMessage}>{formatTimeLabel(message.createdAt)}</span>
  );
};

const SystemMessageLabel = forwardRef<
  ElementRef<"span">,
  Omit<ComponentProps<"span">, "children">
>((props, ref) => {
  const message = messageUtil.useMessage();
  const getIntlDateTimeFormatter = useGetIntlDateTimeFormatter();
  const formattedCreatedAt = useMemo(
    function formatTime() {
      return getIntlDateTimeFormatter({
        hour: "numeric",
        minute: "numeric",
      }).format(message.createdAt);
    },
    [getIntlDateTimeFormatter, message.createdAt],
  );

  return (
    <span
      css={styles.systemMessage}
      // Do not remove this. This is for all "jump to" features.
      {...(Number.isNaN(message.id)
        ? null
        : {
            "data-message-id": message.id,
          })}
      {...props}
      ref={ref}
    >
      {formattedCreatedAt} {message.text}
    </span>
  );
});

const MessageBubble: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const showDeletedMessageFallback = useFeatureFlag("deletedMessageFallback");
  const getMedialUrl = useGetMedialUrl();
  const member = memberQueriesContext.useMember();
  const messagesContext = messageUtil.useMessages();
  const message = messageUtil.useMessage();
  const currentIndex = messagesContext.messages.findIndex(
    (m) => m.id === message.id,
  );
  const previousMessage: typeof message | undefined =
    messagesContext.messages[currentIndex - 1];
  const isSendingMessage = isInvalidDate(message.createdAt);
  const isRightSide =
    messageUtil.isBotMessage(message) || messageUtil.isUserMessage(message);
  const formatMessageTimestamp = useFormatDateTime();

  const shouldRenderTimeLabel = useMemo(() => {
    if (isInvalidDate(message.createdAt)) return false;
    if (!previousMessage) return true;
    return !isSendAtSameDay(message, previousMessage);
  }, [message, previousMessage]);

  const shouldRenderAvatar = useMemo(() => {
    return !previousMessage || !shouldNotRenderAvatar(previousMessage, message);
  }, [previousMessage, message]);

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const jumpToMessageController = useJumpToMessageController();
  const jumpToMessageTarget = jumpToMessageController.useTarget();

  useEffect(
    function shakeIfJumped() {
      if (!jumpToMessageTarget) return;
      if (jumpToMessageTarget.message.id !== message.id) return;
      if (!messageBoxRef.current) return;
      const messageBox = messageBoxRef.current;
      const animate = messageBox.animate(
        [
          { translate: "0" },
          { translate: "-10px" },
          { translate: "10px" },
          { translate: "0" },
        ],
        {
          duration: animateDuration,
          easing: "cubic-bezier(0.68, -0.55, 0.27, 1.55)",
          iterations: 1,
        },
      );
      return function cleanup() {
        animate.cancel();
      };
    },
    [jumpToMessageTarget, message.id],
  );

  if (message.type === "text" && messageUtil.isSystemMessage(message)) {
    return (
      <>
        {!shouldRenderTimeLabel ? null : <DateLabel />}
        <SystemMessageLabel />
      </>
    );
  }

  let inner: JSX.Element | null = null;

  if (showDeletedMessageFallback && isDeleted(message)) {
    inner = (
      <TextBubble messageType="caution">
        <Trans i18nKey="resource.message.deleted.fallbackMessage" />
      </TextBubble>
    );
  } else if (message.type === "text") {
    inner = <TextMessage message={message} isRightSide={isRightSide} />;
  } else if (message.type === "line_flex") {
    inner = (
      <LazyMessage>
        <LINEFlexMessage
          rowIndex={0}
          message={
            parseJson(message.lineFlexContent ?? "") as ComponentProps<
              typeof LINEFlexMessage
            >["message"]
          }
        />
      </LazyMessage>
    );
  } else if (message.type === "image") {
    inner = (
      <LazyMessage>
        <Img />
      </LazyMessage>
    );
  } else if (message.type === "video") {
    inner = (
      <LazyMessage>
        <VideoBubble />
      </LazyMessage>
    );
  } else if (message.type === "file") {
    const fileMetadata = message.metadata;
    if (!isFileMetadata(fileMetadata)) return;

    inner = !fileMetadata ? null : (
      <FilePreview
        downloadUrl={message.originUrl ?? ""}
        fileName={fileMetadata.filename}
        fileSizePrefix={fileMetadata.filesizePrefix}
        fileSize={fileMetadata.filesizeBytes}
        expiryPrefix={fileMetadata.expirationDatePrefix}
        expiry={fileMetadata.downloadExpirationDate}
        small={true}
      />
    );
  } else if (message.type === "audio") {
    inner = <audio src={getMedialUrl(message)} controls />;
  } else if (message.type === "ig_story_mention") {
    if (message.senderType !== "member") {
      // Do nothing, disabled for now.
    }
    inner = <IgStoryMention />;
  } else {
    message.type satisfies never;
    throw new Error(inspectMessage`Unexpected message type: ${message.type}`);
  }

  inner = !inner ? null : (
    <div
      ref={messageBoxRef}
      // Do not remove this. This is for all "jump to" features.
      {...(Number.isNaN(message.id)
        ? null
        : {
            "data-message-id": message.id,
          })}
      css={css([
        styles.innerMessageWrapper,
        !isRightSide ? null : styles.memberInnerMessageWrapper,
      ])}
    >
      {inner}
      {!messagesContext.showMoreButton ? null : (
        <div>
          <More />
        </div>
      )}
    </div>
  );

  return (
    <>
      {!shouldRenderTimeLabel ? null : <DateLabel />}
      <div
        css={css([styles.messageBox, isRightSide && styles.rightMessageBox])}
      >
        {isRightSide && shouldRenderAvatar && (
          <div css={styles.userNameAvatar}>
            {message.senderType === "bot" ||
            message.userId === null ||
            !message.userName ? (
              <ActiveChannelName />
            ) : (
              <div>
                <UserNameById userId={message.userId} />
              </div>
            )}
            <Avatar
              size={24}
              src={
                message.senderType === "bot"
                  ? orgQueriesData.org.logo ?? ""
                  : message.userAvatar ?? ""
              }
            />
          </div>
        )}
        {!isRightSide ? (
          <>
            {!shouldRenderAvatar ? null : <MemberAvatarAndName />}
            <div css={styles.messageContent}>
              {inner}
              <div css={styles.time}>
                {formatMessageTimestamp(message.createdAt)}
                <Pinned />
              </div>
            </div>
          </>
        ) : (
          <div css={styles.userMessageContent}>
            {inner}
            {isSendingMessage ? (
              <SendingMessageStatus message={message} />
            ) : (
              <div css={styles.time}>
                {member.type !== "wccs" || !message.read ? null : (
                  <>
                    <Trans i18nKey="chat.message.read" />
                    <Divider type="vertical" css={styles.divider} />
                  </>
                )}
                {formatMessageTimestamp(message.createdAt)}
                <Pinned />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const Message: FC<{ message: CantataTypes["MessageDetail"] }> = ({
  message,
}) => {
  return (
    <messageUtil.MessageProvider value={message}>
      <MessageBubble />
    </messageUtil.MessageProvider>
  );
};

export { Message };
