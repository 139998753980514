import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";
import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { NotFoundContent } from "@/components/Empty/NotFoundContent";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { Order } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Orders/Order";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Orders: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  header: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral009,
  }),
  orderList: css({
    display: "flex",
    flexDirection: "column",
    gap: "inherit",
    margin: 0,
  }),
  reachMax: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
    textAlign: "center",
  }),
});

namespace Orders {
  export type Props = {
    orders: Array<CantataTypes["Order"]>;
  };
}

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div css={styles.Orders}>
      <div css={styles.header}>
        <Trans i18nKey="chat.memberProfilePanel.membership.order.content.title" />
      </div>
      {children}
    </div>
  );
};

const CLIP_SIZE = 2;
const MAX_SIZE = 10;

const Orders: FC<Orders.Props> = ({ orders }) => {
  const [open, toggle] = useSwitch(false);
  const itemsToShow = useMemo(
    () => (open ? orders : orders.slice(0, CLIP_SIZE)),
    [open, orders],
  );
  const member = memberQueriesContext.useMember();
  const trackOrderDetailButtonClick = useHandler(
    function trackOrderDetailButtonClick() {
      ga4Event("commerceProfileClick", {
        channelId: member.channelId,
        memberId: member.id,
        source: "orderDetailButton",
      });
    },
  );

  if (itemsToShow.length === 0) {
    return (
      <Layout>
        <NotFoundContent
          message={
            <Trans i18nKey="chat.memberProfilePanel.membership.order.noData" />
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <ul css={styles.orderList}>
        {itemsToShow.map((order) => (
          <Order
            key={order.orderId}
            order={order}
            detailButtonProps={{
              onClick: trackOrderDetailButtonClick,
            }}
          />
        ))}
      </ul>
      {!open || orders.length < MAX_SIZE ? null : (
        <span css={styles.reachMax}>
          <Trans i18nKey="chat.memberProfilePanel.membership.order.reachMax" />
        </span>
      )}
      {orders.length <= CLIP_SIZE ? null : (
        <ShowMoreButton
          expanded={open}
          onChange={open ? toggle.off : toggle.on}
        />
      )}
    </Layout>
  );
};

export { Orders };
