import { makeApi } from "@zodios/core";
import { pick } from "lodash-es";
import { z } from "zod";

import { TeamSchema, TeamUserSchema } from "../models";

const CreateUpdateRequestBodySchema = z.object({
  ...pick(TeamSchema.shape, "name", "description", "routingRule"),
  externalTeamId: TeamSchema.shape.externalTeamId.transform((value) =>
    /**
     * If `externalTeamId` is empty, set it to `null` to prevent the server
     * from storing an empty string, which could lead to conflicts.
     *
     * Asana: [[CAAC_團隊編號] 團隊編號留空時，會顯示團隊編號衝突](https://app.asana.com/0/1201173638593204/1209219071707189/f)
     */
    !value ? null : value,
  ),
  userIds: TeamUserSchema.shape.id.array(),
});

const api = makeApi([
  {
    alias: "list",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams",
    response: z.object({
      teams: z.array(TeamSchema),
    }),
  },
  {
    alias: "create",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/teams",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateUpdateRequestBodySchema,
      },
    ],
    response: TeamSchema,
  },
  {
    alias: "update",
    method: "put",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateUpdateRequestBodySchema.omit({ userIds: true }),
      },
    ],
    response: TeamSchema,
  },
  {
    alias: "deleteById",
    method: "delete",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId",
    response: z.void(),
  },
  {
    alias: "getById",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId",
    response: TeamSchema,
  },
  {
    alias: "listUsers",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/users",
    response: z.object({
      users: z.array(TeamUserSchema),
    }),
  },
  {
    alias: "addUsers",
    method: "post",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/users",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({
          userIds: z.array(z.number().int()),
        }),
      },
    ],
    response: z.object({
      users: z.array(TeamUserSchema),
    }),
  },
  {
    alias: "removeUser",
    method: "delete",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/users/:userId",
    response: z.void(),
  },
  {
    alias: "getTeamUsersCount",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/:teamId/users/count",
    response: z.number().int(),
  },
  {
    alias: "listMine",
    method: "get",
    path: "api/v1/orgs/:orgId/organization/teams/me",
    response: z.object({
      teams: z.array(TeamSchema),
    }),
  },
]);

export { api };
