import { Iso4217CurrencySchema } from "@chatbotgang/etude/zod/Iso4217CurrencySchema";
import { z } from "zod";

import { safeSchema } from "./safeSchema";

/**
 * Creates a flexible schema that can parse strings against a currency format.
 * schemas.
 *
 * ```ts
 * const result = SafeIso4217CurrencySchema.parse("TWD"); // { value: "TWD" }
 * const unexpectedCurrency = SafeIso4217CurrencySchema.parse("Yellow"); // { raw: "Yellow" }
 * ```
 */
const SafeIso4217CurrencySchema = safeSchema(
  Iso4217CurrencySchema,
  /**
   * Should always be a string
   */
  z.string(),
);

export { SafeIso4217CurrencySchema };
