import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { type ElementRef, type ReactNode, useMemo } from "react";

import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import type { CantataTypes } from "@/cantata/types";
import { CopyButton } from "@/components/Button/CopyButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { useFormatDateTime } from "@/resources/datetime";
import { YmdHmRange } from "@/resources/datetime/YmdHmRange";
import { Popover } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Popover";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Prize: css({
    display: "flex",
    padding: "12px 12px 6px",
    flexDirection: "column",
    alignSelf: "stretch",
    borderRadius: 8,
    background: theme.colors.neutral001,
    ">*": {
      width: "100%",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
  logo: css({
    fontSize: "16px",
    lineHeight: 1,
  }),
  prizeName: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral009,
  }),
  expiryDate: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  toggleButton: css({
    ".anticon": {
      color: theme.colors.neutral009,
    },
  }),
});

const prizeDetailStyle = defineStyles({
  PrizeDetail: css({
    display: "flex",
    flexDirection: "column",
    gap: 12,
    color: theme.colors.neutral009,
    width: 236,
  }),
  header: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  logo: css({
    fontSize: "24px",
    lineHeight: 1,
  }),
  name: css({
    fontSize: "0.875rem",
    fontWeight: 700,
    color: theme.colors.neutral009,
  }),
  expiryDate: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  code: css({
    display: "flex",
    gap: 4,
    fontSize: "0.75rem",
    alignItems: "center",
  }),
});

namespace Prize {
  export type Ref = ElementRef<"li">;
  export interface OwnProps {
    prize: CantataTypes["Prize"];
    detailButtonProps?: ComponentProps<typeof NarrowIconButton>;
  }
  export type Props = Overwrite<ComponentProps<"li">, OwnProps>;
}

const prizeTypeLogoMap: Record<CantataTypes["Prize"]["type"], ReactNode> = {
  "91app": <MotifIcon un-i-motif="logo-nine_one_app_logo" />,
  shopline: <MotifIcon un-i-motif="logo-shopline_logo" />,
  cyberbiz: <MotifIcon un-i-motif="logo-cyberbiz_logo" />,
  shopify: <MotifIcon un-i-motif="logo-shopify_logo" />,
  flaps: <MotifIcon un-i-motif="logo-flaps_logo" />,
};

const PrizeInternal = forwardRef<Prize.Ref, Prize.Props>(function PrizeInternal(
  { prize, detailButtonProps, ...props },
  ref,
) {
  const [open, toggle] = useSwitch(false);
  const formatDatetime = useFormatDateTime();
  const prizeEndAt = useMemo(
    function computePrizeEndAt() {
      return !prize.prizeEndAt
        ? EMPTY_STRING_PLACEHOLDER
        : !prize.prizeEndAt.isSuccess
          ? prize.prizeEndAt.raw
          : formatDatetime(prize.prizeEndAt.data);
    },
    [prize.prizeEndAt, formatDatetime],
  );
  const handleDetailButtonClick = useHandler<
    ComponentProps<typeof NarrowIconButton>["onClick"]
  >(function handleDetailButtonClick(...args) {
    toggle.on();
    detailButtonProps?.onClick?.(...args);
  });

  return (
    <li css={styles.Prize} ref={ref} {...props}>
      <div css={styles.logo}>{prizeTypeLogoMap[prize.type]}</div>
      <div css={styles.prizeName}>{prize.prizeName}</div>
      <div>
        <div css={styles.expiryDate}>
          <Trans
            i18nKey="chat.memberProfilePanel.membership.prize.expiry.label"
            values={{
              datetime: prizeEndAt,
            }}
          />
        </div>
        <Popover
          open={open}
          placement="topRight"
          closeButtonProps={{
            onClick: toggle.off,
          }}
          content={
            <div css={prizeDetailStyle.PrizeDetail}>
              <div css={prizeDetailStyle.header}>
                <div css={prizeDetailStyle.logo}>
                  {prizeTypeLogoMap[prize.type]}
                </div>
                <span css={prizeDetailStyle.name}>{prize.prizeName}</span>
                <span css={prizeDetailStyle.expiryDate}>
                  <YmdHmRange
                    startDate={prize.prizeStartAt}
                    endDate={prize.prizeEndAt}
                  />
                </span>
              </div>
              <div css={prizeDetailStyle.code}>
                <span>
                  <Trans i18nKey="chat.memberProfilePanel.membership.prize.code.label" />
                </span>
                <span>{prize.prizeCode}</span>
                <CopyButton size="small" text={prize.prizeCode} />
              </div>
            </div>
          }
        >
          <NarrowIconButton
            iconSize="small"
            css={styles.toggleButton}
            icon={<MotifIcon un-i-motif="file_view" />}
            {...detailButtonProps}
            onClick={handleDetailButtonClick}
          />
        </Popover>
      </div>
    </li>
  );
});

/**
 * Display the prize code and expiry date
 */
const Prize = Object.assign(PrizeInternal, {
  styles,
});

assignDisplayName(Prize, "Prize");

export { Prize };
