import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { PopoverProps as AntdPopoverProps } from "antd";
import { Popover as AntdPopover } from "antd";
// eslint-disable-next-line no-restricted-imports -- Get the provider for the `disabled` prop.
import { DisabledContextProvider } from "antd/es/config-provider/DisabledContext";
import type { ElementRef } from "react";

import type { FormProps } from "@/components/Form";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";

namespace Popover {
  export type Ref = ElementRef<typeof AntdPopover>;
  export type Props = AntdPopoverProps & Pick<FormProps, "disabled">;
}

/**
 * Additional props:
 * - disabled: Control the form elements under it.
 *
 * Changed props default values:
 * - arrow: `false`
 * - destroyTooltipOnHide: `true`
 */
const Popover = forwardRef<Popover.Ref, Popover.Props>(function Popover(
  { arrow = false, destroyTooltipOnHide = true, disabled, ...props },
  ref,
) {
  const mergeFormDisabled = useMergeFormDisabled();
  const mergedDisabled = mergeFormDisabled(disabled);
  return (
    <DisabledContextProvider disabled={mergedDisabled}>
      <AntdPopover
        arrow={arrow}
        destroyTooltipOnHide={destroyTooltipOnHide}
        {...props}
        ref={ref}
      />
    </DisabledContextProvider>
  );
});

assignDisplayName(Popover, "Popover");

export { Popover };
