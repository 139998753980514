import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import PQueue from "p-queue";

const pQueue = new PQueue({
  concurrency: 3,
});

function blobToDataUrlInternal(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function blobToDataUrl(blob: Blob): Promise<string> {
  const result = await pQueue.add(() => blobToDataUrlInternal(blob));
  if (!result) {
    throw new Error(
      inspectMessage`Failed to convert blob to data URL, result: ${result}, blob: ${blob}`,
    );
  }
  return result;
}

export { blobToDataUrl };
