import { css } from "@emotion/react";
import { flutterWebViewAppSdk } from "@zeffiroso/flutter-webview-app-sdk/flutterWebViewAppSdk";

import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  FlutterSdkLogs: css({
    fontSize: "0.75rem",
  }),
});

const FlutterSdkLogs: React.FC = () => {
  const { logs } = flutterWebViewAppSdk.useFlutterDebugLogsStore();
  return (
    <pre css={styles.FlutterSdkLogs}>
      <code>
        <ol>
          {logs.map((log, i) => (
            <li key={i}>{log}</li>
          ))}
        </ol>
      </code>
    </pre>
  );
};

export { FlutterSdkLogs };
