import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { define } from "@chatbotgang/etude/util/define";
import { merge } from "lodash-es";
import {
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type ReactNode,
  useMemo,
} from "react";
import type { L } from "ts-toolbelt";

import { Trans } from "@/app/i18n/Trans";
import type {
  Options,
  PruneSmallerUnitsProps,
} from "@/components/PruneSmallerUnits";
import {
  defaultComponent as PruneSmallerUnitsDefaultComponent,
  PruneSmallerUnits,
  pruneSmallerUnitsToString,
} from "@/components/PruneSmallerUnits";
import { fakeT } from "@/shared/g11n/fakeT";

const defaultComponent = PruneSmallerUnitsDefaultComponent;
type DefaultComponent = typeof defaultComponent;

/**
 * List the allowed units of a day-hour-minute-seconds duration.
 *
 * The order of the units is important. From the largest to the smallest.
 */
const allowedUnits = define<Array<string>>()(["d", "h", "m", "s"]);

type AllowedUnit = (typeof allowedUnits)[number];

const sharedDefaultOptions: Partial<Options<AllowedUnit>> = {
  units: allowedUnits,
  maxLargestUnitCount: 2,
  minLargestUnitCount: 2,
  largestUnitMaxValue: 99,
};

/**
 * We use seconds as the parameter instead of a numbers object to make it easier
 * to use inline.
 */
const secondsToNumbers: (seconds: number) => Options<AllowedUnit>["numbers"] = (
  seconds,
) => {
  let rest = Math.floor(seconds);
  const s = rest % 60;
  rest = Math.floor(rest / 60);
  const m = rest % 60;
  rest = Math.floor(rest / 60);
  const h = rest % 24;
  const d = Math.floor(rest / 24);
  return { d, h, m, s };
};

/**
 * pruneSmallerUnitsToString specialized for day-hour-minute-seconds.
 */
const dayHourMinuteSecondDisplayToString = <
  TUnit extends AllowedUnit = AllowedUnit,
>(
  ...args: [
    Omit<
      Parameters<typeof pruneSmallerUnitsToString<TUnit>>[0],
      "numbers" | "units"
    > & {
      seconds: Parameters<typeof secondsToNumbers>[0];
    },
    ...L.Tail<Parameters<typeof pruneSmallerUnitsToString<TUnit>>>,
  ]
): string => {
  const [{ seconds, ...restOptions }, ...rest] = args;
  const defaultOptions: Partial<
    Parameters<typeof pruneSmallerUnitsToString<AllowedUnit>>[0]
  > = {
    ...sharedDefaultOptions,
    /**
     * Design: [Figma](https://www.figma.com/design/2dKDXAEzNSR7s82PVYcLek/Insights?node-id=3339-13100&t=fbaRxHGeCN4DgvQp-1)
     */
    render(args, index) {
      return `${
        (index === 0 ? "" : " ") +
        (args.overflow ? `${args.largestUnitMaxValue}+` : args.currentValue)
      } ${args.unit}`;
    },
  };
  const mergedOptions: Parameters<
    typeof pruneSmallerUnitsToString<AllowedUnit>
  >[0] = merge({}, defaultOptions, restOptions, {
    numbers: secondsToNumbers(seconds),
    units: allowedUnits,
  });
  return pruneSmallerUnitsToString(mergedOptions, ...rest);
};

const seed = random();
const classNamePrefix = `day-hour-minute-second-display-${seed}-` as const;
const classNameRecord = {
  item: `${classNamePrefix}item`,
  value: `${classNamePrefix}value`,
  unit: `${classNamePrefix}unit`,
} satisfies Record<string, `${typeof classNamePrefix}${string}`>;

type DayHourMinuteSecondDisplayProps<
  TRootComponent extends ElementType = DefaultComponent,
> = Omit<
  PruneSmallerUnitsProps<AllowedUnit, TRootComponent>,
  "numbers" | "units"
> & {
  seconds: Parameters<typeof secondsToNumbers>[0];
};

interface DayHourMinuteSecondDisplayType {
  <TRootComponent extends ElementType = DefaultComponent>(
    props: DayHourMinuteSecondDisplayProps<TRootComponent> & {
      component: TRootComponent;
    },
    ref?: ElementRef<TRootComponent>,
  ): ReactNode;
  <TRootComponent extends ElementType = DefaultComponent>(
    props: Omit<DayHourMinuteSecondDisplayProps<TRootComponent>, "component">,
    ref?: ElementRef<DefaultComponent>,
  ): ReactNode;
}

const t = fakeT;
const unitTranslationMap: Record<AllowedUnit, string> = {
  d: t("components.duration.DayHourMinuteSecondDisplay.days"),
  h: t("components.duration.DayHourMinuteSecondDisplay.hours"),
  m: t("components.duration.DayHourMinuteSecondDisplay.minutes"),
  s: t("components.duration.DayHourMinuteSecondDisplay.seconds"),
};

/**
 * PruneSmallerUnits component specialized for day-hour-minute-seconds.
 */
const DayHourMinuteSecondDisplay: DayHourMinuteSecondDisplayType = forwardRef(
  function DayHourMinuteSecondDisplay<
    TRootComponent extends ElementType = DefaultComponent,
  >(
    { seconds, ...props }: DayHourMinuteSecondDisplayProps<TRootComponent>,
    ref: ForwardedRef<ElementRef<TRootComponent>>,
  ) {
    const defaultProps = useMemo<
      Partial<PruneSmallerUnitsProps<AllowedUnit, ElementType>>
    >(
      () => ({
        ...sharedDefaultOptions,
        render(args) {
          return (
            <div className={classNameRecord.item}>
              <div className={classNameRecord.value}>
                {args.overflow
                  ? `${args.largestUnitMaxValue}+`
                  : args.currentValue}
              </div>
              <div className={classNameRecord.unit}>
                <Trans i18nKey={unitTranslationMap[args.unit]} />
              </div>
            </div>
          );
        },
      }),
      [],
    );
    const numbers = useMemo(() => secondsToNumbers(seconds), [seconds]);
    const mergedProps = useMemo<
      PruneSmallerUnitsProps<AllowedUnit, ElementType>
    >(
      () => merge({}, defaultProps, props, { numbers, units: allowedUnits }),
      [defaultProps, numbers, props],
    );
    return (
      <PruneSmallerUnits<AllowedUnit, ElementType> {...mergedProps} ref={ref} />
    );
  },
);

assignDisplayName(DayHourMinuteSecondDisplay, "DayHourMinuteSecondDisplay");

export {
  allowedUnits,
  classNameRecord,
  DayHourMinuteSecondDisplay,
  dayHourMinuteSecondDisplayToString,
  defaultComponent,
};

export type {
  AllowedUnit,
  DayHourMinuteSecondDisplayProps,
  DayHourMinuteSecondDisplayType,
  Options,
};
