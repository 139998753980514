import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { type FC, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { objectEntries } from "tsafe";
import { z } from "zod";

import { Trans } from "@/app/i18n/Trans";
import { Link } from "@/components/Link";
import { compileToString } from "@/router/utils/compileTo";
import { defineStyles } from "@/shared/emotion";
import { useLanguage } from "@/shared/hooks/useLanguage";

const styles = defineStyles({
  SiteSelectorEntry: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 8,
    color: theme.colors.neutral010,
    fontSize: "0.875rem",
  }),
});

const sitesSchema = z.enum(["production", "jp", "staging", "other", "local"]);

const siteToHostRecord = {
  production: "caac.cresclab.com",
  jp: "caac.jp.cresclab.com",
  staging: "caacstaging.cresclab.com",
} satisfies Partial<Record<z.infer<typeof sitesSchema>, string>>;

const ProductionSiteName: FC = () => {
  const lang = useLanguage();
  const { t } = useTranslation();

  const siteNames = [
    t("login.siteSelectorEntry.siteName.th"),
    t("login.siteSelectorEntry.siteName.tw"),
  ];

  const isThai = lang === "th" || navigator.language === "th";

  return isThai ? siteNames.join(", ") : siteNames.reverse().join(", ");
};

const siteToNameDisplayRecord = {
  production: <ProductionSiteName />,
  jp: <Trans i18nKey="login.siteSelectorEntry.siteName.jp" />,
  staging: <>Staging</>,
  local: <>Local</>,
  other: <>Other</>,
} satisfies Record<z.infer<typeof sitesSchema>, ReactNode>;

const currentSite: z.infer<typeof sitesSchema> = (() => {
  const siteToHostRecordEntries = objectEntries(siteToHostRecord);
  for (const [site, host] of siteToHostRecordEntries) {
    if (host.includes(window.location.host)) {
      return site;
    }
  }
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname.endsWith(".localhost")
  ) {
    return "local";
  }
  return "other";
})();

namespace SiteSelectorEntry {
  export interface OwnProps {}
  export type Props = Overwrite<ComponentProps<"div">, OwnProps>;
}

const SiteSelectorEntryInternal: FC<SiteSelectorEntry.Props> = (props) => {
  const siteDisplayName: ReactNode = useMemo(() => {
    return currentSite in siteToNameDisplayRecord ? (
      siteToNameDisplayRecord[currentSite]
    ) : (
      <>{window.location.hostname}</>
    );
  }, []);
  return (
    <div css={styles.SiteSelectorEntry} {...props}>
      <div>
        <Trans
          i18nKey="login.siteSelectorEntry.current"
          components={{
            siteDisplayName,
          }}
        />
      </div>
      <Link
        to={compileToString({
          pathname: "/site-selector",
          ...(() => {
            const searchParams = new URLSearchParams({
              targetPathAndHash:
                window.location.pathname +
                window.location.search +
                window.location.hash,
            });
            const result = searchParams.toString();
            if (result === "" || result === "/") return null;
            return {
              search: `?${result}`,
            };
          })(),
        })}
      >
        <Trans i18nKey="login.siteSelectorEntry.change" />
      </Link>
    </div>
  );
};

const SiteSelectorEntry = Object.assign(SiteSelectorEntryInternal, {
  sitesSchema,
  siteToHostRecord,
  siteToNameDisplayRecord,
});

export { SiteSelectorEntry };
