import { CheckOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { random } from "@chatbotgang/etude/string/random";
import { css, type CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementRef, ElementType, ForwardedRef, ReactNode } from "react";
import { useMemo } from "react";

import {
  cssFlexInheritAndFill,
  cssOneLine,
  defineStyles,
} from "@/shared/emotion";

const Selected = styled(CheckOutlined)`
  color: ${theme.colors.blue006};
`;

const cssVariablePrefix = `--item-with-icon-${random()}-`;
const cssVariableGap = `${cssVariablePrefix}gap`;

const defaultComponent = "div";

type DefaultComponent = typeof defaultComponent;

/**
 * List the own props of the component.
 */
interface ItemWithIconOwnProps {
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The icon to display at the start.
   */
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  gap?: CSSObject["gap"];
  selected?: boolean;
  startBlockProps?: ComponentProps<"div">;
  mainBlockProps?: ComponentProps<"div">;
  endBlockProps?: ComponentProps<"div">;
  /**
   * The component used for the root node.
   */
  component?: ElementType;
}

interface ItemWithIconTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & ItemWithIconOwnProps;
  defaultComponent: RootComponent;
}

type ItemWithIconProps<
  RootComponent extends ElementType = ItemWithIconTypeMap["defaultComponent"],
  // eslint-disable-next-line ts/ban-types -- inherit
  AdditionalProps = {},
> = ItemWithIconOwnProps &
  OverrideProps<
    ItemWithIconTypeMap<AdditionalProps, RootComponent>,
    RootComponent
  > & {
    component?: ElementType;
  };

const styles = defineStyles({
  root: css([
    cssFlexInheritAndFill,
    {
      flexDirection: "row",
      alignItems: "center",
    },
  ]),
  start: css({
    display: "flex",
    alignItems: "center",
  }),
  main: css([
    cssOneLine,
    {
      flex: 1,
    },
  ]),
  end: css({
    display: "flex",
    alignItems: "center",
  }),
});

/**
 * An overridable component.
 */
const ItemWithIcon: OverridableComponent<ItemWithIconTypeMap> = forwardRef(
  function ItemWithIcon(
    {
      children,
      startIcon,
      endIcon,
      gap = 8,
      selected,
      startBlockProps,
      mainBlockProps,
      endBlockProps,
      component: Component = defaultComponent,
      ...props
    }: ItemWithIconProps,
    ref: ForwardedRef<ElementRef<typeof Component>>,
  ) {
    const computedGap = useMemo(
      () => (typeof gap === "number" ? `${gap}px` : gap),
      [gap],
    );
    const cssRoot = useMemo(
      () =>
        css([
          styles.root,
          {
            [cssVariableGap]: computedGap,
            gap: `var(${cssVariableGap})`,
            "--item-with-icon-gap": computedGap,
          },
        ]),
      [computedGap],
    );
    const cssStart = useMemo(
      () => css([styles.start, startBlockProps?.css]),
      [startBlockProps?.css],
    );
    const cssMain = useMemo(
      () => css([styles.main, mainBlockProps?.css]),
      [mainBlockProps?.css],
    );
    const cssEnd = useMemo(
      () => css([styles.end, endBlockProps?.css]),
      [endBlockProps?.css],
    );
    const computedEndIcon = useMemo(
      () => (selected ? <Selected /> : endIcon),
      [endIcon, selected],
    );
    return (
      <Component {...props} css={cssRoot} ref={ref}>
        {!startIcon ? null : (
          <div {...startBlockProps} css={cssStart}>
            {startIcon}
          </div>
        )}
        <div {...mainBlockProps} css={cssMain}>
          {children}
        </div>
        {!endIcon ? null : (
          <div {...endBlockProps} css={cssEnd}>
            {computedEndIcon}
          </div>
        )}
      </Component>
    );
  },
) as OverridableComponent<ItemWithIconTypeMap>;

export { defaultComponent, ItemWithIcon };

export type { ItemWithIconOwnProps, ItemWithIconProps, ItemWithIconTypeMap };
