import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { z } from "zod";

import { Trans } from "@/app/i18n/Trans";
import { useOpenZendesk } from "@/app/zendesk";
import { cantata } from "@/cantata";
import { Badge } from "@/components/Badge";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import type { TabsProps } from "@/components/Tabs";
import { Tabs } from "@/components/Tabs";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { BeforeIntegrate } from "@/routes/Chat/ui/MemberProfilePanel/Membership/BeforeIntegrate";
import { Member } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Member";
import { NotBound } from "@/routes/Chat/ui/MemberProfilePanel/Membership/NotBound";
import { Orders } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Orders";
import { Prizes } from "@/routes/Chat/ui/MemberProfilePanel/Membership/Prizes";
import { SyncMembership } from "@/routes/Chat/ui/MemberProfilePanel/Membership/SyncMembership";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  Tabs: css({
    ".ant-tabs-tab": {
      padding: "8px 0 4px",
    },
    ".ant-tabs-tab + .ant-tabs-tab": {
      marginLeft: "12px",
    },
    ".ant-tabs-nav": {
      marginBottom: "8px",
    },
  }),
  Badge: css({
    ".ant-tabs-tab.ant-tabs-tab-active &": {
      backgroundColor: theme.colors.blue005,
      color: theme.colors.white,
    },
  }),
});

const TabBadge: FC<Omit<ComponentProps<typeof Badge>, "$variant">> = (
  props,
) => {
  return <Badge {...props} variant="plain" css={styles.Badge} />;
};

const TabKeySchema = z.enum(["memberTab", "ordersTab", "prizesTab"]);

namespace Membership {
  export interface OwnProps {}
  export type Props = Overwrite<Omit<TabsProps, "items">, OwnProps>;
}

const MembershipInternal: FC<Membership.Props> = (props) => {
  const orgData = orgQueriesContext.useData();
  const ecType = orgData.org.ecType;
  const member = memberQueriesContext.useMember();
  const isEcPlatformIntegrated = ecType !== null;
  const isMemberBound = Boolean(member.customId);
  const hasCommerceData = isEcPlatformIntegrated && isMemberBound;
  const prizesQuery = cantata.commerceProfile.usePrizes(
    {
      params: {
        orgId: orgData.org.id,
        memberId: member.id,
      },
    },
    {
      enabled: hasCommerceData,
      suspense: hasCommerceData,
      useErrorBoundary: hasCommerceData,
    },
  );

  const prizes = useMemo(
    () => (!prizesQuery.isSuccess ? [] : prizesQuery.data),
    [prizesQuery.data, prizesQuery.isSuccess],
  );

  const ordersQuery = cantata.commerceProfile.useOrders(
    {
      params: {
        orgId: orgData.org.id,
        memberId: member.id,
      },
    },
    {
      enabled: hasCommerceData,
      suspense: hasCommerceData,
      useErrorBoundary: hasCommerceData,
    },
  );

  const orders = useMemo(
    () => (!ordersQuery.isSuccess ? [] : ordersQuery.data),
    [ordersQuery.data, ordersQuery.isSuccess],
  );

  const openZendesk = useOpenZendesk();
  const handleContactUsClick = useHandler(function handleContactUsClick() {
    openZendesk();
    ga4Event("commerceProfileClick", {
      channelId: member.channelId,
      memberId: member.id,
      source: "contactUsButton",
    });
  });

  const handleTabChange = useHandler<ComponentProps<typeof Tabs>["onChange"]>(
    function handleTabChange(key) {
      const result = TabKeySchema.safeParse(key);
      if (!result.success) return;
      ga4Event("commerceProfileClick", {
        channelId: member.channelId,
        memberId: member.id,
        source: result.data,
      });
    },
  );

  const items = useMemo<TabsProps["items"]>(
    function declareItems() {
      const items: TabsProps["items"] = [
        {
          key: TabKeySchema.enum.memberTab,
          label: (
            <Trans i18nKey="chat.memberProfilePanel.items.membership.tabs.member.label" />
          ),
          children: <Member />,
        },
        {
          key: TabKeySchema.enum.ordersTab,
          label: (
            <ItemWithIcon
              gap={4}
              endIcon={
                !ordersQuery.isSuccess ? null : (
                  <TabBadge>{orders.length}</TabBadge>
                )
              }
            >
              <Trans i18nKey="chat.memberProfilePanel.items.membership.tabs.orders.label" />
            </ItemWithIcon>
          ),
          children: !isEcPlatformIntegrated ? (
            <BeforeIntegrate
              contactUsButtonProps={{
                onClick: handleContactUsClick,
              }}
            />
          ) : !isMemberBound ? (
            <NotBound />
          ) : !ordersQuery.isSuccess ? null : (
            <Orders orders={orders} />
          ),
        },
        {
          key: TabKeySchema.enum.prizesTab,
          label: (
            <ItemWithIcon
              gap={4}
              endIcon={
                !prizesQuery.isSuccess ? null : (
                  <TabBadge>{prizes.length}</TabBadge>
                )
              }
            >
              <Trans i18nKey="chat.memberProfilePanel.items.membership.tabs.prizes.label" />
            </ItemWithIcon>
          ),
          children: !isEcPlatformIntegrated ? (
            <BeforeIntegrate
              contactUsButtonProps={{
                onClick: handleContactUsClick,
              }}
            />
          ) : !isMemberBound ? (
            <NotBound />
          ) : !prizesQuery.isSuccess ? null : (
            <Prizes prizes={prizes} />
          ),
        },
      ];
      return items;
    },
    [
      handleContactUsClick,
      isEcPlatformIntegrated,
      isMemberBound,
      orders,
      ordersQuery.isSuccess,
      prizes,
      prizesQuery.isSuccess,
    ],
  );
  return (
    <Tabs
      css={styles.Tabs}
      {...props}
      items={items}
      onChange={handleTabChange}
    />
  );
};

const Wrapped: FC<Membership.Props> = (props) => {
  return (
    <ErrorBoundary.Alert>
      <MembershipInternal {...props} />
      <SyncMembership />
    </ErrorBoundary.Alert>
  );
};

/**
 * Present the membership information of a contact.
 */
const Membership = Object.assign(Wrapped, {
  styles,
});

assignDisplayName(Membership, "Membership");

export { Membership };
