import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useMemo } from "react";
import { objectEntries, objectFromEntries } from "tsafe";

import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";
import { PermissionChecker } from "@/components/PermissionChecker";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { YmdHmRange } from "@/resources/datetime/YmdHmRange";
import { SafeCurrencyNumberFormat } from "@/routes/Chat/ui/MemberProfilePanel/Membership/SafeCurrencyNumberFormat";
import { AccountManagerField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/AccountManagerField";
import { MemberLevelField } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/fields/MemberLevelField";
import { FormProvider } from "@/routes/Chat/ui/MemberProfilePanel/ProfilePanel/FormProvider";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  EcProfile: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    color: theme.colors.neutral009,
  }),
  label: css({
    fontSize: "0.75rem",
    color: theme.colors.neutral007,
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  content: css({
    fontSize: "1rem",
    fontWeight: 700,
  }),
  separator: css({
    color: theme.colors.neutral003,
  }),
  row: css({
    display: "flex",
    gap: 8,
    ">*": {
      flex: 1,
    },
  }),
  memberLevel: css({
    fontSize: "0.875rem",
    fontWeight: 400,
  }),
});

const useProfileQueries = () => {
  const orgData = orgQueriesContext.useData();
  const member = memberQueriesContext.useMember();
  const profile = cantata.commerceProfile.useProfile(
    {
      params: {
        orgId: orgData.org.id,
        memberId: member.id,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
  return { profile };
};

const ProfileQueriesContext =
  createQueriesContext<ReturnType<typeof useProfileQueries>>();

const useQueries = ProfileQueriesContext.useData;

const Field: FC<{ label: ReactNode; content: ReactNode }> = ({
  label,
  content,
}) => (
  <div css={styles.field}>
    <label css={styles.label}>{label}</label>
    <div css={styles.content}>{content}</div>
  </div>
);

const MemberLevel: FC = () => {
  const { profile } = useQueries();
  const orgData = orgQueriesContext.useData();
  const ecType = orgData.org.ecType;
  const member = memberQueriesContext.useMember();

  return (
    <Field
      label={
        <Trans i18nKey="chat.memberProfilePanel.membership.member.memberLevel" />
      }
      content={
        <span css={styles.memberLevel}>
          {ecType === "shopify" ? (
            <PermissionChecker
              to="editMemberProfile"
              fallback={<>{member.memberLevel}</>}
            >
              <FormProvider fieldName="memberLevel">
                <MemberLevelField />
              </FormProvider>
            </PermissionChecker>
          ) : !member.customId || !profile.memberLevel ? (
            <>
              {EMPTY_STRING_PLACEHOLDER}
              <span css={styles.separator}> / </span>
              {EMPTY_STRING_PLACEHOLDER}
            </>
          ) : (
            <>
              {profile.memberLevel}
              <span css={styles.separator}> / </span>
              <YmdHmRange
                startDate={profile.ecMemberStartTime}
                endDate={profile.ecMemberEndTime}
              />
            </>
          )}
        </span>
      }
    />
  );
};

const LifetimeSpending: FC = () => {
  const profileQueries = useQueries();
  const member = memberQueriesContext.useMember();
  const profile = profileQueries.profile;
  return (
    <Field
      label={
        <Trans i18nKey="chat.memberProfilePanel.membership.member.totalAmount" />
      }
      content={
        !member.customId ||
        profile.ecTotalAmount === null ||
        Number.isNaN(profile.ecTotalAmount) ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <SafeCurrencyNumberFormat
            currency={profile.ecCurrency}
            value={profile.ecTotalAmount}
          />
        )
      }
    />
  );
};

const MemberTierTotalSpending: FC = () => {
  const member = memberQueriesContext.useMember();
  const profileQueries = useQueries();
  const profile = profileQueries.profile;
  return (
    <Field
      label={
        <Trans i18nKey="chat.memberProfilePanel.membership.member.tierSpending" />
      }
      content={
        !member.customId ||
        profile.ecMemberTierGapEntitySpending === null ||
        Number.isNaN(profile.ecMemberTierGapEntitySpending) ? (
          EMPTY_STRING_PLACEHOLDER
        ) : (
          <SafeCurrencyNumberFormat
            currency={profile.ecCurrency}
            value={profile.ecMemberTierGapEntitySpending}
          />
        )
      }
    />
  );
};

const Points: FC = () => {
  const profileQueries = useQueries();
  const member = memberQueriesContext.useMember();
  const profile = profileQueries.profile;
  const countFormat = useNumberFormat({ numberFormatPreset: "count" });

  return (
    <Field
      label={
        <Trans i18nKey="chat.memberProfilePanel.membership.member.point" />
      }
      content={
        !member.customId ||
        profile.ecPoints === null ||
        Number.isNaN(profile.ecPoints)
          ? EMPTY_STRING_PLACEHOLDER
          : countFormat.numberFormat(profile.ecPoints)
      }
    />
  );
};

const Credits: FC = () => {
  const profileQueries = useQueries();
  const member = memberQueriesContext.useMember();
  const profile = profileQueries.profile;
  const countFormat = useNumberFormat({ numberFormatPreset: "count" });

  return (
    <Field
      label={
        <Trans i18nKey="chat.memberProfilePanel.membership.member.credit" />
      }
      content={
        !member.customId ||
        profile.ecPoints === null ||
        Number.isNaN(profile.ecPoints)
          ? EMPTY_STRING_PLACEHOLDER
          : countFormat.numberFormat(profile.ecPoints)
      }
    />
  );
};

const AccountManager: FC = () => {
  const member = memberQueriesContext.useMember();
  return (
    <div css={styles.field}>
      <label css={styles.label}>
        <Trans i18nKey="chat.customer.accountManager" />
      </label>
      <div>
        <PermissionChecker
          to="editMemberProfile"
          fallback={<span>{member.accountManager}</span>}
        >
          <FormProvider fieldName="accountManager">
            <AccountManagerField />
          </FormProvider>
        </PermissionChecker>
      </div>
    </div>
  );
};

const ecSupportMap = define<Record<string, CantataTypes["EcType"][]>>()({
  supportsLifetimeSpending: ["shopify", "shopline", "cyberbiz", "flaps"],
  supportsTierTotalSpending: ["91app"],
  supportsPoints: ["shopline", "cyberbiz", "flaps"],
  supportsCredits: ["shopline"],
});
type EcSupport = Record<keyof typeof ecSupportMap, boolean>;

const EcProfile: FC = () => {
  const orgData = orgQueriesContext.useData();
  const ecType = orgData.org.ecType;
  const member = memberQueriesContext.useMember();
  const profileResp = cantata.commerceProfile.useProfile(
    {
      params: {
        orgId: orgData.org.id,
        memberId: member.id,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  const ecSupport = useMemo<EcSupport>(
    function checkEcSupport() {
      return objectFromEntries(
        objectEntries(ecSupportMap).map(
          ([key, ecTypes]) =>
            [key, ecType !== null && ecTypes.includes(ecType)] as const,
        ),
      ) satisfies EcSupport as EcSupport;
    },
    [ecType],
  );

  if (!profileResp.data || !ecType) return null;

  return (
    <div css={styles.EcProfile}>
      {!ecSupport.supportsLifetimeSpending ? null : <LifetimeSpending />}
      {!ecSupport.supportsTierTotalSpending ? null : (
        <MemberTierTotalSpending />
      )}
      {!ecSupport.supportsPoints && !ecSupport.supportsCredits ? null : (
        <div css={styles.row}>
          {!ecSupport.supportsPoints ? null : <Points />}
          {!ecSupport.supportsCredits ? null : <Credits />}
        </div>
      )}
      <MemberLevel />
      <AccountManager />
    </div>
  );
};

const Wrapped = () => {
  const profileQueries = useProfileQueries();
  return (
    <ProfileQueriesContext.Provider queries={profileQueries}>
      <EcProfile />
    </ProfileQueriesContext.Provider>
  );
};

assignDisplayName(Wrapped, "EcProfile");

export { Wrapped as EcProfile };
